.policyText {
  margin-top: 16px !important;
  color: #3f3e43 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  text-align: left !important;
  font-size: 13px !important;
  font-family: "Poppins" !important;
  width: 100% !important;
}
.serviceText {
  margin-top: 0px;
  text-align: center;
}
.link {
  text-decoration: none;
  margin-bottom: 16px;
  color: #16bc93;
  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins";
  cursor: pointer;
}
