.AddressDisplayHeading {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
  margin-bottom: 8px;
}
.AddressDisplaySubHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
  margin-bottom: 16px;
}
.AddressDisplayBox {
  padding: 26px 26px;
  border: 1px solid #ebebed;
  border-radius: 6px;
  display: flex;
  gap: 10px;
}
.AddressDetailsItemLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
  margin-bottom: 2px;
}
.AddressDetailsItemValue {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
}
.AddressDetailsItem {
  margin-bottom: 24px;
}
.AddressDisplayBoxLeft {
  max-width: 307px;
}
.AddressDisplayBoxRight {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

/* edit address */

.addressEditorWrapper {
  max-width: 800px;
}
.addressEditHeading {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
  margin-bottom: 22px;
}
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 22px;
}
