.rolesAndPermissionHead {
  color: #142c44;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}
.rolesAndPermissionSubHead {
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
}
.systemRole {
  color: #142c44;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}
.userAvatar {
  aspect-ratio: 1;
  text-transform: capitalize;
}
.userCardImg {
  /* width: 32px;
  height: 32px; */
  display: flex;
  align-items: center;
  gap: -16px;
  position: relative;
}
.staffExtraRoleCount:hover .staffPopupWrap {
  display: block;
  transition: 0.1s ease-in-out;
}
.staffPopupWrap {
  position: absolute;
  width: 160px;
  max-height: 150px;
  border-radius: 7px;
  border: 1px solid #f2f4f7;
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 10px 10px 10px 10px;
  z-index: 99;
  list-style: none;
  top: 30px;
  left: 0;
  margin: 0;
  overflow-y: auto;
  display: none;
  transition: 0.1s ease-in-out;
}
.staffPopupItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}
.staffPopupItemAvatar {
  border-radius: 50%;
  border: 1.286px solid #fff;
  background: #667085;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.57px 7.286px 2.43px 7.714px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.188px; /* 151.568% */
}
.staffPopupItemName {
  color: #142c44;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: capitalize;
}
.roleDescriptionTable:first-letter {
  text-transform: capitalize;
}
