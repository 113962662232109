.modalTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #142c44;
  margin-bottom: 10px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -24px;
}
.previewFieldName {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
}
.additionalFieldWrap {
  margin-bottom: 14px;
}
.demoFieldLabel {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.previewBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px 24px 24px;
  box-shadow: 10px 4px 14px rgba(0, 0, 0, 0.15);
  gap: 20px;
}
.previewBtnWrap button {
  width: 100%;
}
