.viewCourseDetailsPopupBtn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  padding: 7px 14px;
  cursor: pointer;
  height: 38px;
}
.viewCourseDetailsPopupBtn:focus {
  outline: "none";
  border: 1px solid #d0d5dd;
}

.viewCourseDetailsPopupBtn:hover {
  background: #e8faf5;
  transition: 0.2s;
}

.CourseDetailsPopupWrap {
  overflow-y: auto;
}
.CourseDetailsPopupHeading {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #142c44;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.CourseDetailsPopupDescription h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #142c44;
}
.CourseDetailsPopupDescription p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}
.CourseDetailsPopupSyllabus {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}
.chapterContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}
.chapterContent p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
.CourseDetailsPopupImg {
  width: 300px;
  border-radius: 4px;
  overflow: hidden;
}
.CourseDetailsPopupImg img {
  width: 100%;
  border-radius: 4px;
}
.CourseDetailsPopupSplitTop {
  display: flex;
  gap: 30px;
  margin-bottom: 24px;
}
.CourseDetailsPopupName {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #142c44;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.CourseDetailsPopupLevel {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #667085;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.certificateTagWrapper {
  border-radius: 4px;
  background: rgba(17, 200, 155, 0.08);
  display: inline-flex;
  align-items: center;
  padding: 5px 9px;
}
.certificateTag {
  color: #11c89b;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 14.848px;
  text-transform: capitalize;
}

.blueBtn {
  color: #2664dc;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: underline;
  border: none;
  background-color: #fff;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .viewCourseDetailsPopupBtn {
    padding: 7px 8px;
    font-size: 12px;
  }
  .CourseDetailsPopupSplitTop {
    flex-direction: column;
  }
}


/* Tab View */

@media only screen and (max-width: 1024px) {
  .viewCourseDetailsPopupBtn {
    font-size: 11px;
    line-height: 11px;
    padding: 8px;
  }
 
}
