.editFormPopupCloseWrap {
  display: flex;
  justify-content: flex-end;
}

.editFormPopupCloseBtn {
  color: #1877f2;
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
}

.editFormPopupCloseBtn:hover {
  background: transparent;
}

.editFormPopupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #101828;
  margin-bottom: 8px;
  font-family: "Poppins";
}
.editFormPopupContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 22px;
  font-family: "Poppins";
}

.editFormPopupDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #69696b;
  margin-bottom: 40px;
}

.editFormPopupBtnsSplit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.editFormPopupCancelBtn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  padding: 10px 18px;
  width: 100%;
  color: #344054;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "Poppins";
}
.editFormPopupRemoveBtn {
  background: #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  padding: 10px 18px;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #d92d20;
  font-family: "Poppins";
}

/* .editFormPopupRemoveBtn:hover {
  background: #d92d20;
  border-radius: 8px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
} */

/* .editFormPopupCancelBtn:hover {
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  padding: 8px 10px;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
} */
.integrationText {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #0fb28a;
}
.settingsText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #142c44;
  margin-bottom: 10px;
}
.backText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.emailSettingsTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 23px;
}

.noEmailsBox {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 22px;
  font-family: "Poppins";
  text-align: center;
}