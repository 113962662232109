.currentStageWrap {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(155, 155, 155, 0.08);
  padding: 8px 32px 8px 14px;
  margin-bottom: 10px;
}
.currentStageLabel {
  color: #667085;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.currentStageValue {
  color: #142c44;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.overviewDetailsWrap {
  display: flex;
  justify-content: space-between;
}
.overviewDetailsLeft {
  width: 23%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.overviewDetailsRight {
  width: 76%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: 16px 16px 16px 18px;
  max-height: 308px;
  overflow: hidden;
}
.overviewDetailsLeftBox {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fff;
  padding: 12px 18px;
}
.overviewDetailsLeftLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
.overviewDetailsLeftValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.leadGraphHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}
.leadGraphTitle {
  color: #142c44;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  text-transform: capitalize;
}
