.leadPopupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  color: #131313;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1% 1%;
  transition: 0.3s ease-in-out;
  cursor: default;
}
.leadPopupWrapperChild {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.leadPopupSplit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.leadPopupSplitLeft {
  /* width: 407px; */
  width: 380px;
  border-right: 1px solid #f0f0f0;
}
.leadPopupSplitRight {
  /* width: calc(100% - 407px); */
  width: calc(100% - 380px);
  background: #f5f7f9;
}
.leadProfileDetailsWrap {
  padding: 14px 14px;
  border-bottom: 1px solid #ebebeb;
}
.leadCourseStatusWrap {
  padding: 16px 14px;
  /* max-height: calc(98vh - 230px); */
  overflow-y: auto;
  max-height: calc(95vh - 265px);
  padding-bottom: 70px;
}
.leadPopupCloseWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 12px 2px 12px;
}
.leadPopupTabWrapper {
  padding: 0 34px 0 12px;
}
.closeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.leadPopupCloseWrapTab {
  display: none;
}

/* lead Details Top */
.leadDetailsHead {
  text-align: center;
}
.leadDetailsAvatar {
  height: 50px;
  padding: 9px 20px 8px 20px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #989ca4;
  background: #667085;
  border-radius: 50%;
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  color: #fff;
  margin: 0 auto 6px auto;
  text-transform: capitalize;
}
.leadDetailsPic {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 6px auto;
}
.leadDetailsPic img {
  width: 100%;
  max-width: 100%;
}
.leadNameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  margin-bottom: 2px;
}
.leadName {
  color: #142c44;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.leadContactDetails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 3px;
}
.leadOtherDetailsWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-wrap: wrap;
}
.leadSourceDetails {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #eeeef4;
  background: #f2f4f7;
  color: #667085;
  text-align: center;
  /* font-family: Inter; */
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.leadAssigneeDetails {
  display: inline-flex;
  height: 34px;
  width: 34px;
  padding: 6px 11px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-transform: capitalize;
}
.leadWhatsAppWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}
.leadWhatsApp {
  width: max-content;
  color: #25d366;
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 3px;
  font-size: 12px;
  align-items: center;
}
.leadWhatsApp.disabled {
  color: #a6abb5;
  position: relative;
  min-width: 200px;
}
.leadWhatsApp.disabled::after {
  content: "Add a valid phone number to use the WhatsApp services";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #101828;
  color: #fff;
  z-index: 99;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  display: none;
  border-radius: 4px;
  transform: translateY(8px);
}
.leadWhatsApp.disabled::before {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateY(3px);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #101828; /* The arrow's color should match the tooltip's background */
  display: none;
  z-index: 100; /* Ensure it's above the tooltip background */
}
.leadWhatsApp.disabled:hover::after,
.leadWhatsApp.disabled:hover::before {
  /* display: block; */
}
.editBtn {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

/* lead course name */
.accordionCourseWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
.accordionCourseIcon {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.accordionCourseIcon img {
  max-width: 100%;
}
.accordionCourseName {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
.defaultCourseBatchName {
  color: #3f3e43;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.defaultCourseBatchWrap {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 13px 14px;
}
.defaultCourseBatchBottom {
  display: flex;
  align-items: center;
  gap: 17px;
}
.defaultCourseBatchItem {
  display: flex;
  align-items: center;
  gap: 6px;
}
.defaultCourseBatchItemIcon {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.defaultCourseBatchItemValue {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.defaultCourseDetailsLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 6px;
}
.defaultCourseDetailsValue {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
}
.defaultCourseDetailsItem {
  margin-bottom: 18px;
}
.editBranchBtn {
  color: #11c89b;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  outline: none;
  border: none;
  background: transparent;
  margin-bottom: 4px;
  cursor: pointer;
  text-underline-offset: 2px;
  display: flex;
  align-items: center;
}
.editBranchBtn img {
  margin-right: 4px;
}
.leadLostBtn {
  color: #ec3939;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  text-underline-offset: 2px;
  margin-bottom: 20px;
}
.leadActiveBtn {
  color: #11c89b;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  text-underline-offset: 2px;
  margin-bottom: 20px;
}
.leadCourseBtns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.courseEditBtns {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 8px;
}
.courseRelatedEditForm {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  position: relative;
}
.stageProgressBar {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  margin-bottom: 18px;
}
.stageProgressBarItem {
  position: relative;
  z-index: 999999;
}
.stageProgressBarItemCount {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9a9fab;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.stageProgressBarItemAfter::after {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 7px;
  right: 0;
  width: 12.5px;
  height: 12.5px;
  transform: rotate(45deg);
  background: #f9f9f9;
  border-top: 1px solid rgba(17, 200, 155, 0.1);
  border-left: 1px solid rgba(17, 200, 155, 0.1);

  z-index: 9999999;
}
/* .stageProgressBarItem::after {
  content: "";
  position: absolute;
  bottom: -24px;
  left: 7px;
  right: 0;
  width: 12.5px;
  height: 12.5px;
  transform: rotate(45deg);
  background: #f9f9f9;
  border-top: 1px solid rgba(17, 200, 155, 0.1);
  border-left: 1px solid rgba(17, 200, 155, 0.1);

  z-index: 9999999;
} */
.stageProgressBarLine {
  position: absolute;
  height: 4px;
  background: #fff;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.08) inset;
  width: 100%;
}

.stageGreenBackground {
  background: #11c89b;
  color: #fff;
}
.stageGreyBackground {
  background: #d2d2d2;
  color: #fff;
}
.stageProgressBarLineChildActive {
  background: #11c89b;
  display: block;
  height: 100%;
}
.stageProgressBarLineChildLost {
  background: #d2d2d2;
  display: block;
  height: 100%;
}
/* Lead Add Course */
.addCourseBtn {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  height: 48px;
  display: flex;
  width: 100%;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  box-shadow: none;
}

.leadAddCourseWrapper {
  position: fixed;
  /* z-index: 999999; */
  z-index: 9999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #131313;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadAddCourseWrapperChild {
  background: #fff;
  width: 408px;
  border-radius: 12px;
  padding: 16px 16px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.popupClsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.clsBtn {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.clsBtn img {
  max-width: 100%;
}
.popupTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
}
.leadAddCourseFormWrap {
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow-x: hidden; */
}
.stageMenuItem {
  display: flex;
}
.leadCallPopupBtn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #11c89b;
  cursor: pointer;
  padding: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadCallPopupClsBtnWrap {
  display: flex;
  justify-content: flex-end;
}
.leadCallPopupTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.dispositionPopupHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 12px;
}
.dispositionPopupHeadTitle {
  color: #142c44;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
.dispositionPopupHeadTimeDesc {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  display: flex;
}
.dispositionPopupHeadTime {
  color: #3f3e43;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}
.dispositionPopupFieldLabel {
  color: #323b4b;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.phoneChangeYesButton {
  border-radius: 4px;
  background: #12bd93;
  border: 1px solid #12bd93;
  padding: 10px 18px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}

.phoneChangePopupTitle {
  color: #101828;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 21px;
}
.phoneChangePopupBtnSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.phoneChangePopupBtnSection button {
  flex-basis: 100%;
}
.phoneChangeNoButton {
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 10px 18px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  color: #344054;
  font-size: 15px;
}
.leadStudentId {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
}
/* Tab View */

@media only screen and (max-width: 1024px) {
  .leadPopupSplit {
    flex-direction: column;
    overflow: auto;
  }
  .leadPopupSplitLeft {
    width: 100%;
  }
  .leadPopupSplitRight {
    width: 100%;
  }
  .leadPopupCloseWrapTab {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 12px 2px 12px;
  }
  .leadPopupCloseWrap {
    display: none;
  }
  .leadPopupTabWrapper {
    padding: 12px;
  }
  .leadCourseStatusWrap {
    max-height: calc(95vh - 190px);
  }
  .leadPopupSplitRight {
    height: calc(-14% + 100vh);
    max-height: calc(-14% + 100vh);
    min-height: calc(-14% + 100vh);
  }
}
