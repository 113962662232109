.sourceManagerWrap {
  position: relative;
}

.sourceListPopover {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;
  /* width: 240px; */
  width: 100%;
  z-index: 9999999;
}
.sourceListPopoverItemsWrap {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 10px;
}
.sourceListPopoverItem {
  padding: 4px 4px;
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  text-transform: capitalize;
  text-align: left;
}
.sourceListPopoverItem:hover {
  background: #e6e6e642;
}
.sourceListPopoverItemAdd {
  padding: 4px 4px;
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.sourceListPopoverItemAddValue {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
