.lockedProfile_count {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #11C89B;
  border-radius: 50%;
  color: #23a67b;
}

.lockedList {
  background-color: #ffffff;
  border: 1px solid #DFDFDF;
  color: #69696B;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.lockedList_inProgress {
  background: #EFFEF9;
  border: 1px solid rgba(17, 200, 155, 0.5);
  color: #11C89B;  
  border-radius: 4px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.lockedList_completed {
  background: #EFFEF9;
  border: 1px solid rgba(17, 200, 155, 0.5);
  color: #11C89B;
  border-radius: 4px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.listItemIcon {
  display: flex;
  justify-content: flex-end;
}