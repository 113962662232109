.taskCompletedCard {
  background: rgba(17, 200, 155, 0.04);
  border: 1px solid rgba(17, 200, 155, 0.2);
  border-radius: 6px;
  padding: 12px 12px;
  margin-bottom: 6px;
}
.taskCompletedCardSplitTop {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
  margin-top: 4px;
}
.taskCompletedCardDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #667085;
  margin-bottom: 4px;
}
.taskCompletedCardType {
  background: #f2f4f7;
  border-radius: 16px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #344054;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.taskCompletedCardDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #142c44;
  margin-bottom: 2px;
}
.taskCompletedCardDescShort {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #142c44;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.taskCompletedCardSubDetails {
  display: flex;
  align-items: center;
  gap: 6px;
}
.taskCompletedCardSubDetailsItem {
  width: 30px;
  height: 30px;
  background: #f5f5f7;
  border: 1px solid #eeeef4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #667085;
}
.taskCompletedCardLeadName {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #142c44;
  text-transform: capitalize;
}
.flagAssign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #f5f5f7;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
}
.taskFlag {
  display: flex;
  gap: 8px;
}
.userCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #f5f5f7;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #667085;
}
