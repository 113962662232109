.integrationHead {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}
.integrationItemHead {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.integrationItemDescription {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.integrationItemWrap {
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: #fff;
  max-width: 375px;
  padding: 24px;
}
.integrationItemsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
