.topBottom {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  padding: 29px;
}
.imageRelative {
  position: relative;
}
.imageRelative img {
  display: flex;
}
.imageSection {
  width: 114px;
  height: 114px;
  position: absolute;
  bottom: -40%;
  left: 29px;
}
.imageSection img {
  border: 4px solid #f9f9f9;
  border-radius: 307px;
  width: 100%;
}
.editButton {
  display: flex;
  justify-content: flex-end;
}
.name {
  color: #142c44;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin-top: 12px;
}
.contactSection {
  display: flex;
  margin-top: 12px;
  margin-bottom: 17px;
  align-items: center;
}
.website {
  text-decoration-line: underline;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #377dff;
  margin-right: 26px;
}
.email {
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  margin-right: 26px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact {
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.domainAddressSection {
  display: flex;
  width: 100%;
}
.rightSection {
  margin-left: 52px;
}
.bottomSection {
  margin-top: 15px;
  display: flex;
  padding: 0 22px 22px 22px;
}
.aboutSection {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  width: 70%;
  min-height: 310px;
}
.mediaSection {
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  margin-left: 15px;
  width: 30%;
  height: 100%;
  min-height: 310px;
}
.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #667085;
}
.des {
  color: #142c44;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  width: 100%;
}
.add {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #11c89b;
  padding-top: 106px;
  padding-bottom: 106px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.modalTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #142c44;
}
.modalDes {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #667085;
}
.socialModalTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #142c44;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialTextMainDiv {
  /* border: 1px solid #d4d4d8;
  border-radius: 6px; */
  margin-bottom: 20px;
}
.iconSection {
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  border-right: 1px solid #d4d4d8;
  margin-left: -14px;
}
.iconSectionDisplay {
  display: inline-flex;
  align-items: center;
  padding: 8px;
}
.mainTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #142c44;
  font-family: "Poppins";
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.url {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.customModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.customModal {
  position: relative;
  z-index: 9999; 
}


/* Tab View */

@media only screen and (max-width: 899px) {
  .imageSection {    
    bottom: -80%;
    width: 96px;
    height: 96px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 999px) {
  .imageSection {    
    bottom: -90%;
    width: 96px;
    height: 96px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .imageSection {    
    bottom: -65%;
    width: 96px;
    height: 96px;
  }
}



