.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.viewBatchDetailsBtn {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  border-radius: 2px;
  border: 1px solid #ebebed;
  height: 40px;
  padding: 6px 12px;
  background: #fff;
  cursor: pointer;
}
.PageBackBtnWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 14px;
}
.batchDetailsTitle {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 11px;
}
.batchDetailsSplit {
  display: flex;
  justify-content: space-between;
  gap: 26px;
  width: 100%;
  margin-bottom: 20px;
}
.batchDetailsBox {
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #fff;
  width: 60%;
  height: 250px;
  display: flex;
  flex-direction: column;
}
.batchDetailsBoxTop {
  background: #f7f9fe;
  padding: 25px 25px;
}
.batchName {
  color: #142c44;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.branchName {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.branchName::first-letter {
  text-transform: uppercase;
}
.batchName::first-letter {
  text-transform: uppercase;
}
.demoDetailsBox {
  border-radius: 6px;
  border: 1px solid #ebebed;
  padding: 22px 26px;
  width: 38%;
  height: 250px;
}
.batchDetailsBoxBottom {
  display: flex;
  gap: 40px;
  padding: 25px 40px;
  flex: 1;
  align-items: center;
}
.batchDetailsBoxBottomSplit {
  display: flex;
  gap: 14px;
}
.batchDetailsBoxBottomMain {
  color: #323b4b;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}
.batchDetailsBoxBottomSub {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.batchDetailsBoxBottomSub::first-letter {
  text-transform: uppercase;
}
.demoDetailsBoxItemLabel {
  color: #323b4b;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
}
.demoDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
}
.demoDetailsBoxItemValue {
  display: flex;
  align-items: center;
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  gap: 6px;
}
.demoDetailsBoxDateIcon {
  display: flex;
}
.demoAssignee {
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  display: flex;
  width: 28px;
  height: 28px;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #667085;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.767px;
}
.demoStudentsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}
.demoStudentsHeadTitle {
  color: #494949;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.demoStudentCard {
  border-radius: 4px;
  border: 1px solid rgba(38, 100, 220, 0.1);
  background: rgba(38, 100, 220, 0.04);
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  margin-bottom: 8px;
}
.demoStudentCard:last-child {
  margin-bottom: 0;
}
.demoStudentCardLeft {
  display: flex;
  /* gap: 150px; */
  align-items: center;
  gap: 10px;
}
.demoStudentCardMainSplit {
  display: flex;
  gap: 12px;
  align-items: center;
  min-width: 340px;
  max-width: 340px;
}
.demoStudentCardAvatar {
  display: flex;
  height: 34px;
  width: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  text-transform: capitalize;
  color: #667085;
  font-size: 17px;
  font-weight: 400;
  line-height: 18.02px;
}
.demoStudentCardName {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
}
.demoStudentCardEmail {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.demoStudentCardPhone {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.demoStudentCardLeadBtn {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.demoAssigneeName {
  text-transform: capitalize;
}
.demoStudentCardAttendanceBtn {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 14px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.demoStudentCardRight {
  display: flex;
  align-items: center;
  gap: 42px;
}
.attendanceBtnsWrap {
  background: #fff;
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.08));
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.demoStudentsHeadRight {
  display: flex;
  align-items: center;
  gap: 16px;
}
.demoStudentStatus {
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.demoAttendanceEditBtn {
  display: flex;
  width: 40px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
}
.demoDetailsCompleteBar {
  border-radius: 3px;
  background: rgba(17, 200, 155, 0.1);
  padding: 8px 14px;
  color: #12bd93;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
}
