.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -6px;
}
.modalTitle {
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}
.modalPara {
  color: #142c44;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}
