.pageTitle {
  color: #142c44;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 21px;
}
.prefixDetailsWrap {
  border-radius: 4px;
  border: 1px solid rgba(119, 129, 126, 0.07);
  background: rgba(178, 178, 178, 0.06);
  padding: 22px 24px;
  max-width: 45%;
}
.prefixDetailsTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 24px;
}
.prefixDetailsChild {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.prefixDetailsChildLeft {
  display: flex;
  align-items: center;
  gap: 45px;
}
.prefixDetailsItemLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.prefixDetailsItemValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: uppercase;
}
.editPrefixWrap {
  max-width: 45%;
}
.editPrefixTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}
.editPrefixDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 18px;
}
.editPrefixLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 5px;
}
