.leadDatePicker {
  display: none;
}
.leadDatePickerLabel {
  /* padding: 4px 8px; */
  padding: 2px 2px;
  display: flex;
  color: #69696b;
  cursor: pointer;
  height: 30px;
  width: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadDatePickerLabel img {
  width: 80%;
}
