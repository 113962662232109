.enableText {
    color: #142C44;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .setupInvoiceHead {
    color: #142C44;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
  }
  .gstIn {
    color: #142C44;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
  }
  .invoicePreview {
    color: #667085;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .step {
    color: #3F3E43;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .addGst,
  .invoiceSectionHead {
    color: #142C44;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .invoiceSectionSubHead {
    color: #667085;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 18px;
  }
  .prefix,
  .nextNumber {
    color:#142C44;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400; 
    line-height: 22px;
  }
  
  
  .invoiceDetailsMainHead {
    color: #142C44;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; 
    margin-bottom: 26px;
  }
  .invoiceDetailsSubHead {
    color: #667085;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    text-transform: capitalize;
  }
  .invoiceDetailsWrapper {
    border-radius: 4px;
    border: 1px solid rgba(119, 129, 126, 0.07);
    background: rgba(178, 178, 178, 0.06);
    padding: 24px;
  }
  .value {
    color: #142C44;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; 
  }
  .editGst {
    color: #142C44;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 18px;
  }
  .editInvoice {
    color: #142C44;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  
  /* Tax Invoice Receipt */
  
  .instituteName {
    color: #142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .instituteAddress {
    color:#142C44;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  .instituteLogo {
    max-width: 100px;
    overflow: hidden;
  }
  .receiptHead {
    color: #142C44;
    text-align: end;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .receiptDetailsLabel {
    color: #142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    text-transform: capitalize;
  }
  .receiptDetailsValue {
    color: #142C44;
    font-family: Poppins;
    font-size:12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-transform: capitalize;
    margin-left: 4px;
  }
  .studentName, 
  .paymentSource,
  .supply {
    color:#142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-transform: capitalize;
  }
  .billTo {
    color: #667085;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .supplyPlace {
    color: #142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; 
    text-transform: capitalize;
    margin-left: 4px;
  }
  .amountDetails {
    border-radius: 2px;
    background: rgba(20, 44, 68, 0.04);
    padding: 15px 12px;
    margin-top: 10px;
    min-width: 170px;
  }
  .amountDetailsWrapper {
    display: flex;
    justify-content: end;
  }
  .amountDetailsLabel,
  .amountDetailsValue {
    color: #142C44;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .totalLabel,
  .totalValue {
    color: #142C44;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .balanceDueLabel {
    color: #142C44;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .balanceDuevalue {
    color: #142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .totalInWordsLabel {
    color: #142C44;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .totalInWordsValue {
    color: #142C44;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .taxInvoiceDetailsWrapper {
    display: flex;
    padding-top: 20px;
    max-height: calc(100vh - 90px);
    position: relative;
  }


  .invoiceDetailsBox {
    border-right: 2px solid #F7F7F7;
    width: calc(100vh - 300px);
    overflow: auto;
    padding-right: 20px;
    min-height: 100%;
    max-height: 100%;
    min-width: 370PX;
    position: relative;
  }
  .invoicePreviewWrapper {
    width: 630px;
    padding-left: 18px;
    overflow-y: auto;
    padding-right: 20px;
    max-height: 500px;
  }


@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .taxInvoiceDetailsWrapper {
    flex-direction: column;
  }
  .invoiceDetailsBox {
    border-right: unset;
    width: unset;
    overflow: unset;
    min-height: unset;
    max-height: unset;
    min-width: unset;
    padding-right: 0;

  }
  .invoicePreviewWrapper {
    width: unset;
    padding-left: 0;  
    margin-top: 30px;  
  }

}


  