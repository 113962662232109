.closeModalButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: -14px;
    margin-right: -14px;
}
.modalHead {
    color: #101828;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
    margin-bottom: 10px;
}
.buttonSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 8px;
}
.noButton {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #ffffff;
    margin-right: 12px;
    padding: 10px 18px;
    gap: 8px;
    width: 100%;
    color: #344054;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    font-family: Poppins;
}
.yesButton {
    background: #ec3939;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    padding: 10px 18px;
    gap: 8px;
    width: 100%;
    color: #ffffff;
    border: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    font-family: Poppins;

}
  
