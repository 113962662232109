.billingHistoryHead {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
}
.billingTable {
  min-width: 650;
}

.rupee {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}

.invoice a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #456dfb;
}
.billingTableHead {
  background: #f9fafb;
  padding: 10px;
}

.empty {
  color: #667085;
}
.billingHistoryTableRow:last-child td {
  border-bottom: none;
}
.downloadBtn {
  color: #456dfb;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  text-underline-offset: 0.2em;
  cursor: pointer;
}
