.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.inboundSettingsTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 17px 0 0 20px;
}
.inboundSettingsTabs {
  margin: 5px 0 0 20px;
  padding: 0;
  list-style: none;
}
.inboundSettingsTab {
  color: #8d9091;
}
.inboundSettingsTabBtn {
  border: none;
  background: transparent;
  outline: none !important;
  font-size: 14px;
  /* line-height: 1.5; */
  color: #8d9091;
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
}
.inboundSettingsTabBtnActive {
  color: #11c89b;
  border: none;
  background: transparent;
  outline: none !important;
  font-size: 14px;
  /* line-height: 32px; */
  cursor: pointer;
  padding: 10px 0;
}
.inboundSettingsTabPanelActive {
  display: block;
  transition: 0.3s ease-in-out;
  height: 97%;
}
.inboundSettingsTabPanel {
  display: none;
  transition: 0.3s ease-in-out;
}



@media only screen and (min-width: 600px) and (max-width: 899px) {
  .webformTableContainer
  {
    min-width: unset;  
  }
  .webformSearch {
    width: unset;
  }

}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .webformTableContainer
  {
    min-width: unset;
  }
  .webformSearch {
    width: unset;
  }
  
}