.pageWrap {
  padding: 13px 16px;
  background: #f5f5f5;
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: hidden;
}
.pageWrapChild {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  /* padding: 22px 30px; */
  min-height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  overflow-y: hidden;
}
.completedTaskPageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.completedTaskTopSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
}
.completedTaskHead {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
  margin: 12px 0 8px 0;
}

@media only screen and (max-width: 1034px) {
  .completedTaskTopSplit {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
  }
  .completedTaskHead {
    margin-bottom: 10px;
  }
}
