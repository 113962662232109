.userAvatar {
  aspect-ratio: 1;
  text-transform: capitalize;
}
.userCardImg {
  width: 32px;
  height: 32px;
  display: flex;
}
.noUser {
  color: #142C44;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}