/* Webforms */

.welcomeHead {
  color: #3f3e43;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.801px;
  margin-bottom: 8px;
}
.welcomePara {
  color: #667085;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
}
.funnelHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 24px;

}
.funnelHeadSplitTitle {
  color: #142c44;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.funnelHeadSplitRight {
  display: flex;
  align-items: center;
  gap: 8px;
}





@media only screen and (min-width: 600px) and (max-width: 899px) {
  .webformTableContainer
  {
    min-width: unset;  
  }
  .cellFormName {
    min-width: 120px;
  }
  .cellCreatedBy {
    min-width: 120px;
  }
  .cellCreatedDate {
    min-width: 130px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .webformTableContainer
  {
    min-width: unset;
  }
  .cellFormName {
    min-width: 120px;
  } 
  .cellCreatedBy {
    min-width: 120px;
  }
  .cellCreatedDate {
    min-width: 130px;
  }
  
}