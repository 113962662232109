.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background: #fff;
  padding: 50px 30px;
  max-width: 400px;
  border-radius: 6px;
}

.formHeading {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 38px;
  color: #142c44;
}

.signUpLink {
  text-decoration: none;
  color: #16bc93;
  font-weight: 400;
}

.link {
  text-decoration: none;
  margin-bottom: 16px;
  color: #1a73e8;
  font-size: 14px;
  font-weight: 500;
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
}

.Header {
  position: absolute;
  left: 16px;
  top: 16px;
}

.appLogo {
  width: 10%;
}

.signUpText {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #3f3e43;
  margin-top: 16px;
}

.policyText {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #3f3e43;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 23px;
}

.leftPanal {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #142c44;
}

.leftPanal img {
  max-width: 60%;
}

.rightPanal {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 50px) and (max-width: 767px) {
  .Header {
    background: #142c44;
    text-align: center;
    padding-top: 10px;
    position: relative;
    top: unset;
    left: unset;
    justify-content: center;
  }

  .leftPanal {
    width: 100%;
    height: 100%;
  }

  .formHeading {
    text-align: center;
  }

  .leftPanal img {
    width: auto !important;
    height: auto !important;
  }

  .page {
    height: 100%;
    width: 100%;
    display: block;
    align-items: center;
  }

  .appLogo {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .leftPanal img {
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Header {
    background: #142c44;
    text-align: center;
    padding-top: 10px;
    position: relative;
    top: unset;
    left: unset;
    justify-content: center;
  }

  .leftPanal {
    width: 100%;
    height: 100%;
    margin-top: -1px;
  }

  .formHeading {
    text-align: center;
  }

  .leftPanal img {
    width: auto !important;
    height: auto !important;
  }

  .page {
    height: 100%;
    width: 100%;
    display: block;
    align-items: center;
  }

  .appLogo {
    width: 20%;
  }
}
