.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.PageBackBtnWrap {
  padding: 27px 20px 20px 20px;
}
.roleName {
  color: #142c44;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.roleDescription {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 6px;
}
.roleDescription:first-letter {
  text-transform: capitalize;
}
.editHead {
  color: #667085;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.roleHead {
  color: #142c44;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 12px;
}
