.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #69696b;
  text-transform: capitalize;
}
.time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #777777;
}
.description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #69696b;
}
.assign {
  display: flex;
  gap: 8px;
  align-items: center;
}
.newEnquiryBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #ebfff1;
}
.dateStyle {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 4px;
}
.activityDateStyle {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
}
.activityStageDesc {
  color: #142c44;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.activityStageDesc:first-letter {
  text-transform: capitalize;
}
.activityStageBox {
  border-radius: 16px;
  background: #ecfdf3;
  padding: 2px 8px;
  color: #3db985;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.activityHeadStage {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}
.activityHeadStageIcon {
  display: flex;
}
.activityHeadTaskStatus {
  display: flex;
  align-items: center;
  gap: 7px;
}
.activityHeadTaskStatusValue {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  padding: 2px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.activityHeadTaskStatusCheckbox {
  display: flex;
  align-items: center;
  position: relative;
}
.leadTabActivityTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  /* padding-left: 24px;
  padding-bottom: 14px;
  padding-right: 15px; */
}
.activityHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.activityHeadBtns {
  display: flex;
  align-items: center;
  gap: 18px;
}
.activityEditBtn {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
}
.activityDeleteBtn {
  color: #ec3939;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
}
.activityHeadName {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}
.activityDescription {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 6px;
}
.activityDescription::first-letter {
  text-transform: capitalize;
}
.activityStatusBox {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  padding: 2px 8px;
}
.activitySideIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 5px;
  width: 100%;
}
.activitySideIcon img {
  max-width: 100%;
  width: 100%;
}
.activityTop {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 5px;
}
.activityBottom {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.activityBottomLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}
.activityBottomAssign {
  width: 23px;
  height: 23px;
  /* padding: 4px 6px 4px 7px; */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  border: 0.733px solid #eeeef4;
  background: #f5f5f7;
  color: #667085;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-transform: capitalize;
}
.activityBottomFlag {
  width: 22px;
  height: 22px;
  padding: 4px 6px 4px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  border: 0.733px solid #eeeef4;
  background: #f5f5f7;
}
.activityBottomFlagImg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.activityBottomFlagImg img {
  max-width: 100%;
}
.activityDemoTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.activityDemoBottom {
  display: flex;
  align-items: center;
  gap: 18px;
}
.activityDemoBatchName {
  color: #3f3e43;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.activityDemoDate {
  display: flex;
  align-items: center;
  gap: 6px;
}
.activityDemoDateIcon {
  display: flex;
  align-items: center;
}
.activityDemoDateValue {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.activityDemoClassType {
  border-radius: 16px;
  background: #f2f4f7;
  padding: 2px 8px;
  color: #344054;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.activityEnquiry {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.activityPaymentStatementBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 11px 22px;
  border-radius: 3px;
  border: 1px solid rgba(227, 227, 227, 0.7);
}
.activityPaymentStatementItem {
  display: flex;
  align-items: center;
  gap: 6px;
}
.activityPaymentStatementLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.activityPaymentStatementValue {
  color: #667085;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.demoItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #b1b1b1;
  gap: 6px;
}
.demoItemType {
  display: flex;
  align-items: center;
  gap: 12px;
}
.demoItemTypeValue {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 8px 18px;
  background: #23a67b;
  border: 1px solid #23a67b;
  border-radius: 60px;
}
.demoItemIcon {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 20px;
}
.demoItemIcon img {
  max-width: 100%;
}
.leadTabActivityHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 15px 0 24px;
  background: #fff;
}
.commentBtn {
  border: none;
  background: #fff;
  cursor: pointer;
  padding: 0;
  position: relative;
  border-radius: 50%;
  display: flex;
}
.commentBtnPopover {
  display: none;
  transition: 0.1s ease-in-out;
}
.commentBtn:hover .commentBtnPopover {
  position: absolute;
  border-radius: 4px;
  background: #101828;
  padding: 4px 10px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  top: 40px;
  right: 0;
  min-width: 100px;
  display: block;
  z-index: 9;
}
.commentBtnPopoverChild {
  position: relative;
}
.commentBtnPopover::after {
  content: "";
  display: block;
  background: #000;
  position: absolute;
  top: -4px;
  transform: rotate(45deg);
  right: 7px;
  width: 8px;
  height: 8px;
}

.activityTagStatusBox {
  display: flex;
  align-items: center;
  border-radius: 16px;
}
