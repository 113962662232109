.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header {
  position: absolute;
  left: 16px;
  top: 16px;
}

.form {
  background: #fff;
  padding: 50px 30px;
  max-width: 460px;
  border-radius: 6px;
}

.leftPanal {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #142c44;
}

.leftPanal img {
  max-width: 60%;
}

.rightPanal {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formHeading {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 31px;
  justify-content: space-around !important;
}

.formInfo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #8a8a8a;
}

.policyText {
  margin-top: 15px !important;
  color: #3f3e43 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  /* text-align: center !important; */
}

.ServiceText {
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  /* text-align: center !important; */
}

.sendotpTxt {
  margin-top: 32px;
  font-size: 14px;
  color: #3f3e43;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  position: relative;
  bottom: 27px;
}

.link {
  text-decoration: none;
  margin-bottom: 16px;
  color: #23a67b;
  font-weight: 500;
}

.otpInputWrapper {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 30px;
}

.logo_box {
  justify-content: center;
  margin-bottom: 90px;
}

.inputStyle {
  /* width: 38px !important;
  height: 42px; */
  width: 2.375rem !important;
  height: 2.6rem;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins" !important;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
}

.borderHover:focus {
  outline-color: #23a67b;
}

.formHeadingalt {
  font-size: 32px !important;
  font-weight: 600 !important;
  text-align: left !important;
  line-height: 50px !important;
  margin-bottom: 10px;
}

.list {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.passwordCriteria {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 27px !important;
  display: flex;
  margin-left: 10px !important;
  align-items: center;
}

.passwordCriteriaList_icons {
  min-width: 18px !important;
  color: green;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.notValid_passwordCriteriaList_icons {
  min-width: 18px !important;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.submitButton {
  margin-top: 25px !important;
}

.terms_txt {
  font-weight: 600;
}

.gapText {
  font-size: 14px !important;
}

.serviceText {
  margin-top: 0px;
  text-align: center;
}

.password_text_head {
  font-weight: 600;
}

.count_text {
  color: red;
}

.count_text_main {
  font-size: 16px !important;
  text-align: center !important;
  font-family: "Poppins";
  font-weight: 400;
}

/* / signup css  / */
.Image_graph {
  position: absolute;
}

.Rectangle {
  position: relative;
  right: 7rem;
  bottom: 1rem;
}

.textenal {
  position: absolute;
  top: 33rem;
  left: 5rem;
}

.txt_hands {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 32px !important;
  line-height: 40px;
  color: white;
  text-align: right;
}

.txt_hands_sub {
  color: white !important;
  width: 300px !important;
  font-size: 14px !important;
  position: relative !important;
  top: 10px !important;
}

.appLogo {
  position: absolute;
}

.Individual_tutor {
  height: 108px;
  width: 415px;
  background-color: #f5f5f7;
  padding: 25px;
  border-radius: 14px;
  border: 0.5px solid transparent;
}

.Individual_tutor:hover {
  cursor: pointer;
  border: 0.5px solid #23a67b;
  overflow: hidden;
}

.Individual_tutor_text {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #69696b;
}

.Individual_sub_text {
  font-weight: 500;
  font-size: 14px;
  color: #b1b1b1;
}

.background_icon {
  height: 79px;
  width: 79px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: relative;
  bottom: 12px;
}

.bul_icon {
  font-size: 40px !important;
  color: #23a67b;
}

.fstname_lastname {
  display: flex;
  justify-content: space-between;
}

.btn_continue {
  margin-top: 10px !important;
}

.form_body {
  line-height: 3.5;
  padding: 50px 30px;
}

.leftPanal_image {
  position: relative;
  /* right: 80px; */
}

/* Verified Css */
.logo_box {
  position: absolute;
  width: 150px;
  height: 33.83px;
  left: 40px;
  top: 56px;
}

.responsiveLogo_box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.content_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  margin: 25px;
}

.content_div {
  width: 70%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 30px;
  letter-spacing: 0.2px;
  font-weight: 700;
  color: #ffffff;
  line-height: 40px;
}

.paragraph {
  width: 55%;
  margin: auto;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin-top: -0.9rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.institute_inputs {
  width: 408px !important;
}

.formDanger {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #d32f2f;
}

@media (min-width: 50px) and (max-width: 767px) {
  .containerCard {
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
  }

  .containerLeft {
    padding-left: 0px !important;
    padding-top: 0px !important;
  }

  .containerRight {
    padding-top: 0px !important;
  }

  .page {
    height: 100%;
    width: 100%;
    display: block;
    align-items: center;
  }

  .leftPanal {
    width: 100%;
    padding-top: 45px;
    text-align: center;
  }

  .leftPanal img {
    width: auto !important;
    height: auto !important;
  }

  .Header {
    position: relative;
    left: 0;
    top: 0;
    justify-content: center;
  }

  .appLogo {
    margin-top: 10px;
  }

  .Individual_tutor {
    width: 95%;
    margin: auto;
  }

  .background_icon {
    height: 55px;
    width: 55px;
    bottom: 0;
  }

  .logo_box {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .page {
    height: 100%;
    width: 100%;
    display: block;
    align-items: center;
  }

  .leftPanal {
    width: 100%;
    padding-top: 45px;
  }

  .leftPanal img {
    width: auto !important;
    height: auto !important;
  }

  .Header {
    position: relative;
    left: 0;
    top: 0;
    justify-content: center;
  }

  .appLogo {
    margin-top: 10px;
  }

  .logo_box {
    display: none !important;
  }

  .Individual_tutor {
    width: 415px;
    margin: auto;
  }
}

@keyframes fillButton {
  0% {
    background-size: 0%;
  }
  100% {
    background-size: 100%;
  }
}

.buttonLoading {
  background: linear-gradient(to right, #11c89b 50%, transparent 50%);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  animation: fillButton 15s forwards;
}
