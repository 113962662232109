.popupHead {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  padding: 0 10px;
}

.popupPara {
  color: #142c44;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 10px;
  margin: 0;
}

.popupButtonDiv {
  text-align: center;
  display: flex;
  gap: 10px;
  padding: 0 10px;
}

.notifyPara {
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -6px;
}

.popupHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.popupHeadTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.popupHeadClsBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}
.membershipEditBody {
  border-radius: 6px;
  border: 1px solid #f0f0f5;
  margin-bottom: 18px;
}
.membershipEditBodyTop {
  padding: 18px 18px;
  background: #fafafc;
}
.membershipEditBodyBottom {
  padding: 18px 18px;
}
.membershipEditTypeLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 6px;
}
.membershipEditTypeValue {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  padding: 3px 11px;
  border-radius: 4px;
  background: #9747ff;
  width: fit-content;
}
.membershipEditTypeFieldLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 6px;
}
.popupBtnSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.popupBtnSplit > button {
  flex-basis: 100%;
}
.membershipTypeFinalPrice {
  color: #11c89b;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
}
.addBatchWrapper {
  position: fixed;
  /* z-index: 999999; */
  z-index: 9999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #131313;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.addBatchWrapperChild {
  background: #fff;
  width: 408px;
  border-radius: 12px;
  padding: 16px 16px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.courseLevelSection {
  margin: 30px 0px;
}
