.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}
#basic-menu {
  z-index: 99999;
}
.navItmeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationUserWrapper {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid red;
}

.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}

.navbar_list {
  padding-left: 20px;
  border-radius: 9px !important;
}

.navbar_list_active {
  padding-left: 20px;
  background-color: #23a67b !important;
  border-radius: 9px !important;
}

.logo_box {
  position: absolute;
  left: 15%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}

.logo_box img {
  height: 35px;
  width: 150;
}
.logo_box_small {
  position: absolute;
  left: 9.09%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}

.search_icon {
  float: left;
}

.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* today css */

.flag {
  /* position: absolute; */
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #1a3235;
}

.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}

.lineContainer {
  margin: 20px;
}

.subList:hover {
  background: #23a67b;
  color: #ffffff;
}

.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.googleMap {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 10px;
}

::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}

._Institute_imageBox {
  display: flex;
  justify-content: left;
}

._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}

._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}

.styles._image_description {
  margin-left: 50px;
}

.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}

.hader_txt {
  color: white;
}

.list_flat {
  display: flex;
}

.list_item_flat {
  display: flex !important;
  color: black !important;
}

.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}

.list_font {
  font-size: 14px !important;
  text-align: center !important;
}

/* .navBarDivider{
  color: black !important;
} */
.userProfileMenuWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.userProfileMenu {
  min-width: 270px;
  max-width: 300px;
  right: -10px;
  position: absolute;
  background: #fff;
  z-index: 999999999;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 55px;
}

.activeUserProfileDataWrapper {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
}
.activeUserProfile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rightArrow {
  cursor: pointer;
}
/* .userProfileInstituteName {
  padding: 10px;
  text-align: center;
  background: #1a3235;
  color: #fff;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
} */

.userProfileImage {
  width: 50px;
  height: 50px;
}

.userProfileName {
  font-size: 18px;
  color: #142c44;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
}

.userProfileRole {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #667085;
}
.institutes {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  padding: 16px 16px 0;
}
.tickIcon {
  min-width: unset !important;
}
.listItemButton {
  padding-top: 0px !important;
}
.userProfileManageButton {
  border: 1px solid;
  margin: 0 auto;
  border-radius: 25px;
  padding: 10px;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
}

.userProfileInstitutionListItem {
  padding: 10px;
}

.userProfileInstitutionListItemName {
  color: #69696b;
  font-size: 16px;
}

.userProfileInstitutionListItemRole {
  color: #d5d5de;
  font-size: 12px;
}
.changePasswordButton {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  gap: 12px;
}
.changePassword {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #142c44;
}
.logoutButton {
  display: flex;
  cursor: pointer;
  gap: 12px;
}
.logout {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #142c44;
}
.buttonsWrapper {
  padding: 20px 22px;
}

.mainSidebarCompressBtn {
  position: absolute;
  top: 25px;
  left: 190px;
  border: none;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  transform: rotate(180deg);
  cursor: pointer;
}

.mainSidebarCompressBtnisCompress {
  position: absolute;
  top: 25px;
  left: 60px;
  border: none;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  cursor: pointer;
}

.editFormPopupCloseWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.editFormPopupCloseBtn {
  color: #11c89b;
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
}

.editFormPopupCloseBtn:hover {
  background: transparent;
}

.editFormPopupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #3f3e43;
  margin-bottom: 15px;
}

.editFormPopupDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #69696b;
  margin-bottom: 40px;
}

.editFormPopupBtnsSplit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.editFormPopupSaveBtn {
  background: #11c89b;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #11c89b;
  text-transform: capitalize;
  cursor: pointer;
  flex: 1;
}

.editFormPopupSaveBtn:hover {
  background: #11c89b;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #11c89b;
}

.editFormPopupDiscardBtn {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  color: #344054;
  padding: 8px 10px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
  flex: 1;
}

.editFormPopupDiscardBtn:hover {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  color: #344054;
  padding: 8px 10px;
  background: #fff;
}

.editBtn {
  background: transparent;
  border: none;
  color: #3f3e43;
}

/* Tab View */

@media only screen and (max-width: 1024px) {
  .menuDrawer {
    z-index: 1000000;
  }
  .logo_box img {
    height: 30px;
    width: 125;
  }
}

@media only screen and (max-width: 1200px) {
  .logo_box img {
    height: 30px;
    width: 130px;
  }
}
