.formBuildingWrapper {
  display: flex;
  width: 100%;
  /* overflow-y: scroll; */
  /* height: 100%; */
}
.leftSection {
  width: 32%;
  height: 100vh;
  padding: 24px;
}
.primaryFields,
.customFields {
  color: #667085;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 10px;
}
.formName {
  color: #142c44;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 12px;
}
.rightSection {
  /* padding: 28px; */
  /* width: calc(100% - 32%); */
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
}
.webformBuilderWrap {
  display: flex;
}
.webformBuilderLeft {
  width: 40%;
  border-right: 1px solid #ededed;
  padding: 20px;
  max-height: calc(-94px + 100vh);
  overflow-y: auto;
  min-height: calc(-94px + 100vh);
}
.webformBuilderWrapRight {
  width: 60%;
  padding: 20px;
  max-height: calc(-94px + 100vh);
  overflow-y: hidden;
  min-height: calc(-94px + 100vh);
}
.webformFieldsTitle {
  font-size: 14px;
  color: #142c44;
  font-weight: 500;
  margin-bottom: 25px;
}
.webformBuilderFormNameWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding-bottom: 12px;
}
.webformBuilderFormNameWrapRight {
  display: flex;
  align-items: center;
  gap: 10px;
}
.webformBuilderTypeWrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.leadCheckWrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.paymentWrap {
  display: flex;
}
.webformBtnsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid #ededed;
  padding-top: 23px;
}
.webformBtnsSplit {
  display: flex;
  align-items: center;
  gap: 10px;
}
.configureBtn {
  background: #f1f4f880;
  padding: 12px;
  width: 100%;
  color: #8d9091;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  cursor: pointer;
}
