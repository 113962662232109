.taskPopupWrap {
  position: relative;
  /* margin-right: -8px; */
}

.taskPopup {
  position: absolute;
  left: 4px;
  top: -145px;
  margin: 0;
  /* left: 100%; */
  right: 0;
  /* top: -26px; */
  background: #fff;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 150px;
  padding: 8px 8px;
  /* height: 140px; */
  list-style: none;
  z-index: 88;
  /* filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px); */
  box-shadow: 0px 12px 24px 0px rgba(52, 87, 140, 0.12);
}
.isForDispositionForm {
  position: absolute;
  left: unset;
  right: -112px;
  top: unset;
  bottom: 42px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #efefef;
  width: 150px;
  padding: 8px 8px;
  list-style: none;
  z-index: 88;
  box-shadow: 0px 12px 24px 0px rgba(52, 87, 140, 0.12);
  margin: 0;
}
.taskAssignItem {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.taskAssignName {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  text-transform: capitalize;
  line-height: 20px;
}
.call,
.email,
.meetings {
  display: flex;
  color: #69696b;
  font-size: 12px;
  align-items: center;
  padding: 10px 2px;
  gap: 14px;
}
.iconImage {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 0.5px solid #c7c7c7;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedIconImage {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  /* border: 0.5px solid #c7c7c7; */
  padding: 5px;
  text-align: center;
  background-color: #f5f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
