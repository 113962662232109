.userAssignWrap {
  position: relative;
}
.flagAssignPopup {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  width: 160px;
  padding: 0px;
  max-height: 300px;
  list-style: none;
  z-index: 88;
  margin: 5px 0 0 0;
  padding: 13px 0;
}
.isForEnquirymodal {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  width: 160px;
  padding: 0px;
  max-height: 300px;
  list-style: none;
  z-index: 88;
  margin: 5px 0 0 0;
  padding: 13px 0;

  /* filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 2px 8px); */
}

.isForDispositionForm {
  position: absolute;
  right: -120px;
  bottom: 34px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  width: 160px;
  padding: 0px;
  max-height: 300px;
  list-style: none;
  z-index: 88;
  margin: 5px 0 0 0;
  padding: 13px 0;
}

.flagAssignItem {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 18px;
  cursor: pointer;
}

.flagAssignName {
  font-weight: 500;
  font-size: 14px;
  color: #3f3e43;
  text-transform: capitalize;
}
.userAssignAssign {
  background: #2d2d2d;
  border-radius: 60px;
  padding: 8px 8px;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  text-transform: capitalize;
  min-width: 50px;
}
.flagAssignIcon {
  display: flex;
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
}
.flagAssignIcon svg {
  font-size: 19px;
}
.flagAssignBtn {
  padding: 0;
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
