instituteName {
  color: #142c44;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.instituteAddress {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.instituteLogo {
  max-width: 120px;
  overflow: hidden;
}
.receiptHead {
  color: #142c44;
  text-align: end;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.receiptDetailsLabel {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
}
.receiptDetailsValue {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
  margin-left: 4px;
}
.studentName,
.paymentSource,
.supply {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  text-transform: capitalize;
}
.billTo {
  color: #667085;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.supplyPlace {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
  margin-left: 4px;
}
.amountDetails {
  border-radius: 2px;
  background: rgba(20, 44, 68, 0.04);
  padding: 15px 12px;
  margin-top: 10px;
  min-width: 170px;
}
.amountDetailsWrapper {
  display: flex;
  justify-content: end;
}
.amountDetailsLabel,
.amountDetailsValue {
  color: #142c44;
  text-align: right;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.totalLabel,
.totalValue {
  color: #142c44;
  text-align: right;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.balanceDueLabel {
  color: #142c44;
  text-align: right;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.balanceDuevalue {
  color: #142c44;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.totalInWordsLabel {
  color: #142c44;
  text-align: right;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.totalInWordsValue {
  color: #142c44;
  font-family: Poppins;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
