@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.BackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.filterCloeBtn {
  top: 10px;
  right: 486px;
  z-index: 999;
  position: fixed;
  width: 27px;
  height: 27px;
  border: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.filterDrawerHead {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 21px 32px 18px 32px;
  border-bottom: 1px solid #e4e4e4;
}
.filterDrawerTilte {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #142c44;
}
.imapServerWrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.imapServerWrap > div {
  flex-basis: 100%;
}
.imapServerWrapRight {
  display: flex;
  align-items: center;
  gap: 8px;
}
.imapServerWrapRight > div {
  flex-basis: 100%;
}
.imapServerWrapMainLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.googleSign {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  /* border: 1px solid rgba(0, 0, 0, .25); */
  border-radius: 3px;
  /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25); */
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  font-family: "Roboto", sans-serif;
}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
}

.google-sign-in-button:active {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.google-sign-in-button:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
