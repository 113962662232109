.additionalFieldWrap {
  margin-bottom: 14px;
}
.additionalFieldLabel {
  font-size: 12px;
  color: rgb(20, 44, 68);
  font-weight: 500;
  display: flex;
  margin: 0px 0px 5px;
  text-transform: capitalize;
}
.webformBtnWrap button {
  width: 100%;
  margin-bottom: 20px;
}
.webformBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.formFieldsWrap {
  width: 500px;
}
.webformName {
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .formFieldsWrap {
    width: 100%;
  }
}
