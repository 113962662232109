/* Notification Popover */


.notificationWrapper {
  min-width: 450px;
  max-width: 450px;
  Height: 630px;
  right: 60px;
  position: absolute;
  background: #fff;
  z-index: 999999999;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notificationHead {
  color: #142C44;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px; 

}
.markRead {
  color: #667B8B;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.notification {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;

}
.timeDisplay {
  color: #8F97A7;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; 
}
.email {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.notificationItem {
  border-bottom: 1px solid #F4F4F4;
  padding: 14px 0;
  padding: 14px 24px;

}
.viewAll {
  color:#667085;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 40px;
  height: 100px;
}
.notificationItem.unread {
  background-color: rgba(246, 253, 251, 0.80);  
}
.itemHeading {
  color: #142C44;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}
