.paymentType {
  color: #142c44;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 14px;
  display: flex;
}
.paymentTypeBox {
  padding: 20px 30px;
  background: #fff;
  border-radius: 3px;
  border-bottom: 1px solid #efefef;
}
.downPayment {
  color: #142c44;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-right: 12px;
}
.installmentsHead {
  color: #667085;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  padding-top: 16px;
  margin-bottom: 10px;
}
.emiMode,
.noOfEmi,
.startDate,
.amount,
.every {
  color: #142c44;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.every {
  text-transform: capitalize;
}
.amount {
  font-family: "Roboto";
}
.installmentCount {
  margin-right: 10px;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  min-width: 36px;
}
.balance {
  display: flex;
  color: #142c44;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  gap: 4px;
  padding: 4px 0;
}
.noBatch {
  color: #ec3939;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
}
.paymentDataBox {
  padding: 25px 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.paymentDataBoxItemLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.paymentDataBoxItemValue {
  color: #142c44;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  text-transform: capitalize;
}
.paymentDataBoxItem {
  flex-basis: 100%;
  border-left: 1px solid rgba(102, 112, 133, 0.14);
  padding-left: 8%;
}
.paymentDataBoxItem:first-child {
  border-left: none;
  padding-left: 0;
}
.paymentCompleteStatusText {
  color: #11c89b;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.paymentCompleteStatusIcon {
  display: flex;
  overflow: hidden;
}

.leadAutoDebitBox {
  background: #fff;
  padding: 20px 30px;
}
.leadAutoDebitBoxChild {
  padding: 14px 16px;
  border-radius: 8px;
  border: 1px solid #eae9f0;
}
.leadAutoDebitBoxChild {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.leadAutoDebitTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px; /* 192.308% */
}
.leadAutoDebitDesc {
  color: #8a94a6;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.enableAutoDebitDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  margin-bottom: 12px;
}
.enableAutoDebitDesc span {
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px 2px;
}
.enableAutoDebitTitle {
  color: #142c44;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px; /* 138.889% */
  margin-bottom: 22px;
}
.autoEnableBtn {
  border-radius: 6px;
  background: #11c89b;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 16px 16px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.warnBtn {
  border-radius: 6px;
  background: #ec3939;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  border: none;
  padding: 16px 16px;
  width: 100%;
}
.cancelBtn {
  border-radius: 6px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  text-transform: capitalize;
  cursor: pointer;
  padding: 16px 16px;
  width: 100%;
}
.buttonSection {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.emiMainDetailsItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2px;
}
.emiMainDetailsItemValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
/* Tab View */

@media only screen and (max-width: 1024px) {
  .datePicker {
    z-index: 9999999999 !important;
  }
  .calenderView {
    z-index: 9999999999 !important;
  }
}

@media only screen and (max-width: 799px) {
  .recurringBlock {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .recurringBlock {
    flex-wrap: wrap;
  }
}

.membershipTypes {
  display: flex;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 10px;
}
.membershipTypeWrap {
  border-radius: 8px;
  border: 1px solid rgba(151, 71, 255, 0.6);
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  flex-basis: 100%;
}
.membershipTypeTop {
  background: #9747ff;
  padding: 22px 11px 22px 11px;
}
.membershipTypeBottom {
  padding: 16px 16px;
}
.membershipTypeTopHead {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.membershipTypeVal {
  color: #9747ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  padding: 3px 11px;
  border-radius: 4px;
  background: #fff;
}
.membershipTypeTopLabel {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.membershipTypeTopValue {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
}
.membershipTypeBottomLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.membershipTypeBottomValue {
  color: #ab6aff;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}
.typeSelected {
  transform: scale(1.1);
}

.membershipTypeUpdateBtnWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
}
.membershipSaveBtn {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  color: #9747ff;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding: 4px 24px;
  text-transform: capitalize;
}
.membershipCancelBtn {
  border-radius: 4px;
  border: 1px solid rgba(232, 232, 232, 0.6);
  background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  width: 33.6px;
  height: 33.6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.membershipAddDiscountBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 10.348px;
  font-weight: 500;
  line-height: 20.696px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
.membershipDateBox {
  background: #fafafc;
  padding: 22px 24px;
  border-radius: 4px;
}
.membershipDateTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
}
.membershipDateDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 9px;
}
.membershipDateLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}
.membershipBtnWrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px 20px 0px;
}
.customTypeRow {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 22px;
}
.customTypeFieldLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 4px;
}
.addMoreTypeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 503px;
}
.addMoreTypeBtn {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: rgba(241, 244, 248, 0.4);
  padding: 6px 14px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.deleteTypeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}
.batchCustomTitle {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
.definedCustomPayRow {
  display: flex;
  gap: 20px;
  border-radius: 6px;
  border: 1px solid #f0f0f5;
  background: #fafafc;
  padding: 14px 23px;
  margin-bottom: 7px;
  justify-content: space-between;
}
.definedCustomPayRow:last-child {
  margin-bottom: 0;
}
.definedCustomPayRow > div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.definedCustomPayItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.definedCustomPayItemValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px;
}
.randomPayWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.leadPaySecTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.randomPayBtns {
  display: flex;
  align-items: center;
  gap: 4px;
}
.editSectionBtnWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}
.customTypeWrap {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  padding: 22px 26px;
}
.leadRandomPayPopup {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  margin-top: 20px;
}
.leadRandomPayPopup > button {
  flex-basis: 100%;
}
.membershipTypeTopHeadRight {
  display: flex;
  align-items: center;
}
.membershipDetailsType {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  border-radius: 4px;
  background: #9747ff;
  padding: 3px 11px;
}
.paymentDetailsHead {
  display: flex;
  background: rgb(250, 250, 252);
  padding: 20px 24px;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 5px;
}
.paymentDetailsHeadLeft {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex: 1;
}
.paymentDetailsHeadItemLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.paymentDetailsHeadItemValue {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPrice {
  color: #11c89b;
  font-size: 20.939px;
  font-weight: 600;
  line-height: 30px;
}
.paymentDetailsHeadFinalPriceType {
  color: #8a94a6;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPriceWrap {
  padding-left: 30px;
  border-left: 1px solid #e3e3e3;
}
.paymentDetailsHeadRight {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
}
.noDetailsWrap {
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.noDetailsText {
  color: #8a94a6;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.noDetailsBtn {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  background: transparent;
}
