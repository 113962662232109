.staffRouterDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
  padding-right: 10px;
}

.courseListFilterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.courseListStatusFilterWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.courseListStatusFilterItem {
  font-weight: 600;
  font-size: 14px;
  color: #3f3e43;
  text-decoration: none;
  padding: 5px 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.FilterBtnactive {
  color: #23a67b;
}

.NoBatch {
  text-align: center;
  display: flex;
  justify-content: center;
}

/* .branchListWrapper {
  display: flex;
  gap: 20px; */
/* justify-content: space-around; */
/* flex-wrap: wrap;
  margin-bottom: 50px;
} */

.branchList_Main {
  width: 99%;
}

.branchCard {
  min-width: 100%;
  max-width: 275px;
  display: block;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0px 12px 18px rgba(66, 66, 66, 0.06); */
  /* border-radius: 8px; */
  background: #ffffff;
  z-index: 4;
  text-decoration: none;
  padding: 10px 20px;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  /* flex: 1 1 285px; */
  border: 1px solid rgba(222, 222, 222, 0.7);
  cursor: pointer;
}

.branchCardImg {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
  min-width: 120px;
}

.branchCardImg img {
  width: 100%;
  aspect-ratio: 1;
}

.branchCardDetailsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 5px;
  /* flex: 1; */
  /* width: calc(100% - 135px); */
  width: 100%;
}

.branchCardDetailsTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

/* .branchName {
  font-weight: 600;
  font-size: 16px;
  color: #3f3e43;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
} */

.branchMail {
  font-weight: 400;
  font-size: 13px;
  color: #667085 !important;
  overflow: hidden;
  font-family: "Poppins";
}

.branchStatus svg {
  font-size: 18px;
}

.statusActive {
  color: #23a67b;
  display: flex;
}

.statusInActive {
  color: #d32f2f;
  display: flex;
}

.branchDesc {
  font-weight: 400;
  font-size: 14px;
  color: #585858;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
  margin-top: "8px";
}

/* .branchDesc > div {
} */
.branchContactWrap {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.branchContactWrap li {
  font-weight: 400;
  font-size: 12px;
  color: #c7c7c7;
}

.googleMap {
  width: 100%;
  margin-top: 15px;
}

.branchSaveButton {
  float: right;
  margin-top: 40px;
  margin-bottom: 80px;
}

.editBtn {
  background: transparent;
  border: none;
  color: #3f3e43;
}

.addHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 40%;
  margin-bottom: 30px;
  position: relative;
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  color: #3f3e43;
  margin-bottom: 0;
  margin-top: 0;
}

.editFormPopupCloseWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.editFormPopupCloseBtn {
  color: #1877f2;
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
}

.editFormPopupCloseBtn:hover {
  background: transparent;
}

.editFormPopupTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #3f3e43;
  margin-bottom: 15px;
}

.editFormPopupDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #69696b;
  margin-bottom: 40px;
}

.editFormPopupBtnsSplit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.editFormPopupSaveBtn {
  background: #1877f2;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #1877f2;
  text-transform: capitalize;
  cursor: pointer;
}

.editFormPopupSaveBtn:hover {
  background: #1877f2;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #1877f2;
}

.editFormPopupDiscardBtn {
  border: 2px solid #1a73e8;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a73e8;
  padding: 8px 10px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
}

.editFormPopupDiscardBtn:hover {
  border: 2px solid #1a73e8;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a73e8;
  padding: 8px 10px;
  background: #fff;
}

.addressDetails {
  margin: 0px;
  text-transform: capitalize;
}

.courseListSelectFilterWrap {
  display: flex;
  align-items: center;
}

.editBtn {
  background: #98989833;
  /* border: none; */
  color: #142c44;
  margin-bottom: 5px;
  border: 1px solid #98989833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: fit-content;
}
.branchMainWrapper {
  display: flex;
  /* padding-bottom: 50px;
  margin-top: -22px; */
  width: 100%;
}
.rightSubSection {
  max-width: 780px;
  min-width: 90%;
  border: 1px solid #ebebed;
  border-radius: 6px;
  margin-left: 20px;
  margin-top: 20px;
}
.leftSection {
  width: 32%;
  border-right: 1px solid #ededed;
  height: 100vh;
  /* padding-top: 22px; */
}
.rightSection {
  padding: 40px 20px 20px 20px;
  width: calc(100% - 32%);
  overflow-y: auto;
  height: calc(100vh - 114px);
  display: flex;
  flex-direction: column;
}
.branchListWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ededed;
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
}
.selectedBranchCard {
  background: #f8f8f8;
  border-left: 2px solid #11c89b;
}
.NoBranch {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #142c44;
}
.TryAnother {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}
.branchName {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
  margin-top: 5px;
}
.branchNameRight {
  font-weight: 500;
  font-size: 14px;
  color: #142c44;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
  margin-top: 5px;
}
.branchDesignation {
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  font-family: "Poppins";
  text-transform: capitalize;
}
.key {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  text-transform: capitalize;
}
.value {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
}
.selectedValDiv {
  background: #f3f3f3;
  color: #667085;
  padding: 6px 8px;
  border-radius: 4px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 20px;
}
.closeModalBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.closeModalBtn {
  border: none;
  background: none;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}
.branchLimitTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.branchLimitDes {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.branchHead {
  color: #142C44;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
}

@media (min-width: 50px) and (max-width: 1024px) {
  .courseListFilterWrap {
    display: block;
  }

  .courseListSelectFilterWrap {
    margin-top: 15px;
  }

  /* .branchMainWrapper {
    display: block;
  }
  .leftSection {
    width: 100%;
  }
  .rightSection {
    width: 100%;
  } */
}

@media only screen and (max-width: 900px) {

  .branchMainWrapper {
    border-top: 1px solid #ededed;  
  }
  .branchHead {
    font-size: 16px;
  }

}

@media only screen and (max-width: 1024px) {
  
  .link {
    padding-right: 6px;  
  }
  .leftSection {
    width: 40%;    
  }

}
