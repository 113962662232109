.paymentDetailsHead {
  display: flex;
  background: rgb(250, 250, 252);
  padding: 20px 24px;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 5px;
}
.paymentDetailsHeadLeft {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex: 1;
}
.paymentDetailsHeadItemLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.paymentDetailsHeadItemValue {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPrice {
  color: #11c89b;
  font-size: 20.939px;
  font-weight: 600;
  line-height: 30px;
}
.paymentDetailsHeadFinalPriceType {
  color: #8a94a6;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPriceWrap {
  padding-left: 30px;
  border-left: 1px solid #e3e3e3;
}
.paymentDetailsHeadRight {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
}
.sessionBoxes {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}
.sessionBox {
  border-radius: 4px;
  flex: 1;
  padding: 22px 22px;
  color: #fff;
}
.sessionBoxTop {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 18px;
}

.sessionBoxTopTitle {
  font-size: 13px;
}
.sessionBoxCount {
  font-size: 26px;
  font-weight: 600;
}
.sessionBoxHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 14px;
}
.leadPayHeadTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.addMoreSessionBtn {
  border: 1px solid #6670851f;
  background: #fff;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  font-weight: 500;
}
.addMoreBtn {
  border: none;
  background: #fff;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  font-weight: 500;
  width: fit-content;
}
.cross_button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.formHeading {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.addSessionFieldLabel {
  color: #142c44;
  font-size: 14px;
  margin-bottom: 5px;
}
.countIncrementField {
  height: 44px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d4d4d8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 10px 10px 20px;
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
}
.countIncrementBtnsWrap {
  border: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.countIncrementBtnsWrap button {
  border: none;
  background: transparent;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 10px;
}
.countIncrementBtnDivider {
  width: 1px;
  height: 14px;
  background: #e4e4e4;
  border-radius: 2px;
}
.addSessionsBtnsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 20px;
}

.addSessionFinalPriceWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid #f0f0f5;
  background: #fafafc;
  padding: 13px 23px;
}
.addSessionFinalPriceLabel {
  color: #000000;
  font-size: 13px;
  font-weight: 700;
}
.addSessionFinalPrice {
  border: 1px solid #d4d4d8;
  padding: 10px;
  color: #11c89b;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  border-radius: 6px;
  min-width: 150px;
}
.addSessionFields {
  border: 1px solid #f0f0f5;
  border-radius: 6px;
}
.addSessionFieldsChild {
  padding: 12px 22px;
  max-height: 300px;
  overflow-y: auto;
}
.popupHeadWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 18px;
}
.scheduleSessionColLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}
.scheduleSessionWrapper {
  border: 1px solid #e4e5e7;
  border-radius: 4px;
}
.scheduleSessionChild {
  display: flex;
  border-bottom: 1px solid #e4e5e7;
}
.scheduleSessionLeft {
  width: 35%;
  border-right: 1px solid #e4e5e7;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.scheduleSessionRight {
  flex: 1;
  display: flex;
}
.scheduleSessionCalendar {
  flex: 1;
  padding: 24px;
}
.scheduleSessionTime {
  width: 35%;
  padding: 24px 24px 24px 0;
}
.scheduleSessionTimeItem {
  border: 1px solid #eeeeee;
  padding: 12px 12px;
  text-align: center;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  margin-bottom: 4px;
  border: none;
  cursor: pointer;
}
.scheduleSessionBtnsWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  padding: 18px 24px;
}
.sessionTabItemWrap {
  display: flex;
  border: 1px solid #6670851f;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
}
.sessionTabItemRight {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: 22px 24px;
}
.sessionTabItemDateWrap {
  padding: 16px 24px;
  border-right: 1px solid #e4e5e7;
  text-align: center;
}
.sessionTabItemDate {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}
.sessionTabItemDay {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
}
.sessionTabItemName {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
.sessionTabItemEditWrap {
  display: flex;
  align-items: center;
  gap: 26px;
}
.sessionTabItemTiming {
  color: #69696b;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}
.sessionTabItemPayStatus {
  padding: 8px 4px;
  border-radius: 28px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  min-width: 50px;
}
.sessionTabItemStatus {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 8px;
  border-radius: 4px;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.sessionTabItemStatus::first-letter {
  text-transform: capitalize;
}
.noDataText {
  font-size: 12px;
  font-weight: 500;
  color: #8a94a6;
  margin-bottom: 12px;
}
.noDataWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.editSectionBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
}
.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 10px;
}
.modalDes {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 8px;
  margin-bottom: 21px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.buttonSection {
  display: flex;
  justify-content: center;
  gap: 14px;
}
.sessionTabDescWrap {
  display: flex;
  align-items: center;
}
.sessionTabDescWrapPop {
  display: none;
}
.helpIconContainer:hover .sessionTabDescWrapPop {
  display: block;
  position: absolute;
  background-color: #fff;
  /* width: 300px; */
  top: 20px;
  color: #69696b;
  padding: 5px 10px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  font-size: 12px;
  /* box-shadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)"; */
  border: "1px solid #E4E4E7";
  z-index: 99999999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  right: 0;
}
.sessionTabDesc {
  color: #69696b;
  font-size: 12px;
}
.sessionTabDesc::first-letter {
  text-transform: capitalize;
}
.sessionTabDescWrapPop::first-letter {
  text-transform: capitalize;
}
.modalDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 8px;
}

.selectedSessionItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #e4e5e7;
  background-color: rgba(17, 200, 155, 0.16);
}
.selectedSessionItemName {
  flex: 1;
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectedSessionItemName::first-letter {
  text-transform: capitalize;
}
.selectedSessionItemDate {
  width: 180px;
  color: #69696b;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}
.selectedSessionItemTime {
  width: 180px;
  color: #69696b;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}
