.leadConvertionSuccessWrap {
  border-radius: 3px;
  background: #11c89b;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
}
.leadConvertionSuccessValue {
  color: #fefefe;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.leadConvertionSuccessIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.leadConvertionSuccessIcon img {
  max-width: 100%;
}

.leadWarningMessageWrap {
  border-radius: 6px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
}
.leadWarningMessageValue {
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.leadWarningMessageIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.leadWarningMessageIcon img {
  max-width: 100%;
}
