.changePasswordWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}


/* Tab View */

@media only screen and (max-width: 899px) {
  .changePasswordWrapper {
   padding-top: 80px;
  } 
}

@media only screen and (width: 899px) {
    .changePasswordWrapper {
     padding-top: 80px;
    } 
  }