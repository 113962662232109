.membershipDetails {
  display: flex;
  justify-content: space-between;
  min-height: 42px;
  gap: 10px;
}
.membershipDetailsChild {
  border-radius: 8px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  padding: 12px 16px;
  flex: 1 1;
  margin-bottom: 14px;
}
.membershipDetailsChildTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.membershipDetailsChildLeftTop {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-bottom: 2px;
}
.membershipDueDate {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}

.membershipTypes {
  display: flex;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 10px;
}
.membershipTypeWrap {
  border-radius: 8px;
  border: 1px solid rgba(151, 71, 255, 0.6);
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  /* flex-basis: 100%; */
  flex-basis: calc(25% - 6px);
}
.membershipTypeTop {
  background: #935dd9;
  padding: 22px 11px 22px 11px;
  opacity: 0.8;
}
.membershipTypeBottom {
  padding: 16px 16px;
  opacity: 0.8;
}
.membershipTypeTopHead {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.membershipTypeVal {
  color: #9747ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  padding: 3px 11px;
  border-radius: 4px;
  background: #fff;
}
.membershipTypeTopLabel {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.membershipTypeTopValue {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
}
.membershipTypeBottomLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.membershipTypeBottomValue {
  color: #ab6aff;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}
.typeSelected {
  transform: scale(1.1);
}
.membershipTypeWrap.typeSelected .membershipTypeTop {
  background: #9747ff;
  opacity: 1;
}
.membershipTypeWrap.typeSelected .membershipTypeBottom {
  opacity: 1;
}
.membershipTypeUpdateBtnWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
}
.membershipSaveBtn {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  color: #9747ff;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding: 4px 24px;
  text-transform: capitalize;
}
.membershipCancelBtn {
  border-radius: 4px;
  border: 1px solid rgba(232, 232, 232, 0.6);
  background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  width: 33.6px;
  height: 33.6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.membershipAddDiscountBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 10.348px;
  font-weight: 500;
  line-height: 20.696px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
.membershipDateBox {
  background: #fafafc;
  padding: 22px 24px;
  border-radius: 4px;
}
.membershipDateTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
}
.membershipDateDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 9px;
}
.membershipDateLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}
.membershipBtnWrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px 20px 0px;
}
.membershipTypeTopHeadRight {
  display: flex;
  align-items: center;
}
.emiSubaymentsWrapper {
  padding: 16px 0;
  border-top: 1px solid #6670851f;
}
.emiSubaymentsWrap {
  display: flex;
  gap: 9px;
}
.emiSubaymentsRow {
  display: flex;
  align-items: center;
  gap: 42px;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 8px 16px;
  margin-bottom: 10px;
  flex: 1;
}
.emiSubaymentsRowItem {
  display: flex;
  align-items: center;
  gap: 10px;
}
.emiSubaymentsLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.emiSubaymentsValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}
.viewBreakDownBtn {
  color: #667085;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.balanceTag {
  font-size: 12px;
  color: #667085;
}
