.demoBookingWrapper {
  display: flex;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 16px;
}
.demoBookingLeft {
  padding: 24px 0;
  border-right: 1px solid rgba(228, 228, 228, 0.7);
}
.demoBookingLeftHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 30px;
}
.demoBookingTitle {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}
.demoBookingLegends {
  display: flex;
  align-items: center;
  gap: 13px;
}
.demoBookingLegend {
  display: flex;
  align-items: center;
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 175%;
  text-transform: capitalize;
}
.demoBookingLegend img {
  margin-right: 6px;
}
.demoBookingCalendar {
  padding: 0 6px;
}

/* Pick time */
.demoBookingRight {
  width: 100%;
  height: 100%;
}
.pickTimeWrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noDemoScreen {
  width: 100%;
  height: 406px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 10px;
}
.noDemoScreenDesc {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 7px;
}
.existingDemoScreen {
  width: 100%;
  height: 406px;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 10px; */
}
.existingDemoList {
  padding: 12px 12px;
  max-height: calc(406px - 70px);
  overflow-y: auto;
}
.existingDemoScreenBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 8px 10px;
  border-top: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.03);
}
.demoCardWrap {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  padding: 12px 15px;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  margin-bottom: 6px;
}
.demoCardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 4px;
}
.demoCardName {
  color: #3f3e43;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}
.demoCardType {
  border-radius: 16px;
  background: #f2f4f7;
  padding: 2px 8px;
  color: #344054;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  width: fit-content;
  margin-bottom: 10px;
}
.demoCardBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.demoCardCount {
  display: flex;
  align-items: center;
  gap: 4px;
}
.demoCardCountLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.demoCardCountValue {
  border-radius: 4px;
  background: #f3f3f3;
  padding: 3px 8px;
  color: #142c44;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.demoCardBatchDetails {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 7px;
}
.demoCardBatchItem {
  display: flex;
  gap: 6px;
  align-items: center;
}
.demoCardBatchItemValue {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.demoCardBatchItemIcon {
  display: flex;
  align-items: center;
}

.demoSelectedWrapper {
  width: 100%;
  height: 406px;
  position: relative;
}
.demoSelectedWrapperChild {
  padding: 16px 53px 16px 16px;
}
.demoBookedCardSelectedAvatar {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  color: #667085;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.22px;
}
.demoBookedWrapperChild {
  padding: 12px 12px;
  /* max-height: calc(406px - 85px);
  overflow-y: auto;
  min-height: calc(406px - 85px); */
}
.demoBookedCard {
  border-radius: 4px;
  border: 1px solid rgba(17, 200, 155, 0.2);
  background: rgba(17, 200, 155, 0.02);
  padding: 14px 15px;
  margin-bottom: 10px;
}
.resheduleBtn {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  text-transform: capitalize;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;
}
.demoCancelBtn {
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  color: #ec3939;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}
.demoBookedCardBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.demoBookedHighlightWrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.demoBookedHighlightIcon {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 5px;
}
.demoBookedHighlightIcon img {
  max-width: 100%;
}
.demoBookedHighlightLabel {
  color: #11c89b;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.demoBookedTitle {
  color: #3f3e43;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.newDemoHead {
  display: flex;
  /* align-items: center;  */
  gap: 8px;
  margin-bottom: 20px;
}
.newDemoItemSplit {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 17px;
}
.newDemoItemSplitLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  margin-top: 7px;
  min-width: 90px;
}
.timeSlotWrap {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #bdbfc3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.731px;
}
.batchType {
  width: 110px !important;
  height: 35px !important;
  padding: 0px !important;
  border-radius: 60px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
}
.demoCancelPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}
.demoCancelPopupWrap {
  background: #fff;
  border-radius: 12px;
  width: 408px;
  height: 172px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  padding: 16px;
}
.modalTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.modalDes {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 8px;
  margin-bottom: 21px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.buttonSection {
  display: flex;
  justify-content: center;
}
.noButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  margin-right: 12px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #344054;
  cursor: pointer;
}
.yesButton {
  background: #ec3939;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}
.BackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.MuiStaticDatePicker-day[data-has-demo="true"]::before {
  content: "•"; /* Display a bullet point as a green dot */
  color: green;
  position: absolute;
  bottom: 2px; /* Adjust the position of the dot as needed */
  left: 50%;
  transform: translateX(-50%);
}
.custom-calendar-button .MuiButtonBase-root.MuiPickersDay-root.demo-day {
  background-color: red;
}
.demoCalander button.MuiButtonBase-root.MuiPickersDay-root.demo-day {
  position: relative;
  background: red !important;
}
button.MuiButtonBase-root.MuiPickersDay-root.demo-day {
  position: relative;
  background: red !important;
}
.demoCalander
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.demo-day::after {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #11c89b;
  border-radius: 50%;
  bottom: 0;
  content: "";
}
.scheduleSelectTtle {
  color: #142c44;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
}
.demoCardChildWrap {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.demoCardScheduleWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
