.courseListFilterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.courseListStatusFilterWrap {
  display: flex !important;
  align-items: center !important;
  gap: 20px;
}

.courseListStatusFilterItem {
  font-weight: 600;
  font-size: 14px;
  color: #3f3e43;
  text-decoration: none;
  padding: 5px 0;
  border: none;
  background: transparent;
  cursor: pointer;
}
.courseDetailsTopWrapper {
  padding: 15px 15px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  display: flex;
  gap: 34px;
  align-items: flex-start;
  justify-content: space-between;
}

.courseDetailsTopLeft {
  width: 321px;
  height: 189px;
  display: flex;
  justify-content: center;
  max-width: 321px;
  overflow: hidden;
  border-radius: 2px;
}
.courseDetailsTopRight {
  flex: 1;
}
.courseDetailsTopRightTopWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 6px;
}
.courseNameWrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.courseName {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
}
.courseName::first-letter {
  text-transform: capitalize;
}

.courseBasicDetailsWrap {
  border-radius: 4px;
  background: #fafafc;
  padding: 18px 18px;
}
.courseBasicDetailsTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
}
.courseBasicDetailsItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.courseBasicDetailsItemValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.courseBasicDetailsType {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.courseDetailsStatus {
  border-radius: 4px;
  background: rgba(17, 200, 155, 0.08);
  color: #11c89b;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.848px;
  text-transform: capitalize;
  padding: 5px 8px;
}
.courseDetailsCertificate {
  border-radius: 4px;
  background: #f3f3f3;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.848px;
  text-transform: capitalize;
  padding: 5px 8px;
}
.courseDetailsTagsWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}
.courseDetailsTagsLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}
.courseDetailsTagsValues {
  display: flex;
  align-items: center;
  gap: 6px;
}
.courseDetailsTagsValue {
  border-radius: 4px;
  background: #f3f3f3;
  color: #142c44;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 8px;
  text-transform: capitalize;
}
.courseBoxTitleSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}
.courseBoxTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
}
.courseBoxCopySplit {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}
.courseBoxCopyTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}

.courseDescription {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
.courseDetailsTopEdit {
  display: flex;
  align-items: center;
  gap: 6px;
}
.noDetailsText {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 23.562px;
}

.paymentTypeBoxHead {
  border-radius: 7px 7px 0px 0px;
  border: 1px solid #f0f0f0;
  background: #f9fbfd;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.paymentTypeBoxHeadTitle {
  color: #142c44;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}
.paymentTypeBoxHeadValue {
  border-radius: 4px;
  background: rgba(5, 112, 219, 0.08);
  padding: 3px 11px;
  color: #0570db;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.paymentTypeBoxChild {
  padding: 12px 12px;
}
.paymentTypeOneTimeWrap {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.03);
  background: #fafafc;
  padding: 15px 17px;
}
.paymentTypeLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
.paymentTypeValue {
  color: #142c44;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.paymentTypeInstallmentWrap {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.03);
}
.paymentTypeInstallmentTop {
  background: #fafafc;
  padding: 15px 17px;
}
.paymentTypeInstallmentHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
}
.paymentTypeInstallmentTableHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  color: #142c44;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 0px;
}
.paymentTypeInstallmentTableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 8px 14px;
}
.paymentTypeInstallmentTableRowLeft {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}
.paymentTypeInstallmentTableRowRight {
  color: #667085;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.paymentTypeMembershipWrap {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.paymentTypeMembershipItem {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.03);
  background: #fafafc;
  padding: 15px 15px;
  flex-basis: calc(50% - 9px);
}
.courseMembershipType {
  border-radius: 4px;
  background: rgba(102, 112, 133, 0.08);
  padding: 3px 11px;
  margin-bottom: 16px;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  width: fit-content;
}
/* Edit course */
.pageWrapper {
  padding: 38px 30px;
  max-height: 100%;
}
.pageHead {
  border-bottom: 1px solid #ededed;
}
.pageHeadTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.pageChildWrap {
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  margin-top: 33px;
  padding-right: 4px;
}
.courseEditSectionWrap {
  margin-bottom: 24px;
}
.courseEditSectionTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}
.editSectionBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  transition: 0.1s ease-in-out;
}

/* Edit Payment */
.coursePayEditTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 6px;
}
.coursePayEditCourseNameWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 22px;
}
.coursePayEditCourseNameLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
.coursePayEditCourseNameValue {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}
.coursePayEditSectionWrap {
  margin-bottom: 34px;
}
.coursePayEditSectionTitle {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.payTypeAccordionHead {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-right: 5px;
  width: 100%;
}
.payTypeAccordionHeadRight {
  flex: 1;
}
.moreDetailsCloeBtn {
  top: 16px;
  right: 340px;
  z-index: 999;
  position: fixed;
  width: 27px;
  height: 27px;
  border: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.moreDetailsTitle {
  color: #142c44;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  padding: 20px 32px;
  border-bottom: 1px solid #f4f4f4;
}
.payTypeAccordionHeadName {
  color: #142c44;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}
.payTypeAccordionHeadDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}
.payTypeAccordionDescWrap {
  color: #667085;
  padding: 14px 24px;
  overflow-y: auto;
  max-height: calc(100vh - 65px);
}
.payTypeSelectBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  padding: 0px;
}

.paymentTypeBoxHead {
  border-radius: 7px 7px 0px 0px;
  background: #f8fafc;
  padding: 16px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.paymentTypeBoxHeadTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}

.membershipTypeRow {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 22px;
}
.membershipTypeFieldLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 4px;
}
.addMoreTypeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 503px;
  margin-bottom: 15px;
}
.addMoreTypeBtn {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: rgba(241, 244, 248, 0.4);
  padding: 6px 14px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.deleteTypeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}
.definedMembershipRow {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #f0f0f5;
  background: #fafafc;
  padding: 16px 22px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.definedMembershipRowLeft {
  display: flex;
  align-items: center;
  gap: 100px;
}
/* .definedMembershipItem {
  display: flex;
  align-items: center;
  gap: 9px;
  flex-wrap: wrap;
} */
.definedMembershipItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}
.definedMembershipItemValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.regularTotalPriceLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 4px;
}
.regularTotalPriceValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 24px;
}
.regularEmiBox {
  transition: 0.1s ease-in-out;
}
.regularEmiHead {
  border-radius: 2px;
  background: rgba(208, 213, 221, 0.18);
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.regularEmiHead > div {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.regularEmiRow {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 16px 14px;
  border-bottom: 1px solid #eaecf0;
}
.regularEmiRow:last-child {
  border-bottom: none;
}
.regularSectionTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 14px;
}
.regularEmiAmount {
  border-radius: 6px;
  background: rgba(241, 244, 248, 0.5);
  padding: 10px 12px;
  color: #142c44;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  min-width: 115px;
}
.regularEmiNumber {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.regularEmiDetailsRow {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  gap: 30px;
}
.regularEmiDetailsRow > div {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}
.coursePayCustomBox {
  border-radius: 6px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  padding: 20px 20px;
}
.coursePayCustomHead {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.coursePayCustomHeadTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}
.coursePayCustomHeadDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}
.courseListingName {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 95%;
}
.courseSwitchWrap {
  display: flex;
  align-items: center;
}
.courseSwitchLabel {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}
.courseThumbnailLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 12px;
}
.payTypeAccordionDescTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.payTypeAccordionDescription {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
}
.regularDataWrap {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ececec;
  background: #fafafc;
  padding: 20px 40px;
  gap: 25%;
}
.regularDataItem {
  display: flex;
  align-items: center;
  gap: 9px;
}
.regularDataItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}
.regularDataValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.FilterBtnactive {
  color: #23a67b;
}

.NoBatch {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f4;
  width: 50%;
  height: 200px;
  margin: 100px auto;
  background-color: #f4f4f4;
}

.NoCourse {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 200px auto;
  background-color: #f4f4f4;
  padding: 10px;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  cursor: pointer;
}

.courseList_main {
  width: 83%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.courseListWrapper {
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 120px;
}

.courseCardWrap {
  min-width: 230px;
  max-width: 230px;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 12px 24px rgba(52, 87, 140, 0.12);
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid rgba(222, 222, 222, 0.7);
  z-index: 4;
}

.courseCard {
  text-decoration: none;
  display: block;
  overflow: hidden;
  width: 100%;
}

.courseCardWrap:hover .courseCardArchiveBtn {
  display: flex;
}

.courseCardArchiveBtn {
  position: absolute;
  top: 11px;
  right: 7px;
  background: #ffffff;
  width: 37px;
  height: 37px;
  display: none;
  align-items: center;
  justify-content: center;
  color: #69696b;
  border-radius: 50%;
  border: none;
  z-index: 5;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.courseCardArchiveBtn svg {
  font-size: 19px;
}

.courseCardDetailsWrap {
  padding: 0px 14px 0px 14px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.courseCardImg {
  padding: 10px;
}

.courseCardImg img {
  width: 100%;
  min-height: 125px;
  max-height: 125px;
  /* border-radius: 6px; */
}

.courseCardDetailsTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.courseCategory {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* color: #ffb41f; */
  color: #667085;
  text-transform: capitalize;
  font-family: "Poppins";
}
.batches {
  color: #3f3e43;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* text-align: end; */
  margin-top: 14px;
}
.courseCategoryRed {
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #ec3939;
  text-transform: capitalize;
  font-family: "Poppins";
}
.courseStatus svg {
  font-size: 20px;
}

.statusActive {
  color: #23a67b;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  background-color: #d5f5ec;
}

.statusInActive {
  color: #d32f2f;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  background-color: #f7d2d2;
}
.statusDraft {
  color: #494747;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 3px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  background-color: #f1f1f1;
}

.inactiveTag {
  border-radius: 16px;
  background: #fef3f2;
  color: #e7453a;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  padding: 4px 10px;
  text-align: center;
}

.courseCardDetailsBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(115, 114, 114, 0.76);
  text-transform: capitalize;
}

.section {
  padding: 30px 0;
  /* border-bottom: 1px solid #c7c7c7; */
}

.section:last-child {
  border-bottom: 0;
}

.sectionHeading {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.sectionNumber {
  border: 1.2px solid #23a67b;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #23a67b;
  margin-right: 20px;
}

.accordion {
  margin: 0px !important;
  border-bottom: 1px solid #e9e9e9 !important;
}
.accordion::before {
  height: 0 !important;
}
.accordionedit {
  margin-bottom: 10px !important;
  border-bottom: 0 !important;
}
.accordionedit::before {
  height: 0 !important;
}
.Mui-expanded {
  background: rgba(193, 195, 199, 0.2);
  border-radius: 50%;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  background: rgba(193, 195, 199, 0.2);
  border-radius: 50%;
}
.roundButtonsWrapper {
  margin-top: 28px;
  display: flex;
}

.roundButton {
  padding: 5px 15px;
  border: 2px solid #69696b;
  margin-right: 10px;
  border-radius: 25px;
  background: #fff;
  color: #69696b;
  font-weight: 500;
  cursor: pointer;
}

.roundButton.active {
  background: #23a67b;
  border-color: #23a67b;
  color: #fff;
}

.tagsContainer {
  background: #fff;
  min-height: 156px;
  border: 1px solid #d4d4d8;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: unset !important;
  border-radius: 6px;
}

.tagItem {
  border: 1px solid #69696b;
  padding: 5px;
  margin-right: 5px;
  border-radius: 25px;
}

.deleteTagItemBtn {
  background: unset;
  border: unset;
  cursor: pointer;
}

.addListbar {
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
}

.btnSaveChange {
  padding-bottom: 60px !important;
  text-decoration: none !important;
  padding-right: 10px;
}

.courseType {
  /* width: 110px !important; */
  height: 30px !important;
  padding: 0px !important;
  border-radius: 40px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  padding: 8px 16px !important;
  text-align: center !important;
}

.tagItem {
  border: 0;
  width: unset;
  padding: 5px;
  width: unset;
}

.flexBox {
  display: flex;
}

.editFormPopupCloseWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.editFormPopupCloseBtn {
  color: #1877f2;
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
}

.editFormPopupCloseBtn:hover {
  background: transparent;
}

.editFormPopupTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #3f3e43;
  margin-bottom: 15px;
}

.editFormPopupDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #69696b;
  margin-bottom: 40px;
}

.editFormPopupBtnsSplit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.editFormPopupSaveBtn {
  background: #1877f2;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #1877f2;
  text-transform: capitalize;
  cursor: pointer;
}

.editFormPopupSaveBtn:hover {
  background: #1877f2;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #1877f2;
}

.editFormPopupDiscardBtn {
  border: 2px solid #1a73e8;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a73e8;
  padding: 8px 10px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
}

.editFormPopupDiscardBtn:hover {
  border: 2px solid #1a73e8;
  border-radius: 4px;
  min-width: 107px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a73e8;
  padding: 8px 10px;
  background: #fff;
}

.editBtn {
  background: #98989833;
  /* border: none; */
  color: #142c44;
  margin-bottom: 5px;
  border: 1px solid #98989833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: fit-content;
}

.inputLabel {
  font-weight: 600;
  font-size: 18px;
  color: #3f3e43;
  margin-bottom: 10px;
}

.courseListSelectFilterWrap {
  display: flex;
  align-items: center;
}
.selectedValDiv {
  background: #f3f3f3;
  color: #667085;
  padding: 6px 8px;
  border-radius: 4px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
}

.leftSection {
  width: 65%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  margin-right: 10px;
  padding: 20px 25px;
}
.rightSection {
  /* width: calc(100% - 341px);
   */
  width: 65%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  padding: 20px 25px;
  margin-top: 20px; /*newly added*/
}
.tagLable {
  background: #f3f3f3;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* color: #142c44; */
  margin: 3px;
}

.courseCategoryName {
  /* color: #667085; */
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* width: 50%; */
}
.coursePrice {
  color: #142c44;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* width: 50%; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* text-align: end; */
  margin-bottom: 10px;
}

.rightHeading {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #142c44;
}
.rightDescription {
  color: #667085;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.rightDescription img {
  max-width: 100%;
}
.summary {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #667085;
}
.summaryDesc {
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.backButton {
  margin: 0px 0px 5px;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.radioLabel {
  color: #667085;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  text-transform: capitalize;
}
.radioLabelBracket {
  color: #818a99;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

@media (min-width: 50px) and (max-width: 1024px) {
  .courseListFilterWrap {
    display: block;
  }

  .courseListSelectFilterWrap {
    margin-top: 15px;
  }
}
@media (min-width: 50px) and (max-width: 1200px) {
  .mainSection {
    display: block;
  }
  .leftSection {
    width: 100%;
  }
  .rightSection {
    width: 100%;
  }
  .tagsSection {
    width: 100%;
    margin-top: 20px;
  }
}

/* New Course Changes */

.courseDetailsFirstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseDetailsSection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 65%;
}

.courseHeader {
  display: flex;
  justify-content: space-between;
  background-color: rgba(247, 247, 251, 0.48);
  padding: 30px;
}

.courseBasicInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}

.courseLevel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
}

.levelIcon {
  margin: 0 5px;
  height: 15px;
}

.courseBasicInfoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courseInfoIcon {
  margin-right: 10px;
}

.typeHeading {
  font-size: 13px;
  color: #667085;
  font-weight: 400;
}

.typeBody {
  font-size: 14px;
  color: #142c44;
  font-weight: 500;
  text-transform: capitalize;
}

.courseDetailsImageSection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 321px;
  height: 200px;
}

.courseDetailsSectionContainer {
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  /* height: 90%; */
  cursor: pointer;
}

.dragDropText {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px;
}

.dragDropRequirement {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
}

.noBatchTimingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80%;
}

.noDetailsText {
  color: #8a94a6;
  font-size: 12px;
  font-weight: 500;
}

.noSchedulesText {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  width: 120px;
  text-align: center;
}

.tagsSection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 321px;
  padding: 20px;
}

.tagsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.courseEditDetailsSection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 65%;
  padding: 20px;
}

.courseCertificateContainer {
  display: flex;
  align-items: center;
  background-color: rgba(247, 247, 251, 0.48);
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.certificateBody {
  color: #667085;
  font-size: 13px;
}

.categorySection {
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
}

.courseLevelSection {
  margin: 10px 0px;
}
.category {
  width: 50%;
  margin-right: 20px;
}

@media (min-width: 50px) and (max-width: 1200px) {
  .courseDetailsFirstRow {
    display: block;
  }
  .courseEditDetailsSection {
    width: 100%;
  }
  .courseDetailsSection {
    width: 100%;
  }
  .courseDetailsImageSection {
    /* width: 100%; */
    margin-top: 20px;
  }
}
.moreDetailsBtn {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
}
.paymentTypeRegularWrap {
  display: flex;
  align-items: center;
  gap: 100px;
  border-radius: 6px;
  border: 1px solid #f0f0f5;
  background: #fafafc;
  padding: 16px 22px;
}

.emiEnableBtn {
  border: none;
  border-radius: 6px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding: 10px 14px;
  max-height: 38px;
}

.suggestedTags {
  font-size: 13px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #142c44;
}

.noCourseDescriptionText {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  width: 250px;
  text-align: center;
}
