.taskStatusBtn {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  border-radius: 16px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}
.taskStatusBtnLabel {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  font-family: "poppins";
}
.taskStatusPopupWrap {
  position: relative;
}
.taskStatusPopupWrapper {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 166px;
  padding: 12px;
  list-style: none;
  z-index: 99;
  overflow-y: auto;
  box-shadow: 0px 12px 24px 0px rgba(52, 87, 140, 0.12);
}
.taskStatusPopupItem {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-bottom: 10px;
}
.taskStatusPopupItem:hover {
  color: #11c89b;
}
.taskStatusPopupItemTick {
  display: flex;
  align-items: center;
}
