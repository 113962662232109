.additionalInfoTabWrap {
  max-height: calc(98vh - 224px);
  height: 100%;
  overflow-y: auto;
  background-color: white;
  border-radius: 3px;
  padding: 26px;
}
.additionalInfoTitle {
  font-size: 15px;
  font-weight: 500;
  color: #142c44;
  margin-bottom: 30px;
}
.additionalInfoTableHead {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  color: #667085;
  margin-bottom: 20px;
}
.additionalInfoTableRow {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  font-size: 14px;
  color: #142c44;
  padding: 10px 0;
  font-weight: 500;
}
.additionalInfoTableHeadLeft {
  width: 200px;
}
.additionalInfoTableRowLeft {
  width: 200px;
  text-transform: capitalize;
}
.checkboxList {
  padding-left: 20px;
  margin: 0;
}
