.paymenyBodyRowItem {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.oneTimeText {
  padding: 24px 30px;
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  text-transform: capitalize;
  background: #fff;
  border-bottom: 1px solid #efefef;
}
.leadPayHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 18px 0;
}
.leadPayHeadLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}
.leadPayHeadTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.leadPayHeadType {
  border-radius: 4px;
  background: rgba(5, 112, 219, 0.08);
  padding: 3px 11px;
  color: #0570db;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.noBatch {
  color: #ec3939;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
}
