.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 10px;
}
.modalDes {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 8px;
  margin-bottom: 21px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.buttonSection {
  display: flex;
  justify-content: center;
}
.noButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  margin-right: 12px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #344054;
  cursor: pointer;
}
.yesButton {
  background: #ec3939;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.modalTitleForViewLead {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 10px;
  margin: 10px 0px;
}
.modalViewImage {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}
.modalViewImage img {
  max-width: 100%;
  width: 100%;
  border-radius: 50%;
}
