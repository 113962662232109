.container {
  width: 95%;
  margin: auto;
  background: white;
  padding: 0px 20px;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-height: 260px;
  overflow-y: scroll;
}

h2 {
  text-align: center;
  color: #333;
}

.tableContainer {
  overflow-x: auto;
}

.tableOfMerchant {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
}
.tableOfMerchantHeader {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
/*
th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background: #e8f5e9;
  font-weight: bold;
}

td {
  color: #555;
} */

.bankName {
  font-weight: bold;
  color: #333;
}

.idBox {
  background: #ffe0b2;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: bold;
  color: #d35400;
  display: inline-block;
}

.accountNumber {
  background: rgb(250, 254, 253);
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: bold;
  color: #00796b;
  display: inline-block;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.editBtn,
.deleteBtn {
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.editBtn {
  /* background: #fdd835; */
  /* color: #333; */
}

.deleteBtn {
  /* background: #f44336; */
  /* color: white; */
}

.deleteBtn:hover {
  /* background: #d32f2f; */
}

.merchantInfoHeading {
  text-align: start;
  color: #11c89b;
  font-size: 20px;
  font-weight: 500;
  width: 95%;
  margin: auto;
  background: white;
  padding: 5px 10px 5px 20px;
}

.merchantAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  font-size: 18px;
  font-weight: 500;
  background: white;
  margin-left: 50px;
}
