.receiptHead {
  color: #142c44;
  text-align: right;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.instituteName {
  color: #142c44;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.instituteAddress {
  color: #142c44;
  font-size: 8px;
  font-weight: 400;
  text-transform: capitalize;
  max-width: 160px;
  line-height: 12px;
}
.receiptDetails {
  color: #667085;
  font-size: 11px;
  font-weight: 400;
  text-transform: capitalize;
}
.amountDetails {
  border-radius: 2px;
  background: rgba(20, 44, 68, 0.04);
  padding: 15px 12px;
  margin-top: 10px;
  min-width: 170px;
}
.receivedAmount {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
.courseFee {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
.balanceDue {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.balance {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
.amountDetailsWrapper {
  display: flex;
  justify-content: end;
}

.receiptDetailsLabel {
  color: #142c44;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
  width: 80px;
  text-align: left;
}
.receiptDetailsValue {
  color: #142c44;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
}
.instituteLogo {
  max-width: 100px;
  overflow: hidden;
}
.receivedAmountLabel {
  color: #142c44;
  text-align: right;
  font-size: 8px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 8px;
  flex: 1;
}
.receivedAmountValue {
  color: #142c44;
  text-align: right;
  font-family: "Roboto";
  font-size: 8px;
  font-weight: 600;
  text-transform: capitalize;
  min-width: 50px;
  margin-bottom: 8px;
}
.courseFeeLabel {
  color: #142c44;
  text-align: right;
  font-size: 8px;
  font-weight: 400;
  text-transform: capitalize;
  flex: 1;
}
.courseFeeValue {
  color: #142c44;
  text-align: right;
  font-size: 8px;
  font-weight: 400;
  text-transform: capitalize;
  min-width: 50px;
  font-family: "Roboto";
}
