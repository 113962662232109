html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100%;
}
#root {
  height: 100%;
}

@media (min-width: 50px) and (max-width: 1024px) {
  body {
    overflow-y: scroll;
  }
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.tox {
  z-index: 999999999 !important;
}

.tox-statusbar__branding {
  display: none;
}

.tox {
  z-index: 99999999 !important;
}

.staticDatePicker .css-1im1siy {
  display: none !important;
  background-color: red;
}

.staticDatePicker .css-hlj6pa-MuiDialogActions-root {
  display: none !important;
}

.css-g2t9mz-MuiFormControl-root-MuiTextField-root {
  display: none !important;
}

/* .css-xelq0e-MuiPickerStaticWrapper-content {
  min-width: 310px !important;
} */

.currant_batch_staticDatePicker
  .css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
  min-height: 200px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background-color: #23a67b;
  color: #fff;
  border-radius: 4px;
  border: none !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  border: 1px solid #000 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  border-radius: 4px !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0px !important;
}

/* .css-i4bv87-MuiSvgIcon-root{
  color: #FFF;
} */

/* BORDERRADIUS FOR TEXTFIELD */
.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.tox {
  z-index: 555 !important;
}

::-webkit-scrollbar {
  height: 4px;
  width: 5px;
  /* background: #eceef0; */
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(138, 148, 166, 0.5);
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .imageDrop:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px -1px,
    rgba(0, 0, 0, 0.14) 0px 15px 25px 0px, rgba(0, 0, -1, 0.12) 0px 8px 10px 0px;
} */

/* .imageDrop {
  width: 301px;
  height: 197px;
  border-radius: 6px;
} */
.datepicker-container .MuiDialogActions-root.MuiDialogActions-spacing {
  display: none !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  display: block !important;
}
.round-cropper .cropper-crop-box,
.round-cropper .cropper-view-box {
  border-radius: 50% !important;
}
.square-cropper .cropper-crop-box,
.square-cropper .cropper-view-box {
  border-radius: 0 !important;
}
.MuiPickersPopper-root {
  z-index: 9999999 !important;
}
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 22px;
}
.filteredDiv {
  padding: 6px 8px;
  background: #f3f3f3;
  border-radius: 4px;
  color: #667085;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: inline-block;
  margin: 3px 8px 3px 0px;
  text-transform: capitalize;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
#jsd-widget {
  left: 0;
}
.CancelBtn {
  color: #bfbfbf;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  padding: 11px 26px;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
  text-transform: capitalize;
}
.SaveBtn {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  padding: 11px 26px;
  border-radius: 4px;
  background: #11c89b;
  text-transform: capitalize;
  border: 1px solid #11c89b;
}
.react-datepicker {
  font-family: "poppins", sans-serif !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #142c44 !important;
  border: none !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  width: 100% !important;
}
.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  background-color: #11c89b !important;
  color: #fff !important;
}
.react-datepicker__day--in-selecting-range {
  background-color: transparent !important;
  color: #142c44 !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  /* background-color: #ddf0ee !important; */
  border-radius: 50% !important;
  color: #142c44 !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  /* background-color: #ddf0ee !important; */
  border-radius: 50% !important;
  color: #142c44 !important;
  background-color: #11c89b !important;
}
.react-datepicker__day--selected {
  background-color: #11c89b !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.2rem !important;
  line-height: 2.2rem !important;
  font-size: 0.75rem !important;
  /* color: #142c44 !important; */
}
.react-datepicker__day--today {
  color: #142c44 !important;
  border: 1px solid #142c44 !important;
  font-weight: 400 !important;
  border-radius: 50% !important;
}
.react-datepicker__day.react-datepicker__day--today {
  border: none !important;
  color: #142c44 !important;
}
.react-datepicker__day.react-datepicker__day--selected {
  border: none !important;
  color: #fff !important;
}
.react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--today {
  border: none !important;
  color: #fff !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #142c44 !important;
  font-weight: 500 !important;
}
.react-datepicker__day--range-end.react-datepicker__day--keyboard-selected {
  color: #fff !important;
  background-color: #11c89b !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
  background-color: transparent !important;
  color: #142c44 !important;
}
.dashboardBatchCarousel .carousel-status {
  display: none;
}
.dashboardBatchCarousel .carousel .thumbs-wrapper {
  display: none;
}
