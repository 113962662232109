.whatsappContainer {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 40px;
  font-family: "Arial", sans-serif;
  border-radius: 10px;
  height: 100%;
}
.registerSection {
  margin-top: 30px;
}
.gupshup-container {
  display: flex;
  overflow-y: scroll;
}

.left-panel,
.right-panel {
  padding: 20px;
  width: 50%;
}

.back-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.logo-section {
  margin: 20px 0;
}

.logo-section img {
  width: 100px;
}

.logo-section h2 {
  font-size: 24px;
}

.input-section label {
  display: block;
  margin: 20px 0;
}

.input-section input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.continue-button {
  display: block;
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.right-panel h2 {
  font-size: 24px;
}

.step {
  margin: 20px 0;
}
.input-section .input-label {
  display: block;
  margin: 20px 0;
  font-weight: bold;
}

.input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  position: relative;
}

.input-wrapper input {
  flex: 1;
  padding: 10px;
  margin: 10px 0;
  border: none;
  background-color: #f2f2f2;
}

.copy-button {
  padding: 10px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.info-icon {
  display: inline-block;
  margin-left: 10px;
  background-color: grey;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.gupshupContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
}

.leftPanelHeader {
  display: flex;
  cursor: pointer;
}

.logoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.inputSection {
  display: flex;
  flex-direction: column;
}

.inputSectionFileds {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputSubmitButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.integratedGupshupContainer {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 30px;
}

.integratedHeader {
  color: #667085;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.integratedGupshupModal {
  border: 1px solid #e3e3e3;
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.gupShupConfigDetails{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.askAQuestionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editFormPopupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #101828;
  margin-bottom: 8px;
  font-family: "Poppins";
}
.editFormPopupContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 22px;
  font-family: "Poppins";
}

.editFormPopupDesc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #69696b;
  margin-bottom: 40px;
}

.editFormPopupBtnsSplit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.editFormPopupCancelBtn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  padding: 10px 18px;
  width: 100%;
  color: #344054;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "Poppins";
}
.editFormPopupRemoveBtn {
  background: #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  padding: 10px 18px;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #d92d20;
  font-family: "Poppins";
}