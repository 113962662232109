.PaymentLinkPopupHead {
  color: #142c44;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.PaymentLinkPopupHeadWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #667085;
}
.linkBtnSplit {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  padding-top: 15px;
}
.linkBtnSplit button {
  flex-basis: 100%;
}
.buttonSection {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.noButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  margin-right: 12px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  cursor: pointer;

  color: #9a9ea6;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.yesButton {
  background: #11c89b;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.PaymentLinkPopupHead {
  color: #142c44;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.PaymentLinkPopupBodyWrap {
  border-radius: 4px;
  background: #f8f8f8;
  padding: 12px 14px;
  margin-bottom: 14px;
}
.PaymentLinkPopupBodyHead {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 6px;
}
.PaymentLinkPopupBodyItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 6px;
}
.PaymentLinkPopupBodyItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
}
.PaymentLinkPopupBodyItemValue {
  color: #142c44;
  text-align: right;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.PaymentLinkPopupBodyItemDueLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.PaymentLinkPopupBodyItemDueValue {
  color: #142c44;
  text-align: right;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 25px; /* 178.571% */
}
