.funnelHeadSplit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;

}
.funnelHeadSplitTitle {
    color: #142c44;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.completedTaskPageBackBtn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    color: #667085;
    padding-left: 0;
    cursor: pointer;
}


