.commentItemWrap {
  display: flex;
  width: 100%;
  align-items: center;
}
.commentItemBorder {
  background: rgba(102, 112, 133, 0.14);
  width: 1px;
  height: 100%;
  /* margin-right: 18px; */
}
.commentItemCreatorDetails {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}
.commentItemRight {
  position: relative;
  /* padding-left: 28px; */
  flex: 1;
  padding-bottom: 19px;
  margin-left: 28px;
}
.commentItemWrap:last-child .commentItemRight {
  padding-bottom: 0;
}
.commentItemRight::before {
  content: "";
  background: rgba(102, 112, 133, 0.14);
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  /* left: 10px; */
  top: 0;
  left: -18px;
}
.commentItemCreatorAvatar {
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commentItemCreatorName {
  color: #323b4b;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.commentItemCreatorDate {
  color: #667085;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}
.commentItemDesc {
  color: #323b4b;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
