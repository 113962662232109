/* .recordPaymentForm {
  position: absolute;
  left: 50%;
  right: 0;
  top: 16%;
  background: #fff;
  border-radius: 12px;
  width: 408px;
  padding: 20px;
  z-index: 99999;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);  
} */
.recordPayWrap::-webkit-scrollbar {
  width: 6px;
}
.recordPayWrap::-webkit-scrollbar-track {
  background: #fff;
}
.recordPayWrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(138, 148, 166, 0.5);
}

.buttonSave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 18px 28px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
}
.buttonSave button {
  flex-basis: 100%;
}
.cross_button {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-right: -20px;
}

.formHeading {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 6px;
}

.recordPaymentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 26px;
  background: #fff;
  border-radius: 3px;
  margin-bottom: 8px;
}
.recordPaymentBoxLeft {
  display: flex;
  min-width: 65%;
  max-width: 65%;
}
.recordPaymentBoxRight {
  flex: 1;
}
.recordPaymentBoxLeftItem {
  padding-right: 28px;
  border-right: 1px solid rgba(102, 112, 133, 0.14);
  margin-right: 30px;
  flex-basis: 100%;
}
.recordPaymentBoxLeftItem:last-child {
  border-right: none;
  margin-right: 0;
}
.recordPaymentBoxLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.recordPaymentBoxValue {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  font-family: "Roboto";
}
.paymentCompleteStatusWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
.paymentCompleteStatusText {
  color: #11c89b;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
}
.paymentCompleteStatusIcon {
  display: flex;
  overflow: hidden;
}
.paymentBtnSplit > div {
  flex-basis: 100%;
  /* flex: 1; */
}

@media only screen and (min-width: 1025px) and (max-width: 1180px) {
  .recordPaymentBox {
    display: block;
  }
  .recordPaymentBoxRight {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .recordPaymentBoxLeft {
    max-width: 75%;
  }
}
