.label {
  color: #142c44;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: none;
}
.customizedFieldWrapper {
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 22px;
  max-width: 490px;
  margin-bottom: 16px;
}
.customizedCourseWrapper {
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: #fff;
  max-width: 490px;
  margin-top: 16px;
}
.customizedFieldTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 8px;
}
.customizedFieldTopRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.btnsWrap {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 20px;
}
.description {
  color: #667085;
  font-size: 12px;
  margin-bottom: 6px;
}
