.BackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.emsilSyncBox {
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 30px 28px;
  max-width: 384px;
  margin: 7% auto 0 auto;
}
.emsilSyncBoxHead {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.emsilSyncBoxDesc {
  color: #667085;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}
.emsilSyncBoxRadioHead {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.emsilConsentBoxHead{
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}