.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.PageBackBtnWrap {
  padding: 10px 20px 20px 0px;
}

.batchDetailsContainer {
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.batchDetailsHeader {
  background-color: #f7f7fb80;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 20px;
}

.batchNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.batchName {
  color: #142c44;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.batchCity {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.batchInfoHeader {
  font-size: 13px;
  font-weight: 400;
  color: #667085;
  margin: 5px 0px;
}

.courseName {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.batchDetailsSection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
}
.studentSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.studentHeaderTitle {
  font-size: 14px;
  color: #494949;
  font-weight: 500;
}

.studentSectionBody {
  height: 350px;
  overflow-y: scroll;
  padding: 10px;
}

.studentData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #f2f2f2;
  padding: 10px 5px;
  margin-bottom: 5px;
}

.nameView {
  background-color: #667085;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.nameText {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.emails {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
}
.studentName {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
}

.studentSectionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 20;
  margin: 20px;
}

.presentTab {
  background-color: rgba(17, 200, 155, 0.12);
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  border-radius: 4px;
}
.absentTab {
  background-color: rgba(236, 57, 57, 0.12);
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
  border-radius: 4px;
}
