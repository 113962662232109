.emiDetails,
.recurringDetails {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* border-radius: 3px; */
  /* border: 1px solid rgba(227, 227, 227, 0.7); */
  /* background: rgba(245, 245, 247, 0.5); */
  /* padding: 8px 20px; */
  /* margin-top: 14px; */
  min-height: 42px;
  gap: 10px;
}
/* .recurringDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: 1px solid rgba(227, 227, 227, 0.7);
  background: rgba(245, 245, 247, 0.5);
  padding: 8px 20px;
  margin-top: 14px;
  min-height: 42px;
  gap: 10px;
} */
.emiRowDetails,
.recurringRowDetails {
  color: #667085;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
/* .recurringRowDetails {
  color: #667085;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
} */
.emiDetailsChild,
.recurringDetailsChild {
  border-radius: 8px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: #fff;
  padding: 12px 16px;
  flex: 1;
  margin-bottom: 14px;
}
.emiDetailsChildTop,
.recurringDetailsChildTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emiDetailsChildLeftTop,
.recurringDetailsChildLeftTop {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-bottom: 2px;
}
.emiSubaymentsWrapper {
  padding: 16px 0;
  border-top: 1px solid #6670851f;
}
.emiSubaymentsWrap {
  display: flex;
  gap: 9px;
}
.emiSubaymentsRow {
  display: flex;
  align-items: center;
  gap: 42px;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 8px 16px;
  margin-bottom: 10px;
  flex: 1;
}
.emiSubaymentsRowItem {
  display: flex;
  align-items: center;
  gap: 10px;
}
.emiSubaymentsLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.emiSubaymentsValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}
.viewBreakDownBtn {
  color: #667085;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.balanceTag {
  font-size: 12px;
  color: #667085;
}

.basicRegularWrap {
  border-radius: 8px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: #fff;
  padding: 12px 16px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.basicRegularRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.basicRegularAmount {
  color: #142c44;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
}
