.searchPopupField::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #bdbfc3;
  padding: 0;
}
.searchPopupWrapper {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(64, 64, 64, 0.18);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  cursor: auto;
}
.searchPopupWrap {
  background: #fff;
  width: 900px;
  min-width: 500px;
  height: 586px;
  padding: 22px 22px 0 22px;
}
.searchPopupWrapChild {
  max-height: calc(100% - 78px);
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  overflow-y: auto;
}
.searchPopupCategoryWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 31%;
  height: auto;
  max-width: 31%;
}
.searchPopupBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.searchPopupBtn {
  border: none;
  background: none;
  cursor: pointer;
}
.searchPopupCategoryName {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #142c44;
  margin-top: 0;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.searchPopupCourseName {
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  line-height: 28px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  text-transform: capitalize;
  padding-left: 10px;
}
.searchPopupCourseName:hover {
  color: #11c89b;
}
.searchPopupCourseNameSelected {
  font-weight: 400;
  font-size: 13px;
  color: #11c89b;
  line-height: 28px;
  cursor: pointer;
  text-transform: capitalize;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  text-transform: capitalize;
  padding-left: 10px;
}
.searchPopupCourseName:hover {
  color: #11c89b;
}
.searchListCategoryName {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #142c44;
  margin-top: 0;
  margin-bottom: 2px;
  padding: 0 7px;
  text-transform: capitalize;
}
.searchListCourseName {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
  padding: 6px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  text-transform: capitalize;
}
.searchListCourseName:hover {
  background: #11c89b;
  border-radius: 1px;
  color: #ffffff;
}
.searchListCourseNameSelected {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  padding: 6px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  background: #11c89b;
  width: 100%;
  text-align: left;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  text-transform: capitalize;
}
.searchListCourseNameSelected:hover {
  background: #11c89b;
  border-radius: 1px;
  color: #ffffff;
}
.searchListWrap {
  padding: 0px 5px 5px 5px;
  overflow-y: auto;
  height: calc(100% - 120px);
  overflow-x: hidden;
}
.searchListCourseLeadCount {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #11c89b;
}
.searchListCourseName:hover .searchListCourseLeadCount {
  color: #fff;
}
@media screen and (max-width: 780px) {
  .searchPopupWrap {
    width: 85%;
    min-width: 85%;
    max-width: 85%;
  }
}
