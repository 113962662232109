.courseDrawerBtn {
  position: absolute;
  top: 21px;
  right: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  z-index: 9999;
}

.courseDrawerBtn:hover {
  position: absolute;
  top: 21px;
  right: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background: #11c89b;
  transition: 0.2s;
}

.batchDrawerBtn {
  position: absolute;
  top: 21px;
  left: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  padding: 0;
  z-index: 99;
}

.batchDrawerBtn:hover {
  position: absolute;
  top: 21px;
  left: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  background: #11c89b;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  padding: 0;
  z-index: 99;
}

.kanbanBoard {
  display: flex;
  /* flex-wrap: wrap; */
  /* padding: 32px; */
  background-color: #f4f5f7;
  /* overflow-x: auto; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}
.kanbanBoardLeadsWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f5f7;
}

.kanbanColumn {
  flex: 1;
  margin: 0 6px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
.kanbanColumn:first-child {
  margin-left: 0;
}
.kanbanColumnLeads:first-child {
  margin-left: 0;
}
.kanbanColumn:last-child {
  /* margin-right: 0; */
}
.kanbanColumnLeads:last-child .LeadCardDropdownListWrap {
  left: unset;
  right: 0;
}
.kanbanColumnLeads {
  flex: 1;
  margin: 0 6px;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  display: flex;
  flex-direction: column;
  min-width: 250px;
  position: relative;
}
.kanbanColumnHead {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
  background: rgba(17, 200, 155, 0.1);
  border: 1px solid rgba(17, 200, 155, 0.1);
  border-radius: 4px;
}
.kanbanColumnHeadLeft {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.kanbanColumnHeadTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #142c44;
  text-transform: capitalize;
}
.kanbanColumnLeadAddBtn {
  background: #1877f2;
  border-radius: 6px;
  width: 31px;
  height: 24px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}
.kanbanColumnHeadCount {
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #12bd93;
}
.kanbanColumn-tasks {
  flex: 1;
  margin: 8px 0;
}

.kanbanTask {
  background-color: #fff;
  border-radius: 3px;
  /* box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25); */
  margin: 8px 0;
  transition: box-shadow 0.1s ease-in-out;
  border: 1px solid #f0f0f0;
}
.kanbanTaskBox {
  padding: 12px 12px 14px 12px;
}
.kanbanTask:hover {
  /* box-shadow: 0 2px 4px rgba(9, 30, 66, 0.25); */
  /* border: 1px solid #dedede; */
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
}

.kanbanTaskHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  gap: 10px;
  align-items: flex-start;
}
.kanbanLeadSource {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #667085;
  text-transform: capitalize;
  margin-bottom: 14px;
}
.kanbanLeadName {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #142c44;
  text-transform: capitalize;
  max-width: 240px;
  word-break: break-word;
}
.smallLeadCardName {
  cursor: pointer;
}

.smallLeadCardName:hover {
  color: #11c89b;
  transition: 0.3s;
}

.kanbanTaskId {
  font-size: 14px;
  font-weight: 600;
}

.kanbanTaskPriority {
  font-size: 12px;
  font-weight: 500;
  color: #0052cc;
}

.kanbanTaskSummary {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 8px;
}

.kanbanTaskFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #4b4f56;
  border-top: 1px solid #dfe1e6;
  padding-top: 8px;
}

.kanbanTaskAssignee {
  margin-right: 8px;
}

.kanbanTaskStatus {
  font-weight: 600;
}
.kanbanAssign {
  display: flex;
  gap: 10px;
  align-items: center;
}
.columnDraggingOver {
  background: #0052cc;
}
.instituteContact_save_btn {
  text-transform: capitalize;
}
/* Filterform */

.popupform {
  box-sizing: border-box;
  position: absolute;
  min-width: 280px;
  right: 0;
  background: #ffffff;
  z-index: 8;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 12px 10px;
}
.popupformWrap {
  box-sizing: border-box;
  min-width: 270px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 12px 10px;
}

.cross_button {
  display: flex;
  flex-direction: row-reverse;
}
.filterPopup {
  position: relative;
}
.addFieldSplitWrap > * {
  flex-basis: 100%;
}
.inputField {
  background: #f6fafd;
  /* Grey/Grey 30 */

  border: 1px solid #eaeef4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  color: #7e92a2;
  box-sizing: border-box;
}

.inputField::placeholder {
  color: #7e92a2;
}

.formHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
}
.addLeadMainHeading {
  color: #142c44;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* padding: 12px 32px; */
  border-bottom: 1px solid #f4f4f4;
}
.filterCloeBtn {
  top: 10px;
  /* right: 415px; */
  z-index: 999;
  position: fixed;
  width: 27px;
  height: 27px;
  border: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.blueBtn {
  color: #2664dc;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: underline;
  border: none;
  background-color: #fff;
  padding: 0;
  cursor: pointer;
}
.blueBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.subTitle {
  font-family: "Poppins";
  font-style: normal;
  color: #142c44;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}
/* Filterform */

.popupform {
  box-sizing: border-box;
}
.button_reset,
.button_apply {
  text-transform: capitalize;
  color: #3f3e43;
  background: #ffffff;
  padding: 8px 40px;
}
.button_reset:hover,
.button_apply:hover {
  color: #ffffff;
  background: #1877f2;
}
.button_reset:active,
.button_apply:active {
  color: #ffffff;
  background: #1877f2;
}
.cross_button {
  display: flex;
  flex-direction: row-reverse;
}
.kanbanUpcomingTaskWrap {
  display: flex;
  /* align-items: flex-start; */
  gap: 5px;
  padding: 0 12px;
  margin-bottom: 14px;
  align-items: center;
}
.kanbanUpcomingTaskIcon {
  display: flex;
  margin-top: 2px;
}
.kanbanUpcomingTaskIcon svg {
  font-size: 15px;
}
.kanbanUpcomingTaskDetailChild {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  flex-wrap: wrap;
  margin-top: 2px;
}
/* Add Enquiry Modal ========= */

.selectedbatch {
  width: 100%;
  padding: 5px;
  height: 92px;
  margin-top: 10px;
  background-color: #c9eee2;
  padding: 8px 12px;
  border: 2px solid rgba(35, 166, 123, 0.06);
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(52, 87, 140, 0.12);
}

.unSelectedbatch {
  width: 100%;
  padding: 5px;
  height: 92px;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 8px 12px;
  box-shadow: 0px 0px 12px rgba(52, 87, 140, 0.12);
  border-radius: 3.83032px;
}
.dragoverColumn {
  margin: 0 6px;
  min-width: 250px;
}

.dropZoneBox {
  height: 100%;
  margin-top: 8px;
  background: #e9f2ff;
  border: 2px dashed #1e7afd;
  border-radius: 3px;
}
.dragoverColumn .dropZoneBox {
  border: 2px dashed #11c89b;
  background: #e0fcf0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.dropableBatchItem.dragoverColumn {
  margin: unset;
  min-width: unset !important;
  width: 100%;
  margin-top: 8px;
}
.batchPopupHead {
  color: #667085;
  background-color: #e2f0ec;
  border-bottom: 6px solid #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
}
.listTableContainer > div {
  position: relative !important;
}
.batchPopupHeadCell {
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  color: #142c44 !important;
  background-color: unset !important;
}
.batchPopupColumnCellIndex {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}
.batchPopupColumnCellName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.batchPopupColumnCellDefault {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}
.batchPopupColumnCellPayment {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
.mainWrap {
  max-height: 540px;
  overflow-y: auto;
  min-height: 440px;
}
.batchPopupTableHead {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #142c44;
  margin-bottom: 12px;
}
.batchPopupTopSplit {
  display: flex;
  gap: 30px;
  margin-bottom: 28px;
}
.batchPopupTopImg {
  width: 320px;
  overflow: hidden;
  border-radius: 6px;
  aspect-ratio: 16 / 9;
}
.batchPopupTopImg img {
  max-width: 100%;
  width: 100%;
  border-radius: 6px;
  aspect-ratio: 16 / 9;
}
.batchPopupCourseName {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #142c44;
  margin-bottom: 17px;
  text-transform: capitalize;
}
.batchPopupTopSplitRight {
  flex: 1;
}
.batchPopupDetailsBox {
  background: #ffffff;
  border: 1px solid #ebebed;
  border-radius: 8px;
  width: 100%;
}
.batchPopupDetailsBoxHead {
  background: #f6f6f6;
  padding: 8px 14px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.batchPopupDetailsBoxBatchName {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #494949;
}
.batchPopupDetailsBoxBranchName {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #667085;
}
.batchPopupDetailsBoxBottom {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.batchPopupDetailsBoxLeft {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #667085;
  display: flex;
  flex-direction: column;
}
.batchPopupDetailsBoxBottom {
  padding: 8px 14px 16px 14px;
}

.batchPopupScheduleTooltip {
  position: relative;
}
.batchPopupScheduleTooltipWrap {
  position: absolute;
  top: 25px;
  left: 0;
  display: none;
  transition: 0.5s ease-in-out;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.04),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  width: 232px;
  padding: 14px 14px;
  z-index: 9;
}
.batchPopupScheduleTooltipWrapList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.batchPopupScheduleTooltip:hover .batchPopupScheduleTooltipWrap {
  display: block;
}
.batchPopupScheduleTooltipWrapListItem {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #667085;
  text-align: left;
  gap: 9px;
  margin-bottom: 9px;
}
.batchPopupScheduleTooltipHead {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #142c44;
  margin-bottom: 8px;
}
.batchPopupScheduleTooltipLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #10b089;
  cursor: pointer;
}
.batchPopupScheduleTooltipWrapListItemDay {
  font-weight: 400;
  font-size: 14px;
  color: #3f3e43;
  text-transform: capitalize;
  min-width: 35px;
}
.batchPopupScheduleTooltipWrapListItemDay {
  font-weight: 400;
  font-size: 14px;
  color: #3f3e43;
  text-transform: capitalize;
}
.batchPopupDetailsBoxPrice {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #142c44;
  font-family: "Roboto";
}
.batchMembershipItemPer {
  color: #8a94a6;
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.batchPopupDetailsBoxDates {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.convertedStatus {
  width: 30px;
  height: 30px;
  background: #f5f5f7;
  border: 1px solid #eeeef4;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.convertedStatusTooltip {
  background: #101828;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  bottom: -68px;
  z-index: 999;
  min-width: 170px;
  letter-spacing: 0.1px;
  max-width: 170px;
  transition: 0.3s ease-in-out;
  display: none;
}
.tooltipArrow {
  position: absolute;
  bottom: -17px;
  left: 52%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #101828;
  transition: 0.3s ease-in-out;
  display: none;
}
.convertedStatus:hover .convertedStatusTooltip,
.convertedStatus:hover .tooltipArrow {
  display: block;
}
.batchPopupTableHeadWrap {
  max-width: 100%;
}
.additionalFieldWrap {
  margin-bottom: 14px;
}
.additionalFieldLabel {
  font-size: 12px;
  color: rgb(20, 44, 68);
  font-weight: 500;
  display: flex;
  margin: 0px 0px 5px;
  text-transform: capitalize;
}
@media screen and (max-width: 780px) {
  .batchPopupTopSplit {
    flex-direction: column;
  }
}

/* Nurtue List View */

.agentCardImg {
  width: 28px;
  height: 28px;
}
.agentAvatar {
  aspect-ratio: 1;
  text-transform: capitalize;
}
.agentCardImg_Name {
  width: 35px;
  height: 35px;
}

/* Tab View */

@media only screen and (max-width: 1024px) {
  .kanbanColumnHeadTitle {
    font-size: 12px;
  }

  .smallLeadCardName {
    font-size: 12px;
  }
  .kanbanLeadSource {
    font-size: 11px;
  }
}
