.btnBottomSplit {
  padding: 18px 28px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.btnBottomSplit button {
  flex-basis: 100%;
}
.clsBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.clsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}
.formHeading {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.formWrap {
  padding: 14px 14px 0 0;
}
.fieldLabel {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 7px;
}
