.popupHead {   
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;    
    text-transform: capitalize;
    color: #101828;
}

.popupPara {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;    
    color: #EC3939;
}
   
.popupButtonDiv {
    text-align: center;  
    display: flex;
    gap: 10px
}


