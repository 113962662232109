.dashboard_main {
  padding: 20px 20px;
  overflow: auto;
  background: #fafafa;
  position: relative;
}

.card_count {
  color: #142c44;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  margin: 6px 0px;
}

.card_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  margin: 0px;
  color: #667b8b;
}

.card_view {
  background: #ffffff;
  border: 1px solid #e6edf0;
  border-radius: 6px;
}
.dashboardHeadWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: #fff;
  padding: 10px 16px;
}
.dashboardHeadTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}
.dashboardHeadRight {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboardHeadBranchFielLabel {
  color: #7d8592;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.salesPipelineBox {
  border-radius: 8px;
  background: #fff;
  padding: 28px 24px;
}
.salesPipelineTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 20px;
}
.salesPipelineTotalBox {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 18px;
}
.salesPipelineTotalBoxItemValue {
  color: #242424;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.salesPipelineTotalBoxItemLabel {
  color: #7d8592;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.salesPipelineStripBarWrap {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1%;
  margin-bottom: 25px;
}
.salesPipelineStripBarItemBlue {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #697be9 0 0.25vw,
    #95a1fc 0.25vw 0.5vw
  );
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.salesPipelineStripBarItemPink {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #fa898b 0 0.25vw,
    #fcb8b9 0.25vw 0.5vw
  );
}
.salesPipelineStripBarItemYellow {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #f8bb6c 0 0.25vw,
    #f6cf7d 0.25vw 0.5vw
  );
}
.salesPipelineStripBarItemGreen {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #40e4bc 0 0.25vw,
    #a0f2de 0.25vw 0.5vw
  );
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.salesPipelineStagesDataWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.salesPipelineStagesDataItem {
  width: calc(50% - 6px);
  border-radius: 8px;
  border: 1px solid #efefef;
  background: #fff;
  padding: 24px 24px;
}
.salesPipelineStagesDataItemStage {
  display: flex;
  align-items: center;
  gap: 6px;
}
.salesPipelineStagesDataItemStageName {
  color: #7d8592;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
}
.salesPipelineStagesDataBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.salesPipelineStagesDataBottomCount {
  color: #242424;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1px;
}
.salesPipelineStagesDataBottomPrice {
  color: #667085;
  text-align: right;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1px;
}
.salesPipelineStagesDataBottomLabel {
  color: #8a94a6;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}
.dataTilesBox {
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  padding: 12px 12px;
}

.totalBatchTopWrap {
  background: #f8f8f8;
  text-align: left;
  padding: 28px 18px;
  border-radius: 4px;
}
.totalBatchTopCount {
  color: #142c44;
  font-size: 36px;
  font-weight: 600;
  line-height: 58px;
  margin-bottom: 7px;
}
.totalBatchTopLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 16px;
}
.totalBatchTopStatusWrap {
  display: flex;
  align-items: center;
  gap: 6px;
}
.totalBatchTopStatus {
  border-radius: 6px;
  background: rgba(128, 159, 184, 0.15);
  color: #142c44;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
  max-width: 120px;
  width: fit-content;
}

.totalBatchStudentsWrap {
  border-radius: 4px;
  padding: 22px 22px;
  text-align: center;
  margin-bottom: 12px;
  border: 1px solid #eaeaea;
}
.totalBatchStudentsCount {
  color: #142c44;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.totalBatchStudentsLabel {
  color: #8a94a6;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.totalBatchAmountWrap {
  display: flex;
  gap: 12px;
}
.totalBatchAmountItem {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 27px;
  flex-basis: 100%;
}
.totalBatchAmount {
  color: #242424;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1px;
  margin-bottom: 6px;
}
.totalBatchLabel {
  color: #8a94a6;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.totalBatchBottomWrap {
  padding: 15px 0px;
}
.batchSliderTop {
  background: #f8f8f8;
  padding: 28px 28px;
  border-radius: 4px;
  text-align: left;
}
.batchSliderTopChild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.batchSliderBatchName::first-letter {
  text-transform: capitalize;
}
.batchSliderBatchName {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.batchSliderMainDetailsWrap {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 16px;
}
.batchSliderMainDetailsItem {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.batchSliderMainDetailsItem::first-letter {
  text-transform: capitalize;
}
.batchSliderStatus {
  border-radius: 6px;
  background: rgba(128, 159, 184, 0.15);
  padding: 6px 10px;
  color: #142c44;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 85px;
  text-transform: capitalize;
}
.batchSliderCourseWrap {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.batchSliderCourseLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.batchSliderCourseValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 21.22px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}
.batchSliderScheduleWrap {
  display: flex;
  gap: 16px;
}
.batchSliderScheduleItem {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  align-items: center;
}
.batchSliderScheduleItemLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.batchSliderScheduleItemValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 21.22px;
}
.batchSliderBottom {
  padding: 17px 0px 0px 0px;
}
.batchSliderItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.batchSliderItem {
  flex-basis: 100%;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}
.batchSliderTopRight {
  min-width: 90px;
}
.batchSliderViewBtn {
  color: #8a94a6;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
  cursor: pointer;
}
.batchSliderItemValue {
  color: #142c44;
  text-align: center;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
}
.batchSliderItemLabel {
  color: #8a94a6;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.batchSliderBottomWrap {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 22px;
  margin-bottom: 10px;
}
.batchSliderBottomValue {
  color: #142c44;
  text-align: center;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
}
.batchSliderBottomLabel {
  color: #8a94a6;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.dataTilesBoxChildItem {
  width: calc(50% - 7px);
  padding: 17px 20px;
  border-radius: 8px;
}
.dataTilesBoxChildItemValue {
  color: #142c44;
  font-size: 30px;
  font-weight: 600;
}
.dataTilesBoxChildItemLabel {
  color: #667b8b;
  font-size: 14px;
  font-weight: 500;
}
.dataTilesBoxChildItemPercent {
  display: flex;
  align-items: center;
  gap: 6px;
}
.dataTilesBoxChildItemPercent {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.dataTilesBoxChildItemVs {
  color: #8a94a6;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}
.revenueGraphBox {
  border-radius: 8px;
  background: #fff;
  padding: 20px 24px;
}
.revenueGraphTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 13px;
}
.revenueGraphTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.revenueGraphDataWrap {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 28px;
}
.revenueGraphDataItemValue {
  color: #242424;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
}
.revenueGraphDataItemLabelWrap {
  display: flex;
  align-items: center;
  gap: 6px;
}
.revenueGraphDataItemLabelColor {
  width: 10px;
  height: 10px;
  border-radius: 1px;
}
.revenueGraphDataItemLabel {
  color: #7d8592;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.leadSourceDataBox {
  border-radius: 8px;
  background: #fff;
  padding: 20px 24px;
}
.leadSourceDataTop {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  justify-content: space-between;
}
.leadSourceDataTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.leadSourceDataMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 24px;
}
.leadSourceDataMainCount {
  color: #242424;
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
}
.leadSourceDataMainLabel {
  color: #7d8592;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.leadSourceDataMainRight {
  display: flex;
  align-items: center;
  gap: 12px;
}
.leadSourceDataType {
  display: flex;
  align-items: center;
  gap: 4px;
}
.leadSourceDataTypeColor {
  width: 10px;
  height: 10px;
  border-radius: 1px;
}
.leadSourceDataTypeLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 175%;
}
.leadSourceDataListItem {
  margin-bottom: 30px;
}
.leadSourceDataListItemTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 8px;
}
.leadSourceDataListItemSource {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.leadSourceDataListItemCount {
  color: #142c44;
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.leadSourceDataListItemStripWrap {
  display: flex;
  align-items: center;
  gap: 1%;
  width: 100%;
  margin-bottom: 8px;
}
.leadSourceDataListItemStripGreen {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #40e4bc 0 0.25vw,
    #a0f2de 0.25vw 0.5vw
  );
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.leadSourceDataListItemStripRed {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #ec3939 0 0.25vw,
    #ef5656 0.25vw 0.5vw
  );
}
.leadSourceDataListItemStripOrange {
  height: 8px;
  background: repeating-linear-gradient(
    90deg,
    #f8bb6c 0 0.25vw,
    #f6cf7d 0.25vw 0.5vw
  );
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.leadSourceDataListItemLeadStage {
  display: flex;
  align-items: center;
  gap: 26px;
}
.leadSourceDataListItemLeadStageItem {
  color: #8a94a6;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.barChartWrapper {
  border-radius: 8px;
  background: #fff;
  padding: 26px 24px;
  overflow: hidden;
}
.barChartTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 30px;
}
.myTasksWrapper {
  border-radius: 8px;
  background: #fff;
  padding: 26px 24px;
  overflow: hidden;
}
.myTasksHeadWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}
.myTasksHeadTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.myTasksPendingTaskCount {
  color: #242424;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
}
.myTasksPendingTaskTitle {
  color: #7d8592;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.myTasksStudentName {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
}
.myTasksPendingTaskDesc {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 23.562px;
}
.myTasksPendingTaskDesc::first-letter {
  text-transform: capitalize;
}
.myTasksStatus {
  color: #72767c;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.overduePaymentsWrapper {
  border-radius: 8px;
  background: #fff;
  padding: 26px 24px;
  overflow: hidden;
}
.overduePaymentsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 28px;
}
.overduePaymentsHeadTitle {
  color: #7d8592;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.overduePaymentsTableHead {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;
  gap: 1%;
}
.overduePaymentsTableHeadItem {
  color: #72767c;
  font-size: 14px;
  font-weight: 500;
}
.overduePaymentsTableBodyRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f3;
  gap: 1%;
}
.overduePaymentsTableBodyItemName {
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
.overduePaymentsTableBodyItemContacts {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
}
.overduePaymentsTableBodyItemContacts div {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.overduePaymentsTableBodyItemCourse {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.overduePaymentsTableBodyItemCourse::first-letter {
  text-transform: capitalize;
}
.overduePaymentsTableBodyItemAmount {
  color: #667085;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.overduePaymentsTableBodyItemDate {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

/* Tab View */

@media only screen and (max-width: 1024px) {
  .yearPicker {
    max-width: 36%;
  }
}
