.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}

.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}

.navbar_list {
  padding-left: 20px;
}

.logo_box {
  position: absolute;
  left: 15%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}

.search_icon {
  float: left;
}

.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* Sub nav bar */

.flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background-color: #142c44;
  position: relative;
}

.grid_border {
  border-left: 1px solid #c7c7c7;
  padding-left: 0 !important;
}

.lineContainer {
  margin-top: 20px;
}

.subList:hover {
  background: #23a67b;
  color: #ffffff;
}

.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.googleMap {
  /* / border: 1px solid red; / */
  width: 100%;
  margin-top: 10px;
}

::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}

._Institute_imageBox {
  display: flex;
  justify-content: left;
}

._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}

._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}

.styles._image_description {
  margin-left: 50px;
}

.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}

.hader_txt {
  color: white;
}

.list_flat {
  display: flex;

  /* flex-wrap: nowrap; */
}

.list_item_flat {
  display: flex !important;
  color: black !important;
  font-size: 14px !important;
  /* border: 1px solid red !important; */
  /* justify-content: space-between; */
  align-items: center;
}

.list_item {
  padding: 0px 5px;
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}
.list_item_progress {
  padding: 0px 5px;
  background: #e7fff7;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}
.list_item_completed {
  padding: 0px 5px;
  background: #23a67b;
  color: #fff;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  text-align: center !important;
  padding-left: 15px;
}
.number_wrapperdiv {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list_font_staging {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #69696b;
  border-radius: 50%;
}
.list_font_staging_progress {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #23a67b;
  border-radius: 50%;
  color: #23a67b;
}
.list_font_staging_completed {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.list_font_progress {
  font-size: 14px !important;
  text-align: center !important;
  color: #23a67b;
}
.list_font_completed {
  font-size: 14px !important;
  text-align: center !important;
}
.link {
  text-decoration: none;
  color: black;
}
.active {
  background-color: #23a67b;
  color: #fff;
}

/* Tab View */

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .gridInboundRoot {
    border-right: none;
  }
  /* .gridInboundContainer {
    width: calc(100% + 700px);
  } */
  .gridInboundRootBottom {
    margin-left: 30px !important;
  }
  .gridSettingsLeft {
    border-right: none;
  }
  .gridSettingsRight {
    padding-left: 30px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .manageList {
    min-width: 600px;
  }

  .grid_border {
    border-left: unset;
    padding-left: 16px !important;
  }
}
