.addNote {
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
}

.cancelSave {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 10px;
}

.descriptionIcon {
  display: flex;
}
.iconImage {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 0.5px solid #c7c7c7;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}
/* .activityArea {
  margin-top: 20px;
} */
