.taskEditPopupBtnOption {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.taskEditPopupWrap {
  position: relative;
}
.taskEditPopup {
  position: absolute;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  right: 0;
  width: 110px;
  z-index: 9;
  padding: 5px 0;
}
.taskEditPopupBtn {
  border: none;
  background: #fff;
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 6px 18px;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}
