.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}
.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}
.logo_box {
  position: absolute;
  left: 9.09%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}


/* Home page  */
.profileCtagoryMainWrapper {
  border-radius: 10px;
  min-width: 350px;
  margin: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 370px;
}
.lockedProfilelist {
  padding: 15px 30px;
}

.lockedList {
  background-color: #f5f5f7;
  border-radius: 8px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.compltetYourProfileBox {
  /* background-color: #e7fff7; */
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.compleYourProfileBtn {
  font-size: 14px !important;
  padding: 11px, 24px, 11px, 24px;
  text-transform: "capitalize";
  min-width: "132px";
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.4px;
}

/*  complete profile  */

.flag {
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #1a3235;
}
.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}
.lineContainer {
  margin: 20px;
}
.subList:hover {
  background: #23a67b;
  color: #ffffff;
}
.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.institute_info_Wrapper {
  display: flex;
  justify-content: space-between;
}
.googleMap {
  width: 100%;
  margin-top: 10px;
}
.mapGrid_wrapper {
  display: flex;
  justify-content: space-between;
}
._Institute {
  display: flex;
  justify-content: space-between;
}
._Institute_imageBox {
  display: flex;
  justify-content: left;
}
._Institute_imageBoxLabel {
  margin-bottom: 15px;
}
._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}
._image_txt_logo {
  width: 15rem;
  color: #b1b1b1;
}
.styles._image_description {
  margin-left: 50px;
}
.upload_image {
  margin-top: 6rem !important;
}
.hader_txt {
  color: white;
}
.list_flat {
  display: flex;
}
.list_item_flat {
  display: flex !important;
  color: black !important;
}
.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}
.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.instituteContact_save_btn {
  width: 50% !important;
  float: right;
  margin-top: 40px !important;
  margin-bottom: 60px !important;
  text-transform: capitalize;
}
.checkBox {
  display: flex !important;
  justify-content: space-between;
  margin: 0 !important;
}
.responsive_loged_grid {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: 20px;
}
.lockedList {
  background-color: #f5f5f7;
  border-radius: 8px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.lockedList_inProgress {
  background-color: #e7fff7;
  border: 2px solid #23a67b;
  border-radius: 8px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.lockedList_completed {
  background-color: #23a67b;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 14px;
  /* height: 64px; */
}
.compltetYourProfileBox {
  /* background-color: #e7fff7; */
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.uploadImageBtnWrapper {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}
/*  complete profile  */
.flag {
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #1a3235;
}
.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}
.lineContainer {
  margin: 20px;
}
.subList:hover {
  background: #23a67b;
  color: #ffffff;
}
.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.googleMap {
  width: 100%;
  margin-top: 15px;
}
._Institute {
  display: flex;
  justify-content: space-between;
}
._Institute_imageBox {
  display: flex;
  justify-content: left;
}
._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}
._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}
.styles._image_description {
  margin-left: 50px;
}
.upload_image {
  margin-top: 6rem !important;
}
.hader_txt {
  color: white;
}
.list_flat {
  display: flex;
}
.list_item_flat {
  display: flex !important;
  color: black !important;
}
.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}

.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.lockedProfile_count {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #23a67b;
}
/*  congratulation div  */
.congratulationWrapper {
  width: 60vw;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.congratulationSubWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link {
  text-decoration: none;
}
.instLogo {
  border-radius: 1em;
  border: 1px solid rgb(186, 186, 186);
  width: 100%;
}
iframe {
  border-radius: 5px;
}
.editBtn {
  /* background: #98989833; */
  border: none;
  color: #142c44;
  margin-bottom: 5px;
  border: 1px solid #98989833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
}

.addHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  color: #3f3e43;
  margin-bottom: 0;
  margin-top: 0;
}

#searchbox-example > div:first-child {
  margin-top: 50px;
}
