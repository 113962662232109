.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 10px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -24px;
}
.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}
.buttonSection button {
  width: 100%;
}
