.filterDrawerBtn {
  /* padding: 11px 12px; */
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 38px;
}

.filterDrawerBtn:hover {
  /* padding: 11px 12px; */
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  background: #e8faf5;
  transition: 0.2s;
}

.filterDrawerHead {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 21px 32px 18px 32px;
  border-bottom: 1px solid #e4e4e4;
}
.filterDrawerTilte {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #142c44;
}
.filterDrawerClearBtn {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #1877f2;
  background: transparent;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}
.filterDrawerClearBtn:focus {
  border: none;
  outline: none;
}
.filterCloeBtn {
  top: 15px;
  right: 335px;
  z-index: 999;
  position: fixed;
  width: 27px;
  height: 27px;
  border: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
