.leadDetailsVerticalSplit {
  position: relative;
  width: 100%;
  height: 100%;
}
.LeadViewCardButtonWrap {
  position: absolute;
  bottom: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  background: #fff;
}
.leadName {
  color: #142c44;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
.leadContactDetails {
  color: #667085;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.leadOtherDetailsWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.leadSourceDetails {
  border-radius: 16px;
  border: 1px solid #eeeef4;
  background: #f2f4f7;
  padding: 4px 12px;
  color: #667085;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.leadAssigneeDetails {
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.22px;
  text-transform: capitalize;
}
.leadDetailsHead {
  text-align: center;
  margin-bottom: 25px;
}
.leadDetailsAvatar {
  background: #667085;
  height: 59px;
  padding: 9px 20px 8px 20px;
  width: 59px;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #fff;
  font-size: 31.059px;
  font-weight: 500;
  line-height: 47px;
}
.leadDetailsPic {
  height: 59px;
  width: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 6px auto;
}
.leadDetailsPic img {
  width: 100%;
  max-width: 100%;
}
