


/* Tab View */

@media only screen and (max-width: 1024px) {
    .noteScreen {
      min-height: calc(-145px + 98vh);
    }
  
   
}