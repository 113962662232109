.EmailDataTableHead {
  display: flex;
  align-items: center;
  background: #f9fafb;
  padding: 10px 0;
}
.EmailDataTableHeadBodyRow {
  display: flex;
  align-items: center;
}

.EmailDataTable {
  border-radius: 8px;
  border: 1px solid #eaecf0;
}
.funnelHeadSplit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.funnelHeadSplitTitle {
  color: #142c44;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.funnelHeadSplitRight {
  display: flex;
  align-items: center;
  gap: 8px;
}
.filterCloeBtn {
  top: 10px;
  right: 404px;
  z-index: 999;
  position: fixed;
  width: 27px;
  height: 27px;
  border: none;
  background: #fff;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.newMapping {
  color: #667085;
  font-size: 14px;
}
.newMappingResponsive {
  display: none;
}
.importSearch {
  width: 300px;
}

@media only screen and (max-width: 1380px) {
  .newMappingResponsive {
    display: unset;
    color: #667085;
    font-size: 14px;
  }
  .newMapping {
    display: none;
  }
  .discardButton {
    margin-bottom: -24px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .zapierTableContainer {
    min-width: unset;
  }
  .emailTableContainer {
    min-width: unset;
  }
  .cellPhone {
    min-width: 130px;
  }
  .cellImport {
    min-width: 140px;
  }
  .cellDate {
    min-width: 130px;
  }
  .importTableContainer {
    min-width: unset;
  }
  .cellEmail {
    min-width: 130px;
  }
  .cellCourse {
    min-width: 110px;
  }
  .cellName {
    min-width: 130px;
  }
  .cellLeadName {
    min-width: 110px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .zapierTableContainer {
    min-width: unset;
  }
  .emailTableContainer {
    min-width: unset;
  }
  .cellPhone {
    min-width: 130px;
  }
  .cellImport {
    min-width: 140px;
  }
  .cellDate {
    min-width: 130px;
  }
  .importTableContainer {
    min-width: unset;
  }
  .cellEmail {
    min-width: 130px;
  }
  .cellCourse {
    min-width: 110px;
  }
  .cellName {
    min-width: 130px;
  }
  .cellLeadName {
    min-width: 110px;
  }
}

@media only screen and (max-width: 1024px) {
  .importSearch {
    width: unset;
  }
}

@media only screen and (max-width: 736px) {
  .importLeadButton {
    font-size: 13px;
    line-height: 16px;
    max-width: 117px;
  }
}
