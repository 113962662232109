.list_item_progress {
  padding: 0px 5px;
  background: #e7fff7;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}
.list_item_completed {
  padding: 0px 5px;
  background: #23a67b;
  color: #fff;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  text-align: center !important;
  padding-left: 15px;
}

.list_item {
  padding: 0px 5px;
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}

.number_wrapperdiv {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list_font_staging {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #69696b;
  border-radius: 50%;
}
.list_font_staging_progress {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #23a67b;
  border-radius: 50%;
  color: #23a67b;
}
.list_font_staging_completed {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.list_font_progress {
  font-size: 14px !important;
  text-align: center !important;
  color: #23a67b;
}
.list_font_completed {
  font-size: 14px !important;
  text-align: center !important;
  font-weight: 600;
  font-family: "Poppins";
}
