.progressBar {
    width: 100%;
    height: 4px;
    background-color: #C6F2E7;
    border-radius: 30px;
}

.fill {
    height: 100%;
    margin-top: -4px;
    border-radius: 30px;
}
.minimum {
    height: 100%;
    background-color: #FFE4E4;
    border-radius: 30px 0px 0px 30px;
    position: relative;
}

.minimum::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 0.8px;
    height: 6px;
    background-color: #B6B6B6;
  }
  
