.integrationBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.ozonetelHead {
  color: #3f3e43;
  font-size: 22px;
  font-weight: 600;
  line-height: 30.801px;
  margin-bottom: 20px;
}
.ozonetelDescription {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 55%;
  margin: 0 auto 20px auto;
}
.ozonetelDetailsSplitRightHead {
  color: #142c44;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.801px;
}
.keyFeaturesListing {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.PageBackBtnWrap {
  padding: 27px 20px 20px 20px;
}
.ozonetelDetailsSplit {
  display: flex;
  height: 100%;
}
.ozonetelDetailsSplitLeftBody {
  text-align: center;
}
.ozonetelDetailsSplitRight {
  border-left: 1px solid #e9e9e9;
  padding: 45px 25px 10px 35px;
}
.ozonetelDetailsSplitRightSubHead {
  color: #142c44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}
.loginSteps {
  color: #667085;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 14px;
  margin-top: 6px;
}
.loginStepHead {
  color: #142c44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.loginSteps li {
  margin-bottom: 4px;
}

.addAgentsFieldWrap {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 12px;
}
.addAgentsFieldItem {
  flex-basis: 100%;
}
.setupStagesWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto 25px auto;
  justify-content: center;
}
.setupStagesWrapItemLine {
  width: 80px;
  height: 1px;
  background: #dedede;
}
.setupStagesWrapItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.setupStagesWrapItemNumber {
  border: 1px solid #11c89b;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.setupStagesWrapItemLabel {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.connectSuccessPopupImgs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.connectSuccessPopupImg {
  overflow: hidden;
}
.connectSuccessPopupImg img {
  max-width: 100%;
  display: flex;
}
.connectSuccessPopupHeading {
  color: #3f3e43;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.connectSuccessPopupDesc {
  color: #667085;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 7px;
}
.connectSuccessPopupOkBtn {
  border-radius: 4px;
  border: 1px solid #11c89b;
  display: flex;
  width: 72px;
  padding: 7px 0px;
  justify-content: center;
  align-items: center;
  color: #11c89b;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  background: #fff;
  cursor: pointer;
  margin: 0 auto;
}
.ozoneSetupGuideItem {
  display: flex;
  gap: 7px;
  margin-bottom: 6px;
}
.ozoneSetupGuideItemStepCount {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
}
.ozoneSetupGuideItemLeft {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}
.ozoneSetupGuideItemStepBorder {
  background: #dedede;
  width: 1px;
  height: 100%;
}
.ozoneSetupGuideItemRightTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 6px;
}
.ozoneSetupGuideItemRightSubTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 6px;
}
.addAgentsFieldItemUserWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid #d4d4d8;
  background: #fff;
}
.addAgentsFieldItemUserWrapAvatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.addAgentsFieldItemUserName {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}
.addAgentsSubHead {
  color: #323b4b;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 auto 20px auto;
  max-width: 80%;
}
.zapierAccountDetailsWrap {
  display: flex;
  gap: 8px;
  /* margin-bottom: 30px; */
}
.zapierAccountDetailsItem {
  text-align: left;
  flex-basis: 100%;
  max-width: 29%;
  overflow: hidden;
}
.zapierDetailsItemHeading {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.zapierDetailsItemContent {
  color: #142c44;
  font-size: 11px;
  font-weight: 500;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


@media only screen and (max-width: 899px) {
  .zapierImage
  {
    width: 70%;
  }
 
  
}

