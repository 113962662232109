.closeModalBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.closeModalBtn {
  border: none;
  background: none;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}
.closeModalTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
}
.closeModalDesRed {
  color: #ec3939;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 18px;
}
.cancelBtn {
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  padding: 10px 18px;
  font-family: "poppins";
  cursor: pointer;
}
.removeBtn {
  border-radius: 4px;
  background: #ec3939;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  border: 1px solid #ec3939;
  padding: 10px 18px;
  text-transform: capitalize;
  font-family: "poppins";
  cursor: pointer;
}
.buttonSectionOk {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buttonSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.buttonSection > button {
  flex-basis: 100%;
}
.closeModalDesStatus {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 9px;
}
.leadwithCurrentUsers {
  color: #142c44;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
  display: block;
}
.removeUserItem {
  color: #ec3939;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 16px;
}
.removeActionItem {
  color: #142c44;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 16px;
}
