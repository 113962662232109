.membershipBarWrap {
  display: flex;
  border-radius: 6px;
  padding: 12px 12px;
  margin-bottom: 10px;
}
.membershipBarWrapLeft {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.membershipBarDetailsTop {
  display: flex;
  align-items: center;
  gap: 8px;
}
.membershipBarTitle {
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
}
.membershipBarDate {
  color: #667085;
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
}
.membershipBarDesc {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}
.resumeBtn {
  color: #8a94a6;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.1);
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
}
