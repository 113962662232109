.blueBtn {
  color: #2664dc;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: underline;
  border: none;
  background-color: #fff;
  padding: 0;
  cursor: pointer;
}
.leadBatchPopupHeadWrap {
  background: #f8f8f8;
  padding: 24px 25px 18px 26px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.leadBatchPopupClsBtn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.leadBatchPopupHead {
  color: #142c44;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.leadBatchPrice {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Roboto";
}
.leadBatchBox {
  padding: 18px 26px;
  border-bottom: 1px solid #ebebed;
}
.leadBatchDatesWrap {
  display: flex;
  gap: 62px;
}
.leadBatchDatesWrapItemLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.leadBatchDatesWrapItemValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.22px;
}
.leadBatchScheduleWrap {
  padding: 20px 26px 35px 26px;
  max-height: 166px;
  overflow-y: auto;
}
.leadBatchScheduleTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
}
.leadBatchScheduleWrapItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 14px;
}
.leadBatchScheduleWrapItem:last-child {
  margin-bottom: 0;
}
.leadBatchScheduleWrapItemDay {
  color: #3f3e43;
  font-size: 14px;
  font-weight: 400;
}
.leadBatchScheduleWrapItemTime {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
}
.blueBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.batchMembershipItemPer {
  color: #8a94a6;
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.membershipBatchPriceWrap {
  display: flex;
  border: 1px solid #667085;
  width: fit-content;
}
.membershipBatchPriceItem {
  border-right: 1px solid #667085;
}
.membershipBatchPriceItem:last-child {
  border-right: none;
}
.membershipBatchPriceItemLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #667085;
  padding: 10px 8px 10px 8px;
}
.membershipBatchPriceItemValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Roboto";
  padding: 10px 8px 10px 8px;
}
