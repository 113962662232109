.addTask {
  width: 100%;
  height: 100px;
  font-size: 14px;
  font-family: Poppins;
}

.cancelSave {
  display: flex;
  justify-content: flex-end;
  /* padding: 12px 0px 10px 0px; */
  margin: 8px 24px;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.iconImage {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 0.5px solid #c7c7c7;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activityArea {
  margin-top: 20px;
}
