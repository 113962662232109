.autoDebitWrapper {
  text-align: center;
  width: 88%;
  margin: 0 auto;
}
.autoDebitHead {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
.autoDebitDesc {
  color: #667085;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.autoDebitDescHighlighted {
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.autoDebitBox {
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  padding: 24px 24px;
  width: 85%;
  margin: 0 auto;
}
