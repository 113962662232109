.ozonetelWidgetContainer {
  /* position: relative; */
  /* height: 80px;
  width: 58px;
  position: fixed;
  border: 0px;
  bottom: 0px;
  right: 0px;
  transition: height 0s ease 0s;
  z-index: 9999999999; */

  border: 0;
  bottom: 2px;
  height: 48px;
  position: fixed;
  right: 0px;
  transition: height 0s ease 0s;
  width: 50px;
  z-index: 9999999999;
}

.popup {
  position: fixed;
  top: 62%;
  left: 86%;
  transform: translate(-50%, -50%);
  width: 27%; /* Adjust the width as per your requirement */
  height: 70%; /* Adjust the height as per your requirement */
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 4px 3px 22px 6px rgba(150, 150, 150, 0.11);
  z-index: 1000; /* Ensure it's above other elements */
  padding: 36px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden; /* Adjust based on content */
  border-radius: 8px;
}

.iframe {
  width: 100%;
  height: 90%; /* Adjust as per content */
  border: none; /* Remove default border */
}

.popupButton {
  background-color: #11c89b; /* Adjust the color */
  color: white;
  height: 48px;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%; /* Rounded corners */
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #667085;
}
