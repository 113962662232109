.completedTaskPageBackBtn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    color: #667085;
    padding-left: 0;
    cursor: pointer;
}
.formDate {
    color:#667085;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}