.notificationTitle {
  color: #142c44;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.notificationContent {
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 22px;
}
.paymentReminder {
  color: #142C44;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.type {
  color: #142C44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}
.value {
  color: #667085;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.scheduleHead {
  color: #667085;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 4px;
}
.schedule {
  color: #667085;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
}
.scheduleWrapper {
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  border-radius: 4px;
  background: #F2F4F7;
}