.courseListFilterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px !important;
}

.courseListStatusFilterWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.courseListStatusFilterItem {
  font-weight: 600;
  font-size: 14px;
  color: #3f3e43;
  text-decoration: none;
  padding: 5px 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.FilterBtnactive {
  color: #23a67b;
}
.batchListWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ededed;
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
}

.batchCard {
  min-width: 100%;
  max-width: 275px;
  display: block;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0px 12px 18px rgba(66, 66, 66, 0.06); */
  /* border-radius: 8px; */
  background: #ffffff;
  z-index: 4;
  text-decoration: none;
  padding: 10px 20px;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  /* flex: 1 1 285px; */
  border: 1px solid rgba(222, 222, 222, 0.7);
  cursor: pointer;
}

.batchName {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
  line-height: 20px;
  /* margin-top: 5px; */
}
.branchName {
  font-weight: 400;
  font-size: 11px;
  color: #667085;
  font-family: "Poppins";
  text-transform: capitalize;
}
.batchMainWrapper {
  display: flex;
  /* padding-bottom: 50px;
  margin-top: -22px; */
  width: 100%;
}
.leftSection {
  /* width: 32%; */
  width: 300px;
  border-right: 1px solid #ededed;
  height: 100vh;
  /* padding-top: 22px; */
}
.rightSection {
  padding: 30px 20px 20px 20px;
  /* width: calc(100% - 32%); */
  width: calc(100% - 300px);
  overflow-y: auto;
  height: calc(100vh - 114px);
  display: flex;
  flex-direction: column;
}
.rightSubSection {
  max-width: 780px;
  min-width: 90%;
  border-radius: 6px;
  /* margin-left: 20px;
  margin-top: 20px; */
}

.link {
  text-decoration: none;
  padding-right: 10px;
}
.selectedBatchCard {
  background: #f8f8f8;
  border-left: 2px solid #11c89b;
}

.batchStatus svg {
  font-size: 18px;
}

.statusActive {
  color: #23a67b;
  display: flex;
}

.statusInActive {
  color: #d32f2f;
  display: flex;
  align-items: center;
}
.statusInActive svg {
  font-size: 14px;
}

.inactiveTag {
  border-radius: 16px;
  background: #fef3f2;
  color: #e7453a;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  padding: 4px 10px;
  text-align: center;
}

.batchCardCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.batchCardCountItem {
  display: flex;
  align-items: center;
  background: #f5f5f7;
  border-radius: 4px;
  width: 105px;
  height: 48px;
  justify-content: space-around;
  box-sizing: border-box;
}

.batchCardCountItemLabel {
  font-weight: 500;
  font-size: 13px;
  color: #69696b;
  text-transform: uppercase;
  border-right: 1px solid #d5d5de;
  height: 100%;
  width: 44%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  font-family: "Poppins";
}

.batchCardCountItemValue {
  font-weight: 600;
  font-size: 20px;
  color: #23a67b;
  padding: 12px 19px;
  width: 56%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  font-family: "Poppins";
}

.batchCardBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.batchCardDate {
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  font-family: "Poppins";
}

.batchCardTime {
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  font-family: "Poppins";
}

.batchCardTime svg {
  font-size: 20px;
}

.batchCardDate svg {
  font-size: 20px;
}

.addListbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 4px;
}

.nameInputWrapper {
  display: flex;
  gap: 0px;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 20px;
  margin-top: -24px;
}

.batchType {
  width: 110px !important;
  height: 35px !important;
  padding: 0px !important;
  border-radius: 60px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.demoBatchType {
  width: 110px !important;
  height: 35px !important;
  padding: 0px !important;
  border-radius: 60px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.batchTypeBox {
  margin-top: 15px;
  margin-bottom: 15px;
}

.batchSchedule {
  /* border: none !important;
  width: 42px !important;
  height: 42px !important;
  border-radius: 50% !important;
  font-size: 11px !important;
  border: 2px solid #69696b !important; */
  /* background-color: #d5d5de !important; */
  /* background-color:gray !important; */
  border: 1px solid #0071e3 !important;
  border-radius: 8px !important;
  min-width: 50px !important;
  aspect-ratio: 1;
  color: #0071e3 !important;
}

.batchScheduleBox {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.btnSaveCahnge {
  padding-bottom: 60px !important;
  text-decoration: none !important;
  margin-top: 40px !important;
  padding-right: 10px;
}

.btnSaveText {
  text-decoration: none !important;
}

.durationWrapper {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  border-radius: 6px;
  padding: 16px 16px;
}

.durationLabel {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
  margin-bottom: 8px;
  font-family: "Poppins";
  margin-top: 24px;
}

.batchDuration {
  font-weight: 500;
  font-size: 13px;
  color: #142c44;
  margin-bottom: 10px;
  font-family: "Poppins";
  /* margin-top: 24px; */
}

.dateLabelWrap {
  background: #ffffff;
  border: 1px solid #d4d4d8;
  border-radius: 6px;
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 12px;
}

.dateLabel {
  font-weight: 400;
  font-size: 16px;
  color: #1877f2;
  display: flex;
}

.dateLabelArrow {
  color: #b1b1b1;
  margin-left: 5px;
  margin-top: 2px;
}

.dateLabelArrow svg {
  font-size: 18px;
}

.dateValueWrap {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}

.dateValue {
  font-weight: 600;
  font-size: 42px;
  color: #3f3e43;
  line-height: 39px;
}

.dateMonthValue {
  font-weight: 500;
  font-size: 20px;
  color: #3f3e43;
  line-height: 25px;
}

.dateDayValue {
  font-weight: 400;
  font-size: 14px;
  color: #3f3e43;
}

.dateField {
  display: none;
}

.batchDurationWrap {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.batchDurationLabel {
  font-weight: 500;
  font-size: 16px;
  color: #69696b;
  margin-bottom: 16px;
}

.batchDurationDays {
  font-weight: 600;
  font-size: 42px;
  line-height: 39px;
  color: #b1b1b1;
}

.batchDurationDaysLabel {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #b1b1b1;
}

.batchDurationDaysWrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  gap: 6px;
}

.sheduleBatchHead {
  display: flex;
  gap: 11px;
}

.timeRepeatBox {
  background: #f6f6f6;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  max-width: 150px;
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  color: #3f3e43;
  margin-bottom: 0;
  margin-top: 0;
}

.scheduleButton {
  border: 1px solid #0071e3;
  border-radius: 8px;
  color: #0071e3;
  min-width: 50px;
  aspect-ratio: 1;
  background: #fff;
}

.scheduleButtonUndisable,
.scheduleButtonUndisable:hover {
  border: 1px solid #0071e3;
  border-radius: 8px;
  color: #fff !important;
  min-width: 50px;
  aspect-ratio: 1;
  background: #0071e3;
  background-color: #0071e3 !important;
}

.scheduleItemsWrap {
  margin-bottom: 20px;
}

.editBtn {
  background: #98989833;
  /* border: none; */
  color: #142c44;
  margin-bottom: 5px;
  border: 1px solid #98989833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: fit-content;
}

.addHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 40%;
  margin-bottom: 30px;
  position: relative;
}

.dateLabelWrapDisabled {
  background: #ffffff;
  border: 1px solid #b6c2e2;
  border-radius: 5px;
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  overflow: hidden;
}

.NoResult {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #142c44;
}
.TryAnother {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}
.batchNameDetail {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #142c44;
  text-transform: capitalize;
}
.branchNameDetail {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #667085;
  text-transform: capitalize;
}
.rupeesSign {
  font-weight: 400;
  font-family: Roboto;
}
.finalPrice {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #142c44;
}
.batchPrice {
  font-weight: 500;
  font-size: 20px;
  color: #142c44;
}
.key {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
}
.keyTitle {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
}
.value {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
}
.value_type {
  font-weight: 400;
  font-size: 12px;
  color: #142c44;
  text-transform: capitalize;
  background: #f3f3f3;
  border-radius: 4px;
  padding: 4px 6px;
}
.timing {
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  text-transform: capitalize;
  color: #494949;
}
.batchScheduleTimeWrap {
  list-style: none;
  padding-left: 0;
}
.batchScheduleTimeWrapItem {
  display: flex;
  margin-bottom: 10px;
}
.batchScheduleTimeWrapItemDay {
  font-weight: 400;
  font-size: 12px;
  color: #3f3e43;
  width: 115px;
}
.batchScheduleTimeWrapItemTime {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  text-align: left;
}
.courseListSelectFilterWrap {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.cancelFormPopupTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
  font-family: "poppins";
}
.cancelFormPopupContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 15px;
  font-family: "poppins";
}
.cancelFormPopupBtnsSplit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancelFormPopupNoBtn {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  min-width: 160px;
  height: 35px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #344054;
  padding: 8px 10px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancelFormPopupYesBtn {
  background: #ec3939;
  border-radius: 4px;
  min-width: 160px;
  height: 35px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #ec3939;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.batchEditorWrapper {
  padding-left: 22px;
  padding-top: 22px;
}
.demoCard {
  border-radius: 4px;
  border: 1px solid rgba(38, 100, 220, 0.1);
  background: rgba(38, 100, 220, 0.04);
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 10px;
}
.DemoTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 1px;
  text-transform: capitalize;
  color: #494949;
  padding: 20px;
}
.demoStudentCardLeadBtn {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.DemoTime {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.DemoTimeContainer {
  display: flex;
  gap: 20px;
}
.ScheduleDemoCard {
  border-top: 1px solid #ebebed;
  border-bottom: 1px solid #ebebed;
}
.batchHead {
  color: #142c44;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
}
@media (min-width: 50px) and (max-width: 1024px) {
  .courseListFilterWrap {
    display: block;
  }

  .courseListSelectFilterWrap {
    margin-top: 15px;
    justify-content: flex-start;
  }
}

@media (min-width: 50px) and (max-width: 1370px) {
  .priceArea {
    width: 270px;
  }
  .batchList {
    flex-direction: column;
  }
  .rightArea {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .batchMainWrapper {
    border-top: 1px solid #ededed;
  }
}

@media only screen and (max-width: 1024px) {
  .link {
    padding-right: 6px;
  }
  .leftSection {
    width: 40%;
  }
  .batchHead {
    font-size: 18px;
  }
}
.batchBasicDetailsWrapper {
  margin-bottom: 14px;
}
.batchBasicDetailsWrap {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
}
.batchBasicDetailsHead {
  display: flex;
  gap: 10px;
  background: rgba(247, 247, 251, 0.48);
  padding: 23px 20px 23px 39px;
  justify-content: space-between;
}
.batchBasicDetailsBatchName {
  color: #142c44;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.32px;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.batchBasicDetailsBranchName {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.batchBasicDetailsLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.batchBasicDetailsValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.22px;
}
.batchBasicDetailsBatchStatus {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  padding: 2px 6px;
  border-radius: 4px;
}
.editSectionBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
}
.batchBasicDetailsEditWrap {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  padding: 26px 28px;
}
.batchBasicDetailsEditSectionTitle {
  color: #142c44;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 14px;
}
.batchTimingWrapper {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  min-height: 296px;
  padding: 25px 24px;
  margin-bottom: 14px;
}
.batchSecHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
}
.batchSecTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.batchTimingDayRow {
  background: rgba(5, 112, 219, 0.08);
  border-left: 3px solid #0570db;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 12px;
}
.batchTimingDayRow:last-child {
  margin-bottom: 0px;
}
.batchTimingDay {
  display: flex;
  align-items: center;
  gap: 7px;
}
.batchTimingDaySessionWrap {
  display: flex;
  align-items: center;
  gap: 7px;
}
.batchTimingDaySessionItems {
  display: flex;
  align-items: center;
  gap: 12px;
}
.batchTimingDaySessionItem {
  padding-right: 12px;
  border-right: 1px solid #cfd9e3;
}
.batchTimingDaySessionItem:last-child {
  padding-right: 0px;

  border-right: none;
}
.batchTimingEditHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 44px;
}
.batchTimingDatePickerWrap {
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
}

.pageWrapper {
  padding: 38px 30px;
  max-height: 100%;
}
.pageWrapperHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.batchSecSubTitle {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 33.66px;
}
.batchSecSubLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
}
.batchTimingEditChildWrap {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 26px 26px;
  margin-bottom: 16px;
}
.sessionDeleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
}
.batchPayType {
  color: #0570db;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  padding: 3px 11px;
  border-radius: 4px;
  background: rgba(5, 112, 219, 0.08);
}
.batchPayWrapper {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  padding: 25px 24px;
}
.batchRegularPayDetailsBox {
  background: #fafafc;
  padding: 23px 23px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.batchRegularPayDetailsBoxLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.batchRegularPayDetailsBoxValue {
  color: #142c44;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
}
.batchRegularPayDetailsBoxLeft {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  justify-content: space-between;
  flex: 1;
  margin-right: 5px;
}
.batchRegularPayDetailsBoxRight {
  border-left: 1px solid #e3e3e3;
  padding-left: 29px;
}
.batchRegularPayFinalPrice {
  color: #11c89b;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 4px;
}
.batchRegularAddDiscountBtn {
  display: flex;
  align-items: center;
  color: #0570db;
  font-size: 11px;
  font-weight: 500;
  line-height: 22.232px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 5px 5px;
}
.batchRegularSetupEmi {
  cursor: pointer;
  border: none;
  background: transparent;
  color: #8a94a6;
  font-size: 11px;
  font-weight: 500;
  line-height: 22.232px;
  display: flex;
  align-items: center;
}
.batchEmiMainDetailsWrap {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-bottom: 25px;
}
.batchEmiMainDetailsLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.batchEmiMainDetailsValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.22px;
}
.batchEmiDetailsWrap {
  padding: 26px 22px;
}
.batchEmiTableTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.batchEmiTable {
  border-radius: 4px;
  border: 1px solid #eee;
}
.batchEmiTableHead {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f8f9fb;
  padding: 10px 17px;
  gap: 3%;
}
.batchEmiTableHead > div {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.batchEmiTableRow {
  display: flex;
  align-items: center;
  gap: 3%;
  padding: 10px 17px;
}
.batchEmiTableRow > div {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}
.batchRegularPayWrap {
  border-radius: 4px;
  border: 1px solid #eee;
}

.batchMembershipWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}
.batchMembershipItem {
  border-radius: 6px;
  border: 1px solid #9747ff;
  background: #fff;
  width: calc(50% - 7px);
  overflow: hidden;
}
.batchMembershipItemHead {
  /* background: rgba(247, 247, 251, 0.6); */
  padding: 15px 20px;
  background: #9747ff;
}
.batchMembershipItemName {
  border-radius: 4px;
  background: #fff;
  color: #9747ff;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  width: fit-content;
  padding: 3px 11px;
}
.batchMembershipItemLabel {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.batchMembershipItemPrice {
  color: #fff;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}
.batchMembershipItemBottom {
  padding: 16px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.batchMembershipAddDiscountBtn {
  border-radius: 4px;
  background: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 0px;
}
.batchMembershipItemFinalPrice {
  color: #ab6aff;
  font-family: "Roboto";
  font-size: 20.939px;
  font-weight: 500;
  line-height: 30px;
}
.batchMembershipItemPer {
  color: #8a94a6;
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.batchEmiEditMainRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}
.batchEmiEditMainRowLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 29.384px;
}
.batchMembershipItems {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.batchMembershipItemHeadTop {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 16px;
}
.batchBasicDetailsStatus {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 3px 8px;
  border-radius: 3px;
  margin-top: 5px;
  text-transform: capitalize;
}
.batchBasicDetailsHeadRight {
  display: flex;
  align-items: flex-start;
}
.batchBasicDetailsConductType {
  color: #344054;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 3px 8px;
  border-radius: 4px;
  background: #f2f4f7;
  width: fit-content;
}
.batchEditRegularPayRow {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.batchEditRegularEmiStatus {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.batchEmiEnableBtn {
  border: none;
  border-radius: 6px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding: 10px 14px;
  max-height: 38px;
}
.batchTimingSubSecTitle {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.batchTimingDayRowLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}
.customTypeRow {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 22px;
}
.customTypeFieldLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 4px;
}
.addMoreTypeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 503px;
}
.addMoreTypeBtn {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: rgba(241, 244, 248, 0.4);
  padding: 6px 14px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.deleteTypeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}
.batchCustomTitle {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
.definedCustomPayRow {
  display: flex;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #f0f0f5;
  background: #fafafc;
  padding: 14px 23px;
  margin-bottom: 7px;
  justify-content: space-between;
}
.definedCustomPayRow:last-child {
  margin-bottom: 0;
}
.definedCustomPayRowLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}
.definedCustomPayItemLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.definedCustomPayItemValue {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 25px;
}
.noBatchTimingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: "100%";
  min-height: 200px;
}

.noDetailsText {
  color: #8a94a6;
  font-size: 12px;
  font-weight: 500;
}

.noSchedulesText {
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.membershipTypeRow {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 22px;
}
.membershipTypeFieldLabel {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 29.384px;
  margin-bottom: 4px;
}
.addMoreTypeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 503px;
}
.addMoreTypeBtn {
  border-radius: 4px;
  border: 1px solid rgba(102, 112, 133, 0.12);
  background: rgba(241, 244, 248, 0.4);
  padding: 6px 14px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.deleteTypeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}
.addRandomBtn {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  border: none;
  background: transparent;
}
.balance {
  color: #142c44;
  display: flex;
  gap: 4px;
  line-height: 22px;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.studentItemRow {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding: 14px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  margin-bottom: 6px;
}
.studentItemRow:last-child {
  margin-bottom: 0;
}
.studentItemRowLeft {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
.studentAccordHeadTitle {
  color: #142c44;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.studentItemsWrap {
  max-height: 357px;
  overflow-y: auto;
}
.studentSec {
  margin-top: 10px;
}

.NoCourse {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 300px auto;
  background-color: #f4f4f4;
  padding: 10px;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(102, 112, 133, 0.12);
  border-radius: 8px;
  cursor: pointer;
}
.bookedSessionsWrapper {
  display: flex;
  border: 1px solid #eee;
  border-radius: 4px;
}
.bookedSessionsCalendar {
  border-right: 1px solid #eee;
  padding: 10px;
}
.bookedSessionsWrap {
  padding: 10px;
  flex: 1;
}
.bookedSessionsItem {
  background: rgba(5, 112, 219, 0.08);
  padding: 10px 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}
.bookedSessionPiece {
  display: flex;
  align-items: center;
  gap: 7px;
}
.bookedSessionPieceDetail {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
}
.bookedViewLeadBtn {
  border: none;
  background: transparent;
  color: #0570db;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  text-underline-offset: 4px;
}
.bookedCalendarHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.bookedCalendarHeadLeft {
  color: #142c44;
  font-weight: 500;
  font-size: 15px;
}
.bookedCalendarHeadRight {
  display: flex;
  align-items: center;
  gap: 4px;
}
.bookedCalendarHeadRightItem {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: #667085;
}

.sessionTabDescWrapPop {
  display: none;
}
.sessionTabDescWrap:hover .sessionTabDescWrapPop {
  display: block;
  position: absolute;
  background-color: #fff;
  bottom: 20px;
  right: 0;
  color: #69696b;
  padding: 5px 10px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  font-size: 12px;
  border: 1px solid #e4e4e7;
  z-index: 99999999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px;
}
.sessionTabDesc {
  color: #69696b;
  font-size: 12px;
}
.sessionTabDesc::first-letter {
  text-transform: capitalize;
}
.sessionTabDescWrapPop::first-letter {
  text-transform: capitalize;
}
