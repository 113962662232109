.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}



.Header {
  position: absolute;
  left: 16px;
  top: 16px;
}

.form {
  background: #fff;
  padding: 50px 30px;
  max-width: 460px;
  border-radius: 6px;
}

.leftPanal {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #142c44;
}

.leftPanal img {
  max-width: 60%;
}

.rightPanal {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.formHeading {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 32px;
  justify-content: space-around !important;
}

.formInfo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 20px;
  color: #8a8a8a;
  margin-top: 14px;
}

.formDanger {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #d32f2f;
}

.policyText {
  margin-top: 32px !important;
  color: #3f3e43 !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.ServiceText {
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  text-align: center !important;
}

.sendotpTxt {
  margin-top: 32px;
  font-size: 14px;
  color: #3f3e43;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  position: relative;
  bottom: 27px;
}

.link {
  text-decoration: none;
  margin-bottom: 15px;
  color: #1877f2;
  font-weight: 400;
  font-family: "Poppins";
}

.otpInputWrapper {
  /* display: flex;
  justify-content: flex-start; */
  margin-bottom: 30px;
}

.logo_box {
  justify-content: center;
  margin-bottom: 90px;
}

.inputStyle {
  width: 38px !important;
  height: 42px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #bcbcbc;
  font-family: "Poppins";
}

.borderHover:focus {
  outline-color: #23a67b;
}

.formHeadingalt {
  font-size: 32px !important;
  font-weight: 600 !important;
  text-align: left !important;
  line-height: 538px !important;
  margin-bottom: 10px;
  font-family: "Poppins" !important;
  color: #142c44 !important;
}

.list {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.passwordCriteria {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 27px !important;
  display: flex;
  margin-left: 10px !important;
  align-items: center;
}

.passwordCriteriaList_icons {
  min-width: 18px !important;
  color: green;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.notValid_passwordCriteriaList_icons {
  min-width: 18px !important;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.submitButton {
  margin-top: 25px !important;
}

.terms_txt {
  font-weight: 600;
}

.gapText {
  font-size: 14px !important;
}

.serviceText {
  margin-top: 0px;
  text-align: center;
}

.password_text_head {
  font-weight: 600;
  margin-bottom: 14px;
}

.count_text {
  color: red;
}

.count_text_main {
  font-size: 14px !important;
  text-align: center !important;
  color: #3f3e43;
  font-family: "Poppins";
  font-weight: 400;
}

@media(min-width:50px)and (max-width:1024px) {
  .page {
    display: block;
    width: 100%;
  }

  .leftPanal {
    width: 100%;
    height: auto;
  }

  .leftPanal img {
    width: auto !important;
    height: auto !important;
  }

  .Header {
    background: #142c44;
    justify-content: center;
    padding-top: 25px;
    position: relative;
    top: 0;
    left: 0;
  }
}