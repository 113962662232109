.paymentDetailsHead {
  display: flex;
  background: rgb(250, 250, 252);
  padding: 20px 24px;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 5px;
}
.paymentDetailsHeadLeft {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex: 1;
}
.paymentDetailsHeadItemLabel {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.paymentDetailsHeadItemValue {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPrice {
  color: #11c89b;
  font-size: 20.939px;
  font-weight: 600;
  line-height: 30px;
}
.paymentDetailsHeadFinalPriceType {
  color: #8a94a6;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 26px;
}
.paymentDetailsHeadFinalPriceWrap {
  padding-left: 30px;
  border-left: 1px solid #e3e3e3;
}
.paymentDetailsHeadRight {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
}
.regularPayDetailsItems {
  display: flex;
  gap: 100px;
  padding: 24px 28px 16px 28px;
}
.regularPayDetailsLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}
.regularPayDetailsValue {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.22px;
}
.regularPaySecTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.leadEditRegularPayRow {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.leadEmiEditMainRowLabel {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 29.384px;
}
.leadEmiEnableBtn {
  border-radius: 6px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding: 10px 14px;
  max-height: 38px;
}
.leadEditRegularEmiStatus {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
}
.editSectionBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
}
.leadEmiTableTitle {
  color: #142c44;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.leadEmiEditMainRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}
.leadEmiTableHead {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f8f9fb;
  padding: 10px 17px;
  gap: 3%;
}
.leadEmiTableHead > div {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.leadEmiTableRow {
  display: flex;
  align-items: flex-start;
  gap: 3%;
  padding: 10px 17px;
}

.leadEmiTableRow > div {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}
.balance {
  color: #142c44;
  display: flex;
  gap: 4px;
  line-height: 22px;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
