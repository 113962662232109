.tableSkeletonWrap {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 8px;
}
.tableSkeletonHead {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid rgb(234, 236, 240);
  justify-content: space-between;
  gap: 10px;
  background: #f9fafb;
}
.tableSkeletonBodyRow {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  justify-content: space-between;
  gap: 10px;
}
.skeletonBannerWrap {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  overflow: hidden;
}
.skeletonProfileDetailsWrap {
  padding: 50px 29px 29px 29px;
}
.skeletonProfileMainDetailsWrap {
  display: flex;
  align-items: center;
  gap: 15px;
}
.userProfileSkeletonWrap {
  /* border: 1px solid rgb(235, 235, 237); */
  /* margin-top: 20px;
  margin-left: 22px;
  margin-right: 10px; */
}
.userProfileSkeletonHeadWrap {
  padding: 20px;
  border-bottom: 1px solid #ebebed;
  display: flex;
  align-items: center;
  gap: 20px;
}

.smallFormPopupSkeletonWrap {
  width: 100%;
}
.smallFormPopupHead {
  display: flex;
  gap: 20px;
  align-items: center;
}
.smallFormPopupHeadItemWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.smallFormPopupHeadSplit {
  display: flex;
  gap: 20px;
  align-items: center;
}
.smallFormPopupHeadSplitItem {
  flex-basis: 100%;
}
.buttonSplit {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.batchBasicDetailsWrap {
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(241, 244, 248, 0.5);
  margin-bottom: 10px;
}
.batchBasicDetailsHead {
  background: rgba(247, 247, 251, 0.48);
  padding: 23px 20px 23px 39px;
}

.courseDetailsWrap {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 65%;
}

.courseHeader {
  display: flex;
  justify-content: space-between;
  background-color: rgba(247, 247, 251, 0.48);
  padding: 30px;
}

.courseBasicInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}

.courseDetailsSectionContainer {
  border-radius: 3px;
  margin: 10px;
  cursor: pointer;
}

.tagsSection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 321px;
  padding: 20px;
  height: 200px;
}

.tagsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coursePaySection {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  width: 65%;
}

.rightSection {
  /* width: calc(100% - 341px);
   */
  width: 65%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  padding: 20px 25px;
  margin-top: 20px; /*newly added*/
}
.reportsLeftSection {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
  border-radius: 4px;
  padding: 20px 25px;
  height: 100%;
}
