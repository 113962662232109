.subscriptionPlanBox {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 32px;
}
.subscriptionPlanStatusWrap {
  padding: 22px 22px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.subscriptionPlanExpiryWrap {
  padding: 22px 22px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.subscriptionPlanExpiryDate {
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #142c44;
  margin-bottom: 4px;
}
.subscriptionPlanExpiryDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin: 0;
}
.subscriptionDetailsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 9px;
}
.subscriptionDetailsHeadTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #142c44;
}
.subscriptionDetailsBox {
  border: 1px solid #ebebed;
  border-radius: 6px;
}
.subscriptionDetailsBoxHead {
  background: #f5f7fb;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.subscriptionDetailsBoxHeadLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}
.subscriptionDetailsBoxHeadLogo {
  display: flex;
  overflow: hidden;
}
.subscriptionDetailsBoxHeadLogo img {
  max-width: 100%;
}
.subscriptionDetailsBoxHeadName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #142c44;
}
.subscriptionDetailsBoxHeadLink {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #667085;
}
.subscriptionDetailsBoxHeadDetails {
  padding: 22px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #ebebed;
}
.subscriptionDetailsBoxHeadDetailsLeft {
  display: flex;
  gap: 60px;
}
.subscriptionHeadDetailsLeftItemLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
  margin-bottom: 2px;
}
.subscriptionHeadDetailsLeftItemCount {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
}
.subscriptionBoxHeadDetailsRightItem {
  text-align: right;
}
.subscriptionHeadDetailsRightItemLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
  margin-bottom: 2px;
}
.subscriptionHeadDetailsRightItemValue {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #142c44;
  font-family: "Roboto";
}
.subscriptionTableWrapHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 36px 17px 25px;
  border-bottom: 1px solid #ebebed;
}
.subscriptionTableWrapHeadLeft {
  display: flex;
  align-items: center;
}
.subscriptionTableWrapHeadItem {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #142c44;
}
.subscriptionTableWrapBodyItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 36px 14px 26px;
  border-bottom: 1px solid #ebebed;
}
.subscriptionTableWrapBodyItemLeft {
  display: flex;
  align-items: center;
}
.subscriptionTableWrapBodyItemLeftItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.subscriptionTableWrapBodyItemRightItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.subscriptionTableWrapBottom {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #142c44;
  padding: 12px 36px 12px 26px;
}
.subscriptionPlanStatusType {
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.subscriptionPlanStatusCycle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
}
.subscriptionPlanStatusCycleValue {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
}
.subscriptionPlanStatusRadio {
  display: flex;
  align-items: center;
  gap: 8px;
}
.subscriptionPlanStatusRadioIcon {
  display: flex;
  overflow: hidden;
}
.subscriptionPlanStatusRadioIcon img {
  max-width: 100%;
}
.subscriptionPlanStatusRadioSubLabel {
  color: #667085;
}
.subscriptionPlanStatusRadioLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.subscriptionTableWrapBodyItemUserLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
}
.subscriptionTableWrapBodyItemRightIemUserPrice {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #142c44;
  text-align: right;
}
/* pay modal */
.addBillingModalWrap {
  padding: 26px 20px 20px 26px;
}
.addBillingModalCloseWrap {
  display: flex;
  justify-content: flex-end;
}
.addBillingModalCloseBtn {
  border: none;
  background: transparent;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.manageBillingModalHeading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
  margin-bottom: 8px;
}
.manageBillingBranchList {
  max-height: 120px;
  overflow-y: auto;
  padding-right: 10px;
}
.manageBillingBranchListItem {
  border: 1px solid #e4e4e7;
  border-radius: 4px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid: 10px;
  margin-bottom: 6px;
}
.manageBillingBranchItemName {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #667085;
}
.manageBillingBranchListItemRight {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
}
.manageBillingModalSubHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 12px;
}
.manageBillingBranchListIDivider {
  height: 1px;
  background: #e7e7e7;
  width: 97%;
  display: block;
  margin-top: 6px;
  margin-bottom: 12px;
  /* margin-right: 10px; */
  position: relative;
}
.manageBillingUserCount {
  min-width: 31px;
  border-left: 1px solid #e6e6e6;
  /* border-right: 1px solid #e6e6e6; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  padding: 0 5px;
}
.manageBillingUserCountWrap {
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 14px;
  margin-right: 10px;
}
.manageBillingUserCountLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #667085;
}
.manageBillingPromoCodeTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  color: #142c44;
  margin-bottom: 5px;
}
.manageBillingPromoCodeFormWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: "6px";
  margin-right: 10px;
}
.manageBillingPromoCodeFormField {
  width: 270px;
}
.manageBillingPromoCodeAppliedWrap {
  background: rgba(17, 200, 155, 0.06);
  border: 1px solid rgba(17, 200, 155, 0.6);
  border-radius: 6px;
  padding: 16px 20px 16px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-right: 10px;
}
.manageBillingPromoCodeAppliedWrapLeft {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
}
.manageBillingPromoCodeAppliedLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: capitalize;
  color: #142c44;
}
.manageBillingPromoCodeAppliedOff {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #667085;
}
.manageBillingPromoCodeAppliedIcon {
  display: flex;
  overflow: hidden;
}
.manageBillingPromoCodeAppliedIcon img {
  max-width: 100%;
}
.manageBillingPromoCodeAppliedRemove {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #ec3939;
  border: none;
  background: none;
  padding: 0;
  outline: none !important;
  cursor: pointer;
}
.manageBillingSummaryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.manageBillingSummaryItemLeft {
  display: flex;
  align-items: center;
  gap: 5px;
}
.manageBillingSummaryItemLabel {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #667085;
  max-width: 180px;
}
.manageBillingSummaryItemCount {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #667085;
  width: 80px;
}
.manageBillingSummaryItemPrice {
  color: #142c44;
  text-align: right;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 25px;
}
.manageBillingSummaryWrap {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 12px 14px;
  margin-top: 14px;
  margin-bottom: 12px;
  margin-right: 10px;
}
.manageBillingSummaryTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #142c44;
  margin-bottom: 8px;
}
.manageBillingSummaryFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.manageBillingSummaryFooterLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #142c44;
}
.manageBillingSummaryFooterPrice {
  color: #142c44;
  text-align: right;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 25px;
}
.manageBillingModalBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-right: 10px;
}

/* manage subscription */
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 22px;
}
.manageSubscriptionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 14px;
}
.manageSubscriptionTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
}

.manageSubscriptionHeadRight {
  display: flex;
  align-items: center;
  gap: 9px;
}
.manageSubscriptionCycleLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #667085;
}
.manageSubscriptionPricingTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #142c44;
  margin-bottom: 10px;
}
.manageSubscriptionPriceWrapSplit {
  display: flex;
  justify-content: space-between;
  gap: 17px;
  margin-bottom: 30px;
}
.manageSubscriptionPriceItem {
  flex-basis: 100%;
  padding: 20px 25px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}
.manageSubscriptionPriceItemTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}
.manageSubscriptionPriceItemType {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #142c44;
  text-transform: capitalize;
}
.manageSubscriptionPriceItemPrice {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #142c44;
  font-family: "Roboto";
}
.manageSubscriptionPriceItemPriceType {
  font-weight: 400;
  font-size: 16px;
}
.manageSubscriptionPriceItemDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin: 0;
}
.manageSubscriptionBillingHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 11px;
}
.manageSubscriptionBranchTable {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 12px;
}
.manageSubscriptionBranchTableHead {
  background: #f9fafb;
  padding: 15px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manageSubscriptionBranchTableHeadRight {
  display: flex;
  align-items: center;
}
.manageSubscriptionBranchTableHeadBranch {
  /* max-width: calc(100% - 220px); */
}
.manageSubscriptionBranchTableHeadAction {
  width: 100px;
}
.manageSubscriptionBranchTableHeadAmount {
  width: 120px;
  text-align: right;
}

.manageSubscriptionBranchTableBody {
  padding: 10px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #eaecf0; */
  color: #667085;
}
.manageSubscriptionBranchTableBody:first-child {
  border-top: 1px solid #eaecf0;
}

.manageSubscriptionBranchTableBodyRight {
  display: flex;
  align-items: center;
}
.manageSubscriptionBranchTableBodyBranch {
  /* max-width: calc(100% - 220px); */
  text-transform: capitalize;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.manageSubscriptionBranchTableBodyAction {
  width: 100px;
}
.manageSubscriptionBranchTableBodyAmount {
  width: 120px;
  text-align: right;
}
.manageSubscriptionUsersLeft {
  display: flex;
  align-items: center;
  gap: 28px;
}
.manageSubscriptionUserCountLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #142c44;
}
.manageSubscriptionUserDesc {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #667085;
}
.manageSubscriptionUsersPrice {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #142c44;
  font-family: "Roboto";
}
.manageSubscriptionUserCountWrap {
  /* border: 1px solid #eaecf0; */
  /* border-radius: 8px; */
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* margin-bottom: 12px; */
}
.manageSubscriptionSummaryWrap {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 16px 23px;
  margin-bottom: 12px;
}
.manageSubscriptionSummaryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.manageSubscriptionSummaryItemLabel {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #667085;
}
.manageSubscriptionSummaryItemValue {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #667085;
  font-family: "Roboto";
}
.manageSubscriptionSummaryItem {
  margin-bottom: 6px;
}
.manageSubscriptionSummaryFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.manageSubscriptionSummaryFooterItemLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  color: #142c44;
}
.manageSubscriptionSummaryFooterItemValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #142c44;
  font-family: "Roboto";
}
.manageBillingUserExtra {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #ec3939;
  margin-left: 12px;
}
.manageBillingUserCountBtnsWrap {
  display: flex;
  align-items: center;
}
.manageSubscriptionBranchActionRemoveBtn {
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ec3939;
  outline: none !important;
  text-transform: capitalize;
  cursor: pointer;
}

.manageSubscriptionPayBtn {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: flex-start;
}
.manageSubscriptionGstDetails {
  color: #667085;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
}

.accordionHeadPrice {
  color: #142c44;
  text-align: right;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
/* warnings */
.gracePeriodWarningWrap {
  background: rgba(244, 237, 237, 0.4);
  border: 1px solid rgba(241, 179, 179, 0.8);
  border-radius: 6px;
  padding: 18px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
}
.gracePeriodWarningWrapLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}
.gracePeriodWarningLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #faab10;
}
.gracePeriodWarningIcon {
  display: flex;
  overflow: hidden;
}
.gracePeriodWarningIcon img {
  max-width: 100%;
}
.gracePeriodWarningWrapRight {
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #ec3939;
}
.lockedWarningWrap {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ec3939;
}
.lockedWarningWrapRight {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ec3939;
}
.lockedWarningWrap {
  background: rgba(244, 237, 237, 0.4);
  border: 1px solid rgba(241, 179, 179, 0.8);
  border-radius: 6px;
  padding: 18px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
}
.lockedWarningWrapLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}
.lockedWarningIcon {
  display: flex;
  overflow: hidden;
}
.lockedWarningIcon img {
  max-width: 100%;
}
.lockedWarningLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ec3939;
}
.accountUnarchivedWarningWrap {
  background: rgba(17, 200, 155, 0.06);
  border: 1px solid rgba(17, 200, 155, 0.7);
  border-radius: 6px;
  padding: 18px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
}

.accountUnarchivedWarningWrapLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}
.accountUnarchivedWarningIcon {
  display: flex;
  overflow: hidden;
}
.accountUnarchivedWarningIcon img {
  max-width: 100%;
}
.accountUnarchivedWarningLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #11c89b;
}
.accountUnarchivedWarningWrapRight {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #11c89b;
}

.accordionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  /* border-bottom: 1px solid #eaecf0; */
  /* padding-bottom: 10px; */
}
.accordionHeadLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.accordionHeadIcon {
  background: #f8f8f8;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordionHeadLabel {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}
.branchEditBtn {
  color: #cacaca;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  background: none;
  padding: 0;
  outline: none !important;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.branchEditBtn:hover {
  color: #69696b;
}
.manageSubscriptionBranchUnused {
  color: #eba418;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.571px;
  border-radius: 12.952px;
  background: #fef6e0;
  padding: 2px 6px;
}
.accordianUserCurrentStatus {
  color: #667085;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 25px;
}
.accordionHeadRight {
  display: flex;
  align-items: center;
  gap: 20px;
}
.accordionHeadBillableUser {
  color: #142c44;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 25px;
}
.subscriptionAutoDebit {
  color: #667085;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  border-radius: 25px;
  background: #f3f3f3;
  padding: 3px 11px;
}
