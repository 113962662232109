.taskTypeSelect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: #f2f4f7;
  border-radius: 16px;
  color: #344054;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.leadExpandWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  color: #131313;
  background: rgba(0, 0, 0, 0.18);
  padding: 1% 1%;
  transition: 0.3s ease-in-out;
  cursor: default;
}

.leadExpandWrapperChild {
  width: 60%;
  /* height: 55%; */
  height: 350px;
  top: 15%;
  left: 22%;
  right: 0;
  bottom: 0;
  background: #fff;
  position: relative;
  border-radius: 6px;
  display: flex;
}
.course {
  display: flex;
  gap: 30px;
  margin-bottom: 8px;
}

.batch {
  display: flex;
  gap: 40px;
  margin-bottom: 8px;
}

.status {
  display: flex;
  gap: 30px;
  align-items: center;
}
.key {
  font-weight: 400;
  font-size: 14px;
  color: #667085;
}

.value {
  font-weight: 500;
  font-size: 14px;
  color: #3f3e43;
}
.value:first-letter {
  text-transform: capitalize;
}
.value_status {
  font-weight: 500;
  font-size: 13px;
  color: #3f3e43;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #f2f4f7;
  border-radius: 16px;
  text-transform: capitalize;
}

.leadDetails {
  width: 40%;
  border-right: 1px solid #eeeeee;
  padding: 20px;
}

.taskDetails {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 27px); */
  justify-content: space-between;
}
.rightArea {
  width: 60%;
}

.taskHead {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #eaeaea;
  padding: 0 0 6px;
  align-items: center;
  justify-content: space-between;
}
.taskHeadLeft {
  display: flex;
  gap: 16px;
  align-items: center;
}
.taskTCompletedDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #12bd93;
}
.name {
  color: #142c44;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.email {
  color: #667085;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 16px;
}

.note {
  font-weight: 400;
  font-size: 14px;
  color: #142c44;
  padding: 10px 0;
}

.clock {
  display: flex;
  gap: 5px;
  align-items: center;
}
.clockImage {
  display: flex;
  align-items: center;
}

.stage {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date {
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #142c44;
}
.assignee {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #f5f5f7;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #667085;
}

.studentsListWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.students {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
  height: 200px;
  overflow-y: auto;
}

.studentsList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
}

.buttonSection {
  display: flex;
  justify-content: center;
}
.noButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #ffffff;
  margin-right: 12px;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  color: #344054;
  cursor: pointer;
}
.closeBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}
.taskBodyWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.batchHeader {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.calendarHeader {
  display: flex;
  padding: 5px 20px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}
.applyClearSection {
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
}
.headerText {
  color: #142c44;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
}

.custom-calendar-button .MuiButtonBase-root.MuiPickersDay-root.demo-day {
  background-color: red;
}
.demoCalander button.MuiButtonBase-root.MuiPickersDay-root.demo-day {
  position: relative;
  background: red !important;
}
button.MuiButtonBase-root.MuiPickersDay-root.demo-day {
  position: relative;
  background: red !important;
}
.demoCalander
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.demo-day::after {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #11c89b;
  border-radius: 50%;
  bottom: 0;
  content: "";
}

.batchBody {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.bodyHeader {
  background-color: #fafafc;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
}
.bodyFooter {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
}

.location {
  display: flex;
  align-items: center;
  gap: 10;
}

.imageAlignmentWithText {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.locationText {
  color: #8a94a6;
  font-size: 13px;
  margin-left: 5px;
}

.addAttendanceText {
  color: #0eb68c;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}

.viewAttendanceText {
  color: #4a79f0;
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.images {
  height: 20px;
  width: 20px;
}

.batchTitle {
  color: #142c44;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.courseTitle {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.NoResult {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #142c44;
}
.TryAnother {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}
.graphContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 30px;
}
.lineGraphContainer {
  /* min-width: 674px; */
  min-height: 343px;
  border: 1px solid #efefef;
  padding: 10px;
  width: 70%;
}

.pieGraphContainer {
  /* width: 350px; */
  min-height: 343px;
  border: 1px solid #efefef;
  padding: 10px;
  width: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leadsTableContainer {
  margin: 30px 20px;
}

.filterContainer {
  padding: 10px 10px;
  height: 70vh;
  overflow-y: scroll;
}
.applyButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.clearFilterText {
  font-size: 13px;
  font-weight: 500;
  color: #1877f2;
  cursor: pointer;
}

.applyFilterText {
  font-size: 13px;
  font-weight: 500;
  color: #12bd93;
  cursor: pointer;
}

.attendanceContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.leftAttendanceSection {
  width: 60%;
  border: 1px solid #eeeeee;
  height: 73vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.rightAttendanceSection {
  height: 73vh;
  width: 37%;
  border: 1px solid #eeeeee;
  padding: 20px;
}

.topSection {
  background-color: #f7f7fb80;
  height: 100px;
}

.bottomSection {
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.leftTitle {
  display: flex;
  flex-direction: column;
}

.rightTitle {
  border: 1px solid #eaeaea;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #667085;
  cursor: pointer;
}

.mainTitle {
  color: #142c44;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.subTitle {
  color: #667085;
  font-size: 14px;
  font-weight: 500;
}
.batchInfoHeader {
  font-size: 13px;
  font-weight: 400;
  color: #667085;
  margin: 5px 0px;
}

.courseName {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.batchDetailsSection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
}

.overviewContainer {
  margin: 20px;
  border: 1px solid #ededed;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.overViewTitle {
  font-size: 13px;
  font-weight: 600;
  color: #7d8592;
}

.attendanceGraphContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.graph {
  width: 170px;
  height: 170px;
  margin-top: 10px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  background-color: #f2f3f880;
  width: 150px;
  padding: 10px;
  border-radius: 8px;
}

.presentHeader {
  display: flex;
  align-items: center;
  gap: 5;
  font-size: 13px;
  font-weight: 500;
  color: #7d8592;
}

.presentCount {
  font-size: 15px;
  font-weight: 600;
  color: #1f263e;
}
.attendanceListHeader {
  font-size: 15px;
  font-weight: 500;
  color: #142c44;
  margin-bottom: 10px;
}
.attendanceListContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 65vh;
  scrollbar-width: none;
}

.studentNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f2f2f2;
  padding: 10px;
  margin-bottom: 5px;
}

.studentNameWithIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5;
}

.attendanceBatchHeader {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.studentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #667085;
  border-radius: 50%;
  text-transform: capitalize;
  width: 30px;
  height: 30px;
  color: #fff;
}

.studentName {
  font-size: 13px;
  font-weight: 500;
  color: #142c44;
  margin-left: 5px;
}

.progressBar {
  width: 40px;
  height: 40px;
}
