.actionableNotificationContainer {
  border: 0;
  bottom: 2px;
  height: 48px;
  position: fixed;
  right: 0px;
  transition: height 0s ease 0s;
  width: 50px;
  z-index: 9999999999;
}
.popup {
  position: fixed;
  bottom: 0;
  right: 6%;
  width: 26%;
  height: auto;
  min-height: auto;
  background-color: #fff;
  z-index: 1000;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
  box-shadow: 4px 3px 22px 6px rgba(150, 150, 150, 0.11);
  border: 1px solid rgba(222, 222, 222, 0.6);
  /* opacity: 0; */
  transition: opacity 0.1s ease; /* Adjust the duration and easing function as needed */
  max-height: 720px;
}

.popup.visible {
  opacity: 1;
}

.closeButton {
  cursor: pointer;
  color: #667085;
}
.notificationHeader {
  color: #4c7ddc;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.timerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f5ff;
}
.taskStatus {
  font-weight: 500;
  font-size: 11px;
  text-transform: capitalize;
  border-radius: 16px;
  padding: 6px;
  min-height: 26px;
  max-height: 26px;
}
.taskType {
  font-weight: 500;
  font-size: 11px;
  color: #667085;
  text-transform: capitalize;
  background: #f2f4f7;
  border-radius: 16px;
  padding: 6px;
  min-height: 26px;
  max-height: 26px;
}
.minimizeIcon {
  cursor: pointer;
  color: #667085;
}
.overdueTaskAssignee {
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  border: 0.733px solid #eeeef4;
  background: #f5f5f7;
  color: #667085;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-transform: capitalize;
}
.reminderDesscription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
.leadName,
.studentName {
  color: #142c44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.courseName {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.header {
  color: #142c44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.batchName {
  color: #142c44;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.dueAmount {
  color: #ec3939;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.taskDate {
  display: flex;
  gap: 3px;
  font-weight: 500;
  font-size: 11px;
  color: #667085;
  text-transform: capitalize;
  background: #f2f4f7;
  border-radius: 16px;
  padding: 6px;
  min-height: 26px;
  max-height: 26px;
}
