.courseDrawerBtn {
  position: absolute;
  top: 21px;
  right: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}
.batchDrawerBtn {
  position: absolute;
  top: 21px;
  left: -11px;
  width: 24px;
  height: 24px;
  border: 1px solid #e8e8e8;
  background: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}
.kanbanBoard {
  display: flex;
  background-color: #f4f5f7;
}
.kanbanBoardLeadsWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f5f7;
  max-height: calc(100vh - 197px);
  overflow-y: auto;
}

.kanbanColumn {
  flex: 1;
  margin: 0 6px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
.kanbanColumn:first-child {
  margin-left: 0;
}
.kanbanColumn:last-child {
  /* margin-right: 0; */
}
.kanbanColumnLeads:first-child {
  margin-left: 0;
}
.kanbanColumnLeads:last-child {
  /* margin-right: 0; */
}

.kanbanColumnLeads {
  flex: 1;
  margin: 0 6px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
.kanbanColumnHead {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
  background: rgba(17, 200, 155, 0.1);
  border: 1px solid rgba(17, 200, 155, 0.1);
  border-radius: 4px;
}
.kanbanColumnHeadLeft {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.kanbanColumnHeadTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #142c44;
  text-transform: capitalize;
}
.kanbanColumnLeadAddBtn {
  background: #1877f2;
  border-radius: 6px;
  width: 31px;
  height: 24px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}
.kanbanColumnHeadCount {
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #12bd93;
}
.kanbanColumn-tasks {
  flex: 1;
  margin: 8px 0;
}

.kanbanTask {
  background-color: #fff;
  border-radius: 6px;
  margin: 8px 0;
  transition: box-shadow 0.1s ease-in-out;
  border: 1px solid #fff;
  padding: 12px;
  cursor: pointer;
}

.kanbanTaskBox {
  padding: 12px 12px 14px 12px;
}
.kanbanTask:hover {
  /* box-shadow: 0 2px 4px rgba(9, 30, 66, 0.25); */
  /* border: 1px solid #dedede; */
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
}

.kanbanTaskHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  gap: 10px;
  align-items: center;
}
.kanbanLeadSource {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #667085;
  text-transform: capitalize;
  margin-bottom: 14px;
}
.kanbanLeadName {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #142c44;
  text-transform: capitalize;
}
.smallLeadCardName {
  cursor: pointer;
}
.kanbanTaskId {
  font-size: 14px;
  font-weight: 600;
}

.kanbanTaskPriority {
  font-size: 12px;
  font-weight: 500;
  color: #0052cc;
}

.kanbanTaskSummary {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 8px;
}

.kanbanTaskFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #4b4f56;
  border-top: 1px solid #dfe1e6;
  padding-top: 8px;
}

.kanbanTaskAssignee {
  margin-right: 8px;
}

.kanbanTaskStatus {
  font-weight: 600;
}
.kanbanAssign {
  display: flex;
  gap: 8px;
  align-items: center;
}
.columnDraggingOver {
  background: #0052cc;
}
.taskType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lead {
  font-weight: 600;
  font-size: 13px;
  color: #142c44;
  margin-top: 4px;
  text-transform: capitalize;
}

.lead:hover {
  font-weight: 600;
  font-size: 13px;
  margin-top: 4px;
  text-transform: capitalize;
  color: #11c89b;
  transition: 0.2s;
}

.taskTypeSelect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #f2f4f7;
  border-radius: 16px;
  color: #344054;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
}

/* .taskTypeSelect:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #ececec;
  border-radius: 16px;
  color: #344054;
  font-size: 12px;
  font-weight: 500;
} */

.dateSelect {
  color: #667085;
  font-weight: 400;
  font-size: 12px;
}
.taskTitle {
  color: #142c44;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 225px;
}
.taskDesc {
  color: #142c44;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 300px;
}
.taskDemoItemLabel {
  color: #142c44;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.taskDemoItemValue {
  color: #142c44;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.taskFlag {
  display: flex;
  gap: 8px;
}
.userCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #f5f5f7;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #667085;
}
.flagAssign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #f5f5f7;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
}

.flagAssign:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background: #ececec;
  border: 0.733333px solid #eeeef4;
  border-radius: 50%;
}

/* Filterform */

.popupform {
  box-sizing: border-box;
  position: absolute;
  min-width: 280px;
  right: 0;
  background: #ffffff;
  z-index: 8;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 12px 10px;
}
.popupformWrap {
  box-sizing: border-box;
  min-width: 270px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 12px 10px;
}

.cross_button {
  display: flex;
  flex-direction: row-reverse;
}
.filterPopup {
  position: relative;
}
.addFieldSplitWrap > * {
  flex-basis: 100%;
}
.inputField {
  background: #f6fafd;
  /* Grey/Grey 30 */

  border: 1px solid #eaeef4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  color: #7e92a2;
  box-sizing: border-box;
}

.inputField::placeholder {
  color: #7e92a2;
}

.formHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
/* Filterform */

.popupform {
  box-sizing: border-box;
}
.button_reset,
.button_apply {
  text-transform: capitalize;
  color: #3f3e43;
  background: #ffffff;
  padding: 8px 40px;
}
.button_reset:hover,
.button_apply:hover {
  color: #ffffff;
  background: #1877f2;
}
.button_reset:active,
.button_apply:active {
  color: #ffffff;
  background: #1877f2;
}
.cross_button {
  display: flex;
  flex-direction: row-reverse;
}
.kanbanUpcomingTaskWrap {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 0 12px;
  margin-bottom: 14px;
}
.kanbanUpcomingTaskIcon {
  display: flex;
  /* margin-top: 4px; */
}
.kanbanUpcomingTaskIcon svg {
  font-size: 15px;
}
.kanbanUpcomingTaskDetailChild {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  flex-wrap: wrap;
  margin-top: 2px;
}
/* Add Enquiry Modal ========= */

.selectedbatch {
  width: 100%;
  padding: 5px;
  height: 92px;
  margin-top: 10px;
  background-color: #c9eee2;
  padding: 8px 12px;
  border: 2px solid rgba(35, 166, 123, 0.06);
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(52, 87, 140, 0.12);
}

.unSelectedbatch {
  width: 100%;
  padding: 5px;
  height: 92px;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 8px 12px;
  box-shadow: 0px 0px 12px rgba(52, 87, 140, 0.12);
  border-radius: 3.83032px;
}
.dragoverColumn {
  /* border: 2px dashed #11c89b;
  padding: 0 10px;
  margin-top: 10px; */
  margin: 0 6px;
  min-width: 250px;
}
.dropZoneBox {
  height: 100%;
  margin-top: 8px;
  background: #e9f2ff;
  border: 2px dashed #1e7afd;
  border-radius: 3px;
}
.dragoverColumn .dropZoneBox {
  border: 2px dashed #11c89b;
  background: #e0fcf0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.taskCompletedWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
}
.taskCompletedWrapHead {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #142c44;
}
.taskCompletedWrapViewAll {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #11c89b;
  border: none;
  background: transparent;
  cursor: pointer;
}
.taskCompletedListWrap {
  /* max-height: calc(100vh - 415px); */
  overflow-y: auto;
  padding-right: 5px;
  /* min-height: calc(100vh - 415px); */
  max-height: calc(100vh - 440px);
  min-height: calc(100vh - 440px);
}
.staticDatePicker .MuiDialogActions-root.MuiDialogActions-spacing {
  display: none !important;
}
.taskKanbanBoard {
  width: calc(100% - 360px);
}

/* Tab View */

@media only screen and (max-width: 899px) {
  .taskKanbanBoard {
    width: calc(100% - 300px);
  }
}
