.completedTaskPageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.changeMailBtn {
  border: none;
  background: transparent;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
}
.mailsLength {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #989898;
}
.mailDetailsPagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  gap: 15px;
}
