.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}

.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
  justify-content: center;
}
.navbar_list {
  padding-left: 10px;
  border-radius: 9px !important;
}
.navbar_list_active {
  padding-left: 10px;
}

.navbar_list:hover {
  background-color: #142c44 !important;
}
.navbar_list_active:hover {
  background-color: #142c44 !important;
}

.logo_box {
  position: absolute;
  left: 15%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}
.search_icon {
  float: left;
}
.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* today css */

.flag {
  /* position: absolute; */
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #1a3235;
}
.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}
.lineContainer {
  margin: 20px;
}
.subList:hover {
  background: #23a67b;
  color: #ffffff;
}
.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.googleMap {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 10px;
}
::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}
._Institute_imageBox {
  display: flex;
  justify-content: left;
}
._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}
._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}
.styles._image_description {
  margin-left: 50px;
}
.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}
.hader_txt {
  color: white;
}
.list_flat {
  display: flex;
}
.list_item_flat {
  display: flex !important;
  color: black !important;
}
.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}
.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
