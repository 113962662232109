.notificationHead {
    color: #142C44;
    font-size: 20px;
    font-weight: 500;
    line-height: 38px; 
    color: #142C44;
}

.notification {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0 24px;
}

.timeDisplay {
    color: #8F97A7;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; 
}

.email {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.notificationItem {
    border-bottom: 1px solid #F4F4F4;
    padding: 14px 0;
    padding: 14px 24px;
}

.notificationItem.unread {
    background-color: rgba(246, 253, 251, 0.80);  
}

.itemHeading {
    color: #142C44;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;    
}
  