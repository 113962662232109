.customizeTabContentWrap {
  margin-left: 32px;
}
.customizeTabWrap {
  display: flex;
}
.customFieldWrap {
  padding: 26px 26px 26px 0;
  width: 330px;
  border-right: 1px solid #ededed;
}
.customFieldTitle {
  color: #667085;
  font-size: 13px;
  margin-bottom: 6px;
}
.parameterFieldWrapChild {
  padding: 28px 28px;
}
.parameterFieldTitle {
  color: #142c44;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 16px;
}
.parameterFieldBottom {
  padding: 20px;
  border-top: 1px solid #ededed;
}
.parameterFieldBtnsWrap {
  display: flex;
  align-items: center;
  gap: 11px;
  justify-content: flex-end;
}
.customFields {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.customFieldItem {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 12px;
  min-width: 140px;
  text-align: center;
  width: calc(50% - 6px);
  cursor: pointer;
}
.customFieldName {
  font-size: 13px;
  color: #667085;
}
.customFieldItemIcon {
  margin-bottom: 9px;
}
.welcomeWrapper {
  border-radius: 4px;
  border: 1px solid rgb(225, 225, 225);
  background: rgb(255, 255, 255);
  padding: 30px 90px;
  text-align: center;
}
.welcomeHead {
  color: #3f3e43;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}
.welcomePara {
  color: #667085;
  font-size: 14px;
  margin-bottom: 12px;
}
.createFormPopupHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}
.modalTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
}
.btnSplit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 16px;
}
.addLeadTabWrap {
  padding: 22px 32px 22px 0;
}
.splitHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 40px 0 20px 0;
}
.secHead {
  color: #142c44;
  font-weight: 500;
}

.editLeadFormWrap {
  display: flex;
}
.editLeadFormLeft {
  padding: 30px 26px;
  border-right: 1px solid #ededed;
  max-height: calc(-94px + 100vh);
  overflow-y: auto;
  min-height: calc(-94px + 100vh);
}
.editLeadFormRight {
  /* padding: 30px 26px; */
  max-height: calc(-94px + 100vh);
  overflow-y: auto;
  min-height: calc(-94px + 100vh);
}
.demoTextField {
  border: 1px solid #d4d4d8;
  background: #f1f4f880;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  position: relative;
}
.demoFieldLabel {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.demoFieldSplit {
  display: flex;
  align-items: center;
  gap: 12px;
}
.demoBox {
  /* border: 1px solid #e6e6e6; */
  /* padding: 20px; */
  width: 450px;
  border-radius: 6px;
}
.demoFieldSplit .demoFieldWrap {
  flex-basis: 100%;
}
.demoFieldWrap {
  margin-bottom: 14px;
}
.demoPhoneCode {
  width: 70px;
  background: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 14px;
  border-right: 1px solid #d4d4d8;
}
.demoSelectField {
  border: 1px solid #d4d4d8;
  background: #f1f4f880;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: #8a94a6;
  font-size: 14px;
  padding: 10px 20px;
}
.demoCircleField {
  border: 1px dashed #b9b9b9;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demoCircleField img {
  width: 100%;
}
.primaryTitle {
  color: #142c44;
  font-weight: 600;
  margin-bottom: 10px;
}
.secondoryTitle {
  color: #142c44;
  font-weight: 500;
  margin-bottom: 8px;
}
.territoryTitle {
  color: #667085;
  font-size: 13px;
  margin-bottom: 10px;
}
.additionalBoxDrag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #667085;
  width: 100%;
  height: 500px;
  text-align: center;
}
.filterWrapSplit {
  display: flex;
  align-items: center;
  gap: 10px;
}
.configureBtn {
  background: #f1f4f880;
  padding: 12px;
  width: 100%;
  color: #8d9091;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  cursor: pointer;
}
.nameFieldsWrap {
  display: flex;
  align-items: center;
}
.additionalFieldWrap {
  margin-bottom: 14px;
}
.noCustomField {
  text-align: center;
  font-size: 14px;
  color: #667085;
  margin-top: 25%;
}
