.dragDropText {
  color: #142c44;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px;
}
.dragDropRequirement {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
}
