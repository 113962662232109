.leadExpandWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 9999; */
  z-index: 999999;
  color: #131313;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1% 1%;
  transition: 0.3s ease-in-out;
  cursor: default;
}
.leadExpandWrapperChild {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.expandLeadNameWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 7px;
  /* margin-top: 20px; */
  text-transform: capitalize;
}
.expandLeadName {
  font-weight: 600;
  font-size: 22px;
  color: #3f3e43;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.expandLeadEditBtnSingleBtn {
  color: #69696b;
  border: none;
  background: transparent;
}
.expandLeadEditBtn {
  border: none;
  background: #efefef;
  color: #69696b;
  /* position: absolute; */
  bottom: 0px;
  right: 0;
  z-index: 99999;
  height: 56px;
  display: flex;
  align-items: center;
}
.expandLeadAssignWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 5px;
}
.expandLeadSelectLabel {
  font-weight: 500;
  font-size: 18px;
  color: #3f3e43;
  margin-bottom: 16px;
  display: block;
}
.whatsAppButton {
  border: 1px solid #25d366;
  color: #25d366;
  padding: 8px 12px;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: max-content;
  margin: auto;
  font-size: 13px;
  transition: 0.3s ease-in-out;
  margin-bottom: 10px;
}
.whatsAppButton:hover {
  color: #fff;
  background: #25d366;
}
.leadEditWrapper {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #131313;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadEditWrapperChild {
  background: #fff;
  width: 408px;
  border-radius: 12px;
  padding: 16px 16px;
}
.popupClsWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.clsBtn {
  border: none;
  background: transparent;
  outline: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.clsBtn img {
  max-width: 100%;
}
.popupTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
  text-align: left;
}
.courseEditBtns {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 8px;
}
.smallLeadCardName {
  font-weight: 500;
  font-size: 16px;
  color: #3f3e43;
  margin-bottom: 10px;
}
