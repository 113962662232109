.closeModalButton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: -14px;
    margin-right: -14px;
}
.modalHead {
    color: #142C44;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    margin-bottom: 10px;
}
.modalSubHead {
    color: #142C44;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}
.stepsToFollow {
    color:#323B4B;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 2px;
    padding-left: 14px;
}