.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.PageBackBtnWrap {
  padding: 27px 20px 20px 20px;
}

/* AgentsListingComponent */

.AgentsListingWrapper {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  margin-bottom: 19px;
}
.installedHead {
  color: #3f3e43;
  font-size: 16px;
  font-weight: 600;
  line-height: 30.801px;
  margin-left: 12px;
}
.heading {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.agent {
  color: #494949;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}
.agentName {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.userName {
  color: #667085;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.agentCardImg {
  width: 34px;
  height: 34px;
}
.agentAvatar {
  aspect-ratio: 1;
  text-transform: capitalize;
}
.ozonetelAccountDetailsWrap {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  /* margin-bottom: 30px; */
}
.ozonetelAccountDetailsItem {
  text-align: left;
  flex-basis: 100%;
  max-width: 29%;
  overflow: hidden;
}
.ozonetelAccountDetailsItem:first-child {
  max-width: 40%;
}
.ozonetelAccountDetailsItem:first-child .ozonetelDetailsItemContent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ozonetelDetailsItemHeading {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.ozonetelDetailsItemContent {
  color: #142c44;
  font-size: 11px;
  font-weight: 500;
  line-height: 25px;
}
.addedAgentsListWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}
.agentCardDetailsWrap {
  display: flex;
  gap: 15px;
  align-items: center;
}
.agentCardDetailsWrapLeft {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 180px;
}
.editAgentsFieldItemsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.editAgentsFieldItemsWrapLeft {
  display: flex;
  gap: 20px;
  flex: 1;
}
.editAgentsFieldItemsWrapLeftItem {
  /* flex-basis: 100%; */
}
.agentListingBox {
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
}
.agentListingBoxHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 11px 24px;
  border-bottom: 1px solid #f2f2f7;
  background: #f7f7fb;
}
.addAgentsFieldItemUserWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 6px;
  background: #fff;
  max-width: 180px;
  min-width: 180px;
}
.addAgentsFieldItemUserWrapAvatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #eeeef4;
  background: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.addAgentsFieldItemUserName {
  color: #142c44;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}
.removeAgentBtn {
  /* position: absolute;
  right: 4px;
  bottom: 4px; */
  background: #fff;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
