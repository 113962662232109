.LeadCardDropdownWrap {
  position: relative;
}

.LeadCardDropdownListWrap {
  position: absolute;
  /* left: 0; */
  right: 0;
  list-style: none;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 15px rgba(52, 87, 140, 0.06);
  border-radius: 6px;
  background: #fff;
  width: 144px;
  z-index: 8;
  padding: 5px 12px;
  margin: 0;
}
.LeadCardDropdownListItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 5px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #142c44;
  cursor: pointer;
}
.LeadCardDropdownListItem:hover {
  /* background: #23a67b; */
  background: rgba(162, 162, 162, 0.08);
  color: #142c44;
  /* color: #fff; */
}
.LeadCardDropdownListItemLeft {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.LeadCardDropdownListItemLeftIcon {
  display: flex;
  align-items: center;
}
.LeadCardDropdownListItemLeftIcon img {
  max-width: 80%;
}
.LeadCardDropdownListItemChildIcon {
  display: flex;
  align-items: center;
}
.LeadCardDropdownListItemChildIcon svg {
  font-size: 16px;
}
.LeadCardDropdownChildListWrap {
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 8px rgba(52, 87, 140, 0.12);
  border-radius: 6px;
  background: #fff;
  width: 220px;
  list-style: none;
  /* z-index: 88; */
  z-index: 9999;
  position: absolute;
  left: 151px;
  padding: 10px 12px;
  top: -50px;
}
.LeadCardDropdownChildItem {
  font-size: 14px;
  line-height: 24px;
  color: #3f3e43;
  padding: 5px 4px;
  border-radius: 4px;
}
.LeadCardDropdownChildItem:hover {
  background: #11c89b !important;
  color: #fff;
}
.LeadCardDropdownBtn {
  border: none;
  background: none;
  color: #bdbfc3;
  padding: 0;
  display: flex;
  cursor: pointer;
}

.LeadCardDropdownBtn svg {
  color: "#69696B";
}
.LeadCardDropdownListItemLeftLabel {
  color: #ec3939;
  font-size: 14px;
}
