.userAssignWrap {
  position: relative;
}
.userAssignBtn {
  min-width: 30px;
}
.userAssignSelectBtn {
  border: 1px solid #11c89b;
  padding: 3px 10px;
  text-transform: capitalize;
  color: #11c89b;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;
}
.userAssignPopup {
  position: absolute;
  left: 0;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  width: 230px;
  padding: 10px;
  list-style: none;
  z-index: 99;
  overflow-y: auto;
}
.isForEnquirymodal {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  width: 280px;
  padding: 20px;
  list-style: none;
  z-index: 88;
  border: 1px solid #f0f0f0;
}
.isForDispositionForm {
  position: absolute;
  bottom: 34px;
  right: -230px;
  background: #fff;
  border-radius: 4px;
  width: 280px;
  padding: 20px;
  list-style: none;
  z-index: 88;
  border: 1px solid #f0f0f0;
}
.userAssignPopupList {
  max-height: 170px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}
.userAssignItem {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  padding: 10px 0;
}
.userAssignItemLeft {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: calc(100% - 65px);
}
.userAssignName {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #142c44;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
.userDefaultIcon img {
  width: 100%;
  max-width: 100%;
}
.userDefaultIcon {
  width: 100%;
  height: 100%;
}

/* field */
.selectAssigneeFieldBox {
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid #d4d4d8;
  background: #fff;
  cursor: pointer;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.selectAssigneeFieldSelected {
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectAssigneeFieldName {
  color: #142c44;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}
.userAvatar {
  aspect-ratio: 1;
  text-transform: uppercase;
}
.userCardImg {
  /* width: 32px;
  height: 32px; */
  display: flex;
  align-items: center;
  gap: -16px;
  position: relative;
}
.staffExtraRoleCount:hover .staffPopupWrap {
  display: block;
  transition: 0.1s ease-in-out;
}
.staffPopupWrap {
  position: absolute;
  width: 245px;
  max-height: 150px;
  border-radius: 7px;
  border: 1px solid #f2f4f7;
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 10px 10px 10px 10px;
  z-index: 99;
  list-style: none;
  top: 30px;
  left: 0;
  margin: 0;
  overflow-y: auto;
  display: none;
  transition: 0.1s ease-in-out;
}
.staffPopupItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
}
.staffPopupItemAvatar {
  border-radius: 50%;
  border: 1.286px solid #fff;
  background: #667085;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.57px 7.286px 2.43px 7.714px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.188px; /* 151.568% */
}
.staffPopupItemName {
  color: #142c44;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
