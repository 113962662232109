.custom-input-class:focus {
  box-shadow: none !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: unset !important;
  box-shadow: none !important;
  border: 0;
}
.react-tel-input .selected-flag:before {
  border: 0;
}
