.nurtureDetailsSplit {
  display: flex;
  height: 100%;
}
.nurtureDetailsSplitRight {
  border-left: 1px solid #e9e9e9;
  padding: 45px 25px 10px 35px;
}
.nurtureDetailsSplitRightHead {
  color: #142c44;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 4px;
}
.nurtureDetailsSplitRightDesc {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 14px;
}
.nurtureDetailsSplitRightListItem {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.nurtureDetailsSplitRightList {
  padding-left: 16px;
  margin-top: 0;
}
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
}
.PageBackBtnWrap {
  padding: 27px 20px 20px 20px;
}
.nurtureDetailsBodyBox {
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  padding: 20px 40px 30px 40px;
  background: #fff;
  max-width: 70%;
  margin: 0 auto 20px auto;
  text-align: center;
}
.nurtureDetailsBodyBoxHead {
  color: #3f3e43;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
}
.nurtureDetailsBodyBoxDesc {
  color: #667085;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;
}
.nurtureDetailsBodyMainDesc {
  color: #667085;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  max-width: 55%;
  margin: 0 auto 20px auto;
}
.nurtureDetailsSplitLeftBody {
  text-align: center;
}
.nurturePayStagesHead {
  color: #3f3e43;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 10px;
}
.nurturePayStagesDesc {
  color: #667085;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}
.nurturePayStagesItemsWrap {
  display: flex;
  gap: 25px;
}
.nurturePayStagesItem {
  flex-basis: 100%;
  display: flex;
  align-items: center;
}
.nurturePayStagesItemChild {
  border-radius: 8px;
  padding: 22px 22px;
  min-height: 180px;
  width: 94%;
}
.nurturePayStagesBody {
  padding: 0px 60px 20px 60px;
}
.nurturePayStagesItemTitle {
  color: #3f3e43;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.nurturePayStagesItemDesc {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 19px;
}
.nurturePayStagesItemSuccessMessageWrap,
.nurturePayStagesItemPendingMsgWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}
.nurturePayStagesItemSuccessMessageImg,
.nurturePayStagesItemPendingMsgImg {
  display: flex;
}
.nurturePayStagesItemSuccessMsg {
  color: #11c89b;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}
.nurturePayStagesItemArrow {
  width: 22px;
  height: 22px;
  transform: rotate(-45deg);
  position: relative;
  left: -12px;
}
.nurturePayStagesItemPendingMsg {
  color: #eba418;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

@media only screen and (max-width: 1200px) {
  .nurturePayStagesItemChild {
    min-height: 230px;
  }
}

@media only screen and (max-width: 1024px) {
  .nurturePayStagesItemsWrap {
    flex-direction: column;
  }
  .nurturePayStagesItemChild {
    min-height: 160px;
  }
  .nurturePayStagesItemArrow {
    transform: rotate(45deg);
    position: relative;
    left: unset;
    right: 50%;
    top: 80px;
  }
}
