.customPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
}
.customPopupWrap {
  background: #fff;
  border-radius: 12px;
  width: 408px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  padding: 16px;
}
.popupTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.popupDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-top: 8px;
  margin-bottom: 21px;
}
.clsBtn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.customPopupBottom {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
}
.customPopupBottom > button {
  flex-basis: 100%;
}
